
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faRedo, faCog } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./Prod.css";
import MySnackbar from 'views/examples/Snackbar'
import ConfigModal from "./ConfigModal";
import AdminModal from "./AdminModal";
import ForgotPasswordModal from "./ForgotPasswordModal";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useAuth } from "views/examples/AuthContext";
import { useApiUrl } from "views/examples/ApiUrlContext";
import { useSharedvars } from "views/examples/Sharedvars";
import { localUrl } from '../examples/Constant';


const Login = () => {
  const { setAuthData } = useAuth();
  const { setUserLoginCode } = useAuth();
  const { setUsername } = useAuth();
  const { setApiUrlContext } = useApiUrl();
  const { setDropdowns } = useSharedvars();
  const { apiUrl } = useApiUrl();
  const { setInstbasic } = useAuth();
  const [email, setEmail] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarBgColor, setSnackbarBgColor] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);
  const [password, setPassword] = useState("");
  const [userInput, setUserInput] = useState("");
  const [captchaText, setCaptchaText] = useState(generateCaptchaText());
  const [isConfigModalOpen, setConfigModalOpen] = useState(false);
  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginInstitute, setLoginInstitute] = useState(null);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
  const [instcode, setInstCode] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");


  const toggleForgotPasswordModal = () => {
    setIsForgotPasswordModalOpen(!isForgotPasswordModalOpen);
  };


  function generateCaptchaText() {
    // Generate a random string of specified length
    const generateRandomString = (length) => {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      return result;
    };

    // Specify the length of the Captcha text
    const captchaLength = 5;

    // Call the generateRandomString function to create the Captcha text
    return generateRandomString(captchaLength);
  }


  const handleReloadCaptcha = () => {
    setCaptchaText(generateCaptchaText());
    setUserInput("");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const handleUserInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const fetchInstituteInfo = async (data, code) => {
    try {
      const response = await fetch(`${apiUrl}/iBasic/code/${code}`,
        {
          headers: {
            'Authorization': `Bearer ${data}`,
            'Content-Type': 'application/json',
          },
        });
      if (response.ok) {
        const data = await response.json();
        setInstbasic({ name: data.name });
      } else {

      }
    } catch (error) {

    }
  };
  const handleSignInJwt = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Check for empty fields
    if (!email.trim()) {
      setSnackbarMessage("Email is required.");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }
    if (!emailRegex.test(email) || !password.trim()) {
      if (!emailRegex.test(email)) {
        setSnackbarMessage("Please enter a valid email address.");
        setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
      }
      if (!password.trim()) {
        setSnackbarMessage("Password is required.");
        setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
      }
       
    }
    if (userInput !== captchaText) {
      setSnackbarMessage("Captcha verification failed. Please enter the correct Captcha.");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }
    const headers = {
      "Content-Type": "application/json",
    };
    const loginData = { name: email, password: password, type: 'i' };

    axios
      .post(`${apiUrl}/token/login`, loginData, { headers })
      .then((res) => {
        if (res.data) {
          setAuthData(res.data);
          handleSignIn(res.data.accessToken);

        } else {
          setSnackbarMessage("Invalid Credentials.");
          setSnackbarBgColor('red');
          setIsSuccess(false);
          setSnackbarOpen(true);
        }
      })
      .catch((e) => {
        setSnackbarMessage("Issue while logging in\nCheck with system administrator");
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
      });
  };


  const handleSignIn = async (data) => {
    const loginData = { name: email, password: password, type: 'i' };
    try {
      const headers = {
        'Authorization': `Bearer ${data}`,
        'Content-Type': 'application/json',
      };
      const res = await axios.post(`${apiUrl}/auth/login`, loginData, { headers });
      if (res.data && res.data.type === 'a') {
        toggleAdminModal();
        if (res.data.cd === 1) {
          setSnackbarMessage(res.data.description);
          setSnackbarBgColor('red');
          setIsSuccess(false);
          setSnackbarOpen(true);
        }
        setInstCode(res.data.name);
        setUserLoginCode(loginInstitute);
        setUsername(email);

        // alert("Login Successful");
        fetchInstituteInfo(data, res.data.name);

        // Save apiUrl to localStorage
        localStorage.setItem('apiUrl', JSON.stringify(localUrl));

        // Redirect to the appropriate page
        window.location.href = "/auth/index";
      } else {
        if (res.data.cd === 1) {
          setSnackbarMessage(res.data.description);
          setSnackbarBgColor('red');
          setIsSuccess(false);
          setSnackbarOpen(true);
          return;
        }
        setInstCode(res.data.name);
        setUserLoginCode(res.data);
        setUsername(email);
        if (res.data.percomp >= 100) {
          setSnackbarMessage("Login Successful.");
          setSnackbarBgColor('green');
          setIsSuccess(true);
          setSnackbarOpen(true);
          // alert("Login Successful");
          setUsername(email);
        } else {
          setSnackbarMessage("Your profile is incomplete. Please complete your profile to access the dashboard.");
          setSnackbarBgColor('green');
          setIsSuccess(true);
          setSnackbarOpen(true);
        }

        // Save apiUrl to localStorage
        localStorage.setItem('apiUrl', JSON.stringify(apiUrl));

        fetchInstituteInfo(data, res.data.name);
        if (res.data.percomp < 100) {
          //setUserLoginCode("");
          window.location.href = "/auth/SignUp";
        } else {
          // Redirect to home page
          window.location.href = "/auth/index";
        }
      }
    } catch (error) {
      setSnackbarMessage("An error occurred during login. Please try again later.");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
    }
  };

  const dropdowns = async () => {
    try {
      const response = await fetch(`${apiUrl}/dropdown/alldropdowns`, {

      });
      if (!response.ok) {
        // Handle non-successful responses here
        throw new Error('Failed to fetch language options');
      }
      const data = await response.json();
      setDropdowns(data);
    } catch (error) {

    }
  };

  useEffect(() => {
    if (!dropdowns.length) {
      dropdowns();
    }
  }, []);



  const handleSignUp = () => {
    for (let key in localStorage) {
      if (localStorage.hasOwnProperty(key) && key !== 'apiUrl' && key !== 'dropdowns') {
        localStorage.removeItem(key);
      }
    }
    window.location.href = "/auth/SignUp";
  };



  const toggleConfigModal = () => {
    setConfigModalOpen(!isConfigModalOpen);
  };


  const toggleAdminModal = () => {
    setIsAdminModalOpen(!isAdminModalOpen);
  };


  const handleConfig = () => {

    toggleConfigModal();
  };

  const handleSave = (apiUrl) => {
    setApiUrlContext(apiUrl);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0" style={{ position: "relative" }}>
        {/* <FontAwesomeIcon
            icon={faCog}
            className="my-4"
            style={{ cursor: "pointer", position: "absolute", top: 1, right: 10, color: "black", fontSize: "24px" }}
            onClick={handleConfig}
          />  */}
          <CardBody className="px-lg-5 py-lg-5">

            <Form role="form">
              <FormGroup className="mb-3">
                <label htmlFor="email" style={{ fontWeight: "bold" }}>
                  Email <span className="required-asterisk">*</span>
                </label>

                <Input
                  id="email"
                  placeholder="Enter Email"
                  type="email"
                  autoComplete="new-email"
                  value={email}
                  onChange={handleEmailChange}
                  style={{ color: "black", borderColor: emailError ? "red" : "" }}
                />
              
              </FormGroup>
              <FormGroup className="mb-3" style={{ position: "relative" }}>
                <label htmlFor="password" style={{ fontWeight: "bold" }}>
                  Password <span className="required-asterisk">*</span>
                </label>
                <div style={{ position: "relative" }}>
                  <Input
                    id="password"
                    placeholder="Enter Password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    value={password}
                    onChange={handlePasswordChange}
                    onCopy={(e) => e.preventDefault()}
                    style={{ color: "black", borderColor: passwordError ? "red" : "" }}
                  />
                
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    onClick={togglePasswordVisibility}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: "1000",
                      color: "grey",
                    }}
                  />
                </div>

              </FormGroup>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button color="link" onClick={toggleForgotPasswordModal}>
                  Forgot Password?
                </Button>
              </div>

              <FormGroup className="mb-3">
                <label htmlFor="captcha" style={{ fontWeight: "bold" }}>
                  Captcha
                </label>
                <div className="d-flex justify-content-between">
                  <div>
                    <Input
                      id="captcha"
                      type="text"
                      placeholder="Enter Captcha"
                      value={userInput}
                      onChange={handleUserInputChange}
                      style={{ width: "220px" }}

                    />
                  </div>
                  <div className="captcha-container">
                    <div
                      style={{
                        border: "1px solid #ced4da",
                        padding: "5px",
                        borderRadius: "5px",
                        backgroundColor: "yellow",
                        userSelect: "none"
                      }}
                    >
                      <small
                        className="text-muted"
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          width: "100px",
                        }}
                      >
                        {captchaText}
                      </small>
                      <FontAwesomeIcon
                        icon={faRedo}
                        onClick={handleReloadCaptcha}
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                      />
                    </div>

                  </div>

                </div>
              </FormGroup>

              <Row className="mb-2">
                <Button
                  className="my-4 w-100"
                  color="primary"
                  type="button"
                  onClick={handleSignInJwt}
                >
                  LOGIN
                </Button>
              </Row>
             
              <Row className="mt-2 text-center">
                <Col>
                  <strong>
                    Don't have an account?{' '}
                    <Button
                      color="link"
                      type="button"
                      className="p-0"
                      onClick={handleSignUp} // Replace this with your sign-up handler
                    >
                      SIGN UP
                    </Button>
                  </strong>
                </Col>
              </Row>
              
              <AdminModal isOpen={isAdminModalOpen}
                toggle={() => setIsAdminModalOpen(false)
                }
                setLoginInstitute={setLoginInstitute} />
              <ConfigModal
                isOpen={isConfigModalOpen}
                toggle={toggleConfigModal}
                onSave={handleSave}
              />
              <MySnackbar
                snackbarOpen={snackbarOpen}
                handleSnackbarClose={handleSnackbarClose}
                snackbarMessage={snackbarMessage}
                snackbarBgColor={snackbarBgColor}
                isSuccess={isSuccess}
              />

              <ForgotPasswordModal isOpen={isForgotPasswordModalOpen} toggle={toggleForgotPasswordModal} />
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3"></Row>

      </Col>

    </>
  );
};

export default Login;
