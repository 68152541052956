import { Dialog, DialogActions, DialogContent } from "@mui/material";
import axios from "axios";
import Select from 'react-select';
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useSharedvars } from 'views/examples/Sharedvars';
import "react-toastify/dist/ReactToastify.css";
import "./Prod.css";
import "./suggestedjobs.css";
import ColorHeader from "./ColorHeader";
import { useApiUrl } from "views/examples/ApiUrlContext";
import { useAuth } from "views/examples/AuthContext";
import useUtil from "views/examples/Util"
import Button from "react-bootstrap/Button";
import { useNavigate } from 'react-router-dom';
import { Row, Col, FormGroup, Label, Form, Input } from "reactstrap";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Topbar from "../examples/TopBar"
import Papa from "papaparse";
import { ClipLoader } from 'react-spinners';
import MySnackbar from 'views/examples/Snackbar'

const Employee = () => {
  const { getDropdownData } = useSharedvars();
  const { apiUrl } = useApiUrl();
  const { userData } = useAuth();
  const { instbasic } = useAuth();
  const { userLoginCode } = useAuth();
  const [initialData, setInitialData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [newItem, setNewItem] = useState({
    name: "",
    designation: "",
    email: "",
    salary: "",
    department: "",
    joiningDate: '',
    phoneNumber: '',
  });
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    department: "",
    designation: "",
    email: "",
    salary: "",
    joiningDate: '',
    phoneNumber: '',
  });
  const [searchQuery, setSearchQuery] = useState("");

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedColumnsData, setSelectedColumnsData] = useState([
    { Header: "Type", name: "typeVal", display: true },
    { Header: "Name", name: "name", display: true },
    { Header: "Designation", name: "designationVal", display: true },
    { Header: "Email", name: "email", display: true },
    { Header: "Phone No", name: "phoneNumber", display: true },
   // { Header: "Salary", name: "salary", display: true },
    { Header: "Department", name: "departmentVal", display: true },
   // { Header: "Joining Date", name: "joiningDate", display: true },
  ]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [showColumnOptions, setShowColumnOptions] = useState(false);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarBgColor, setSnackbarBgColor] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);
  const navigate = useNavigate();


  const employeeOptions = [
    { value: "TE", label: "Teaching" },
    { value: "NT", label: "Non teaching" },

  ];

  useEffect(() => {
    if (
      !userData ||
      userData === undefined ||
      userData === '' ||
      (typeof userData === 'object' && Object.keys(userData).length === 0)
    ) {
      navigate('/auth/login');
    }
  }, [userData, navigate]);

  useEffect(() => {
    fetchData();
  }, [apiUrl, userData]);

  const handleImportCSV = (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      complete: async (result) => {
        try {
          const bearerToken = userData.accessToken;
          const config = {
            headers: {
              'Authorization': `Bearer ${bearerToken}`,
              'Content-Type': 'application/json',
            },
          };

          // Assuming each row in CSV represents an employee
          for (const row of result.data) {
            const newItem = {
              name: row.name,
              department: row.department,
              designation: row.designation,
              email: row.email,
              salary: row.salary,
              joiningDate: row.joiningDate,
              phoneNumber: row.phoneNumber,
              iCode: userLoginCode.name,
              type: row.type,
            };

            const response = await axios.post(`${apiUrl}/employees`, newItem, config);
            if (response.data.cd === 1) {
              toast.error(response.data.description);

            }
            else {
              toast.success(response.data.description);
              fetchData();
            }
          }
        } catch (error) {

          setSnackbarMessage("Error importing CSV.");
          setSnackbarBgColor('red');
          setIsSuccess(false);
          setSnackbarOpen(true);
        }
      },
      header: true, // Assume CSV has header row
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleExportCSV = () => {
    if (data.length === 0) {
      setSnackbarMessage('Data not found');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return; // Stop further execution if no data is found
    }

    // Convert employee data to CSV format
    const csv = Papa.unparse(data);

    // Create a Blob object and create a URL for the CSV file
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element and trigger download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "employee_data.csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };


  useEffect(() => {
    const fetchDepartmentOptions = async () => {
      try {
        const response = getDropdownData('DP');
        const mappedOptions = response.map((option) => ({
          value: option.code,
          label: option.value,
        }));

        setDepartmentOptions(mappedOptions);
      } catch (error) {

      }
    };

    fetchDepartmentOptions();
  }, [getDropdownData]);

  const fetchData = async () => {
    try {
      // Replace 'YOUR_BEARER_TOKEN' with the actual Bearer token
      const bearerToken = userData.accessToken;

      const response = await fetch(`${apiUrl}/employees/icode/${userLoginCode.name}`, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json', // Adjust the content type if needed
        },
      });

      const data = await response.json();

      setData(data);
      setInitialData(data);
      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.Exception === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.Exception === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }
    } catch (error) {

    }
  };
  useEffect(() => {
    const fetchDesignationOptions = async () => {
      try {
        const response = getDropdownData('RL');
        const mappedOptions = response.map((option) => ({
          value: option.code,
          label: option.value,
        }));
        setDesignationOptions(mappedOptions);
      } catch (error) {

      }
    };

    fetchDesignationOptions();
  }, [getDropdownData]);


  // const handleDateChange = (e) => {
  //   setNewItem({ ...newItem, joiningDate: e.target.value });
  //   setValidationErrors({ ...validationErrors, joiningDate: "" });
  // };

  const handlePhoneNumberChange = (e) => {
    setNewItem({ ...newItem, phoneNumber: e.target.value });
    setValidationErrors({ ...validationErrors, phoneNumber: "" });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value, 10));
    setCurrentPage(1); // Reset to the first page when changing the number of rows per page
  };

  const filteredData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleRowClick = (itemId) => {
    setSelectedItemId((prevSelected) =>
      prevSelected === itemId ? null : itemId
    );
  };

  const handleDelete = async () => {
    if (!selectedItemId) {
      setSnackbarMessage("Please select a row to delete.");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }
    setDeleteConfirmationOpen(true);
  };

  const confirmDelete = async () => {
    try {
      // Replace 'YOUR_BEARER_TOKEN' with the actual Bearer token
      const bearerToken = userData.accessToken;
      const response = await axios.delete(
        `${apiUrl}/employees/${selectedItemId}`,
        {
          data: { id: selectedItemId },
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json', // Adjust the content type if needed
          },
        }
      );

      setSelectedItemId(null);
      fetchData();
      setDeleteConfirmationOpen(false);
      setSnackbarMessage("Employee Deleted Successfully.");
      setSnackbarBgColor('green');
      setIsSuccess(true);
      setSnackbarOpen(true);

      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.get('exception') === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.get('exception') === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }
    } catch (error) {

    }
  };

  const cancelDelete = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleAdd = () => {
    setNewItem({
      name: "",
      designation: "",
      email: "",
      salary: "",
      department: "",
      joiningDate: '',
      phoneNumber: '',
      type: "",
    });
    //setImagePreviewUrl("");
    setShowAddModal(true);
  };

  const handleUpdate = async () => {
    if (!selectedItemId) {
      setSnackbarMessage("Please select a row to update.");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }

    try {
      const selectedItem = data.find((item) => item.id === selectedItemId);
      if (selectedItem) {
        setNewItem({
          ...selectedItem,
          // userCode: selectedItem.user_cd, // Set userCode with user_cd value
        });

        setShowUpdateModal(true);
      }
    } catch (error) {

    }
  };

  const validateFields = () => {
    let errors = {};
    const isNumeric = (value) => !isNaN(value) && !isNaN(parseFloat(value));
    const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isValidPhoneNumber = (phoneNumber) => /^\d{10}$/.test(phoneNumber);

    if (!newItem.type) errors.type = "Please select employee type.";
    if (!newItem.name) errors.name = "Please fill in this field.";
    if (!newItem.department) errors.department = "Please fill in this field.";
    if (!newItem.designation) errors.designation = "Please fill in this field.";
    if (!newItem.email) {
      errors.email = "Please fill in this field.";
    } else if (!isValidEmail(newItem.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!isNumeric(newItem.phoneNumber)) {
      errors.phoneNumber = "PhoneNumber is required and must be numeric.";
    } else if (!isValidPhoneNumber(newItem.phoneNumber)) {
      errors.phoneNumber = "PhoneNumber must be exactly 10 digits.";
    }

    setValidationErrors(errors);
    return errors;
  };



  const handleSave = async () => {
    try {
      setLoading(true);
      const bearerToken = userData.accessToken;
      const fieldErrors = validateFields();
      setValidationErrors(fieldErrors);
      if (Object.keys(fieldErrors).length > 0) {
        toast.warn("Please fill all required fields.");
        setLoading(false);
        return;
      }

      // All validations passed, proceed to add/update the item
      const employees = {
        id: selectedItemId,
        iCode: userLoginCode.name,
        employeeId: newItem.employeeId,
        type: newItem.type,
        name: newItem.name,
        department: newItem.department,
        designation: newItem.designation,
        email: newItem.email,
        salary: newItem.salary,
        joiningDate: newItem.joiningDate,
        phoneNumber: newItem.phoneNumber,
      };

      const config = {
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
      };

      let response;

      if (showAddModal) {
        response = await axios.post(`${apiUrl}/employees`, employees, config);
        if (response.data.cd === 1) {
          toast.error(response.data.description);
        }
        else {
          toast.success(response.data.description);
          setShowAddModal(false);
          setNewItem({
            name: "",
            department: "",
            designation: "",
            type: "",
            email: "",
            salary: "",
            joiningDate: "",
            phoneNumber: "",
          });
          fetchData();

        }

      } else if (showUpdateModal) {
        response = await axios.put(`${apiUrl}/employees`, employees, config);
        if (response.data.cd === 1) {
          toast.error(response.data.description);

        }
        else {
          toast.success(response.data.description);
          setShowUpdateModal(false);
          fetchData();
        }

      }


      fetchData();
      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.Exception === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.Exception === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }
    } catch (error) {
      setShowAddModal(false);
      setShowUpdateModal(false);
      setValidationErrors('');
      setSnackbarMessage("Some Issue Pls Check.");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
    }
    finally {
      setLoading(false); // Stop loading when API call is finished
    }
  };


  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();

    // Check if the search query is empty
    if (query.trim() === '') {
      setSearchQuery('');
      setData(initialData); // Reset data to initial unfiltered data
      return;
    }

    // Filter data based on the search query
    const filteredData = Array.isArray(data)
      ? data.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === 'string' && value.toLowerCase().includes(query)
        )
      )
      : [];

    setSearchQuery(query);
    setData(filteredData);
  };

  const handleColumnToggle = (columnName) => {
    setSelectedColumnsData((prevColumns) =>
      prevColumns.map((col) =>
        col.Header === columnName ? { ...col, display: !col.display } : col
      )
    );
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setNewItem({ ...newItem, joiningDate: selectedDate });
    setValidationErrors({ ...validationErrors, joiningDate: "" });
  };

  const today = new Date().toISOString().split('T')[0];
  const handleCloseModal = () => {
    setShowAddModal(false);
    setShowUpdateModal(false);
    setValidationErrors("");
    setNewItem({
      name: "",
      department: "",
      designation: "",
      type: "",
      email: "",
      salary: "",
      joiningDate: "",
      phoneNumber: "",
    });
  };

  return (
    <>
      <ColorHeader />

      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh", margin: "5px" }}
      >
        <Topbar />
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, textAlign: "center" }}>


            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: 10,
              }}
            >
              <div style={{ position: "relative", display: "inline-block" }}>
                <input
                  style={{
                    borderRadius: "5px",
                    padding: "5px 30px 5px 10px", /* Add extra padding on the right for the icon */
                  }}
                  type="text"
                  id="searchInput"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <span style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  pointerEvents: "none",
                }}>
                  <i className="fa fa-search" style={{ color: "#888" }}></i>
                </span>
              </div>

            </div>
            {showColumnOptions && (
              <div className="modal">
                <div className="column-options-modal">
                  <h2>Column Options</h2>
                  <div className="column-options">
                    {selectedColumnsData.map((col) => (
                      <label className="column-option-label" key={col.Header}>
                        <input
                          type="checkbox"
                          checked={col.display}
                          onChange={() => handleColumnToggle(col.Header)}
                        />
                        {col.Header}
                      </label>
                    ))}
                  </div>
                  <Button
                    variant="primary"
                    style={{ padding: "5px 10px" }}
                    onClick={() => setShowColumnOptions(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
              }}
            >
              <div>

                <Button onClick={handleAdd} variant="success">
                  Add Employee
                </Button>{" "}
                <Button onClick={handleUpdate} variant="warning">
                  Edit Employee
                </Button>{" "}
                <Button onClick={handleDelete} variant="danger">
                  Delete Employee
                </Button>{" "}
              </div>
              <div>
                <input
                  type="file"
                  accept=".csv"
                  onChange={handleImportCSV}
                  style={{ display: "none" }}
                  id="fileInput"
                />
                <Button onClick={() => document.getElementById("fileInput").click()}>
                  Import CSV
                </Button>
                <Button onClick={handleExportCSV}>Export CSV</Button>
                {/* <CSVLink data={data} filename={"employee_data.csv"}>
        Download CSV
      </CSVLink> */}
                {/* Render your employee table using employeeData */}
              </div>

              <Dialog open={deleteConfirmationOpen} onClose={cancelDelete}>
                <DialogContent>
                  Are you sure you want to delete?
                </DialogContent>
                <DialogActions>
                  <Button onClick={cancelDelete} variant="outline-success">
                    Cancel
                  </Button>
                  <Button onClick={confirmDelete} variant="outline-danger">
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            {showAddModal && (
              <div className="employee-modal-overlay">
                <div className="employee-modal-content">
                  <div className="employee-modal-header">
                    <h2 className="modal-title"> Add Employee</h2>
                    <button className="cancel-button" onClick={handleCloseModal}>
                      &#x2715;
                    </button>
                  </div>
                  <div className="employee-modal-body">
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <Label for="designationInput" className="font-weight-bold">
                          Employee Type <span className="required-asterisk">*</span>
                        </Label>
                        <Select
                          id="employeeInput"
                          value={employeeOptions.find(option => option.value === newItem.type)}
                          options={employeeOptions}
                          onChange={(selectedOption) => {
                            setNewItem({ ...newItem, type: selectedOption.value });
                            setValidationErrors({ ...validationErrors, type: "" });
                          }}
                          placeholder="Select Employee Type"
                        />
                        {validationErrors.type && (
                          <div className="error-text" style={{ color: "red" }}>
                            {validationErrors.type}
                          </div>
                        )}
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Label for="nameInput" className="font-weight-bold">
                          Full Name <span className="required-asterisk">*</span>
                        </Label>
                        <Input
                          type="text"
                          id="nameInput"
                          placeholder="Full Name"
                          value={newItem.name}
                          onChange={(e) => {
                            setNewItem({ ...newItem, name: e.target.value });
                            // Clear validation error when user starts typing
                            setValidationErrors({
                              ...validationErrors,
                              name: "",
                            });
                          }}
                          // Apply red border if validation error exists
                          style={{
                            color: "black",
                            borderColor: validationErrors.name ? "red" : "",
                          }}
                        />
                        {/* Display error message if validation error exists */}
                        {validationErrors.name && (
                          <div className="error-text" style={{ color: "red" }}>
                            {validationErrors.name}
                          </div>
                        )}
                      </FormGroup>

                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label for="designationInput" className="font-weight-bold">
                              Designation <span className="required-asterisk">*</span>
                            </Label>
                            <Select
                              id="designationInput"
                              value={designationOptions.find(option => option.value === newItem.designation)}
                              options={designationOptions}
                              onChange={(selectedOption) => {
                                setNewItem({ ...newItem, designation: selectedOption.value });
                                setValidationErrors({ ...validationErrors, designation: "" });
                              }}
                              placeholder="Select Designation"
                            />
                            {validationErrors.designation && (
                              <div className="error-text" style={{ color: "red" }}>
                                {validationErrors.designation}
                              </div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label for="departmentInput" className="font-weight-bold">
                              Department <span className="required-asterisk">*</span>
                            </Label>
                            <Select
                              id="departmentInput"
                              value={departmentOptions.find(option => option.value === newItem.department)}
                              options={departmentOptions}
                              onChange={(selectedOption) => {
                                setNewItem({ ...newItem, department: selectedOption.value });
                                setValidationErrors({ ...validationErrors, department: "" });
                              }}
                              placeholder="Select Department"
                            />
                            {validationErrors.department && (
                              <div className="error-text" style={{ color: "red" }}>
                                {validationErrors.department}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label for="emailInput" className="font-weight-bold">
                              E-Mail <span className="required-asterisk">*</span>
                            </Label>
                            <Input
                              type="text"
                              id="emailInput"
                              placeholder="Email"
                              value={newItem.email}
                              onChange={(e) => {
                                setNewItem({ ...newItem, email: e.target.value });
                                // Clear validation error when the user starts typing
                                setValidationErrors({
                                  ...validationErrors,
                                  email: "",
                                });
                              }}
                              // Apply red border if validation error exists
                              style={{
                                color: "black",
                                borderColor: validationErrors.email ? "red" : "",
                              }}
                            />
                            {/* Display error message if validation error exists */}
                            {validationErrors.email && (
                              <div className="error-text" style={{ color: "red" }}>
                                {validationErrors.email}
                              </div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label for="phoneNumberInput" className="font-weight-bold">
                              Phone Number <span className="required-asterisk">*</span>
                            </Label>
                            <Input
                              type="tel"
                              id="phoneNumberInput"
                              placeholder="phone number"
                              value={newItem.phoneNumber}
                              onChange={handlePhoneNumberChange}
                              style={{
                                color: "black",
                                borderColor: validationErrors.phoneNumber ? "red" : "",
                              }}
                            />
                            {validationErrors.phoneNumber && (
                              <div className="error-text" style={{ color: "red" }}>
                                {validationErrors.phoneNumber}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label for="salaryInput" className="font-weight-bold">
                              Salary <span className="required-asterisk">*</span>
                            </Label>
                            <Input
                              type="text"
                              id="salaryInput"
                              placeholder="Salary"
                              value={newItem.salary}
                              onChange={(e) => {
                                setNewItem({ ...newItem, salary: e.target.value });
                               
                              }}
                              
                            />
                           
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label for="joiningDateInput" className="font-weight-bold">
                              Date of Joining <span className="required-asterisk">*</span>
                            </Label>
                            <Input
                              type="date"
                              id="joiningDateInput"
                              value={newItem.joiningDate}
                              onChange={handleDateChange}
                              style={{ color: "black", }}
                            />
                           
                          </FormGroup>
                        </Col>
                      </Row>
                      {loading && (
                        <div className="spinner-container">
                          <ClipLoader size={50} color={"#123abc"} loading={loading} />
                        </div>
                      )}

                  </Form>
                  </div>

                  <div className="employee-button">
                    <Button onClick={handleSave} disabled={loading} variant="success">
                      Save
                    </Button>
                    <Button onClick={handleCloseModal} variant="danger">
                      Close
                    </Button>
                  </div>
                </div>
              </div>

            )}

            {showUpdateModal && (
              <div className="employee-modal-overlay">
                <div className="employee-modal-content">
                  <div className="employee-modal-header">
                    <h2 className="modal-title"> Update Employee</h2>
                    <button className="cancel-button" onClick={handleCloseModal}>
                      &#x2715;
                    </button>
                  </div>
                  <div className="employee-modal-body">
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <Label for="designationInput" className="font-weight-bold">
                          Employee Type <span className="required-asterisk">*</span>
                        </Label>
                        <Select
                          id="employeeInput"
                          value={employeeOptions.find(option => option.value === newItem.type)}
                          options={employeeOptions}
                          onChange={(selectedOption) => {
                            setNewItem({ ...newItem, type: selectedOption.value });
                            setValidationErrors({ ...validationErrors, type: "" });
                          }}
                          placeholder="Select Employee Type"
                        />
                        {validationErrors.type && (
                          <div className="error-text" style={{ color: "red" }}>
                            {validationErrors.type}
                          </div>
                        )}
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Label for="nameInput" className="font-weight-bold">
                          Full Name <span className="required-asterisk">*</span>
                        </Label>
                        <Input
                          type="text"
                          id="nameInput"
                          placeholder="Full Name"
                          value={newItem.name}
                          onChange={(e) => {
                            setNewItem({ ...newItem, name: e.target.value });
                            // Clear validation error when user starts typing
                            setValidationErrors({
                              ...validationErrors,
                              name: "",
                            });
                          }}
                          // Apply red border if validation error exists
                          style={{
                            color: "black",
                            borderColor: validationErrors.name ? "red" : "",
                          }}
                        />
                        {/* Display error message if validation error exists */}
                        {validationErrors.name && (
                          <div className="error-text" style={{ color: "red" }}>
                            {validationErrors.name}
                          </div>
                        )}
                      </FormGroup>

                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label for="designationInput" className="font-weight-bold">
                              Designation <span className="required-asterisk">*</span>
                            </Label>
                            <Select
                              id="designationInput"
                              value={designationOptions.find(option => option.value === newItem.designation)}
                              options={designationOptions}
                              onChange={(selectedOption) => {
                                setNewItem({ ...newItem, designation: selectedOption.value });
                                setValidationErrors({ ...validationErrors, designation: "" });
                              }}
                              placeholder="Select Designation"
                            />
                            {validationErrors.designation && (
                              <div className="error-text" style={{ color: "red" }}>
                                {validationErrors.designation}
                              </div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label for="departmentInput" className="font-weight-bold">
                              Department <span className="required-asterisk">*</span>
                            </Label>
                            <Select
                              id="departmentInput"
                              value={departmentOptions.find(option => option.value === newItem.department)}
                              options={departmentOptions}
                              onChange={(selectedOption) => {
                                setNewItem({ ...newItem, department: selectedOption.value });
                                setValidationErrors({ ...validationErrors, department: "" });
                              }}
                              placeholder="Select Department"
                            />
                            {validationErrors.department && (
                              <div className="error-text" style={{ color: "red" }}>
                                {validationErrors.department}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label for="emailInput" className="font-weight-bold">
                              E-Mail <span className="required-asterisk">*</span>
                            </Label>
                            <Input
                              type="text"
                              id="emailInput"
                              placeholder="Email"
                              value={newItem.email}
                              onChange={(e) => {
                                setNewItem({ ...newItem, email: e.target.value });
                                // Clear validation error when the user starts typing
                                setValidationErrors({
                                  ...validationErrors,
                                  email: "",
                                });
                              }}
                              // Apply red border if validation error exists
                              style={{
                                color: "black",
                                borderColor: validationErrors.email ? "red" : "",
                              }}
                            />
                            {/* Display error message if validation error exists */}
                            {validationErrors.email && (
                              <div className="error-text" style={{ color: "red" }}>
                                {validationErrors.email}
                              </div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label for="phoneNumberInput" className="font-weight-bold">
                              Phone Number <span className="required-asterisk">*</span>
                            </Label>
                            <Input
                              type="tel"
                              id="phoneNumberInput"
                              placeholder="phone number"
                              value={newItem.phoneNumber}
                              onChange={handlePhoneNumberChange}
                              style={{
                                color: "black",
                                borderColor: validationErrors.phoneNumber ? "red" : "",
                              }}
                            />
                            {validationErrors.phoneNumber && (
                              <div className="error-text" style={{ color: "red" }}>
                                {validationErrors.phoneNumber}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label for="salaryInput" className="font-weight-bold">
                              Salary <span className="required-asterisk">*</span>
                            </Label>
                            <Input
                              type="text"
                              id="salaryInput"
                              placeholder="Salary"
                              value={newItem.salary}
                              onChange={(e) => {
                                setNewItem({ ...newItem, salary: e.target.value });
                               
                              }}
                            
                            />
                           
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="mb-3">
                            <Label for="joiningDateInput" className="font-weight-bold">
                              Date of Joining <span className="required-asterisk">*</span>
                            </Label>
                            <Input
                              type="date"
                              id="joiningDateInput"
                              value={newItem.joiningDate}
                              onChange={handleDateChange}
                           
                              style={{ color: "black", }}
                            />
                          
                          </FormGroup>
                        </Col>
                      </Row>
                      {loading && (
                        <div className="spinner-container">
                          <ClipLoader size={50} color={"#123abc"} loading={loading} />
                        </div>
                      )}

                    </Form>

                  </div>
                  <div className="employee-button">
                    <Button onClick={handleSave} disabled={loading} variant="success">
                      Save
                    </Button>
                    <Button onClick={handleCloseModal} variant="danger">
                      Close
                    </Button>
                  </div>
                </div>
              </div>

            )}


            <div className="table-container">
              <table className="modern-table">
                <thead>
                  <tr>
                    {selectedColumnsData.map(
                      (col) =>
                        col.display && (
                          <th
                            key={col.Header}
                            style={{
                              width: `${100 / selectedColumnsData.length}%`,
                            }}
                          >
                            {col.Header}
                          </th>
                        )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item) => (
                    <tr
                      key={item.id}
                      className={`${selectedItemId === item.id ? "selected" : ""
                        }`}
                      onClick={() => handleRowClick(item.id)}
                    >
                      {selectedColumnsData.map((col) => (
                        <td
                          key={col.name}
                          style={{
                            width: `${100 / selectedColumnsData.length}%`,
                          }}
                        >
                          {col.name === "logo" ? (
                            <div
                              style={{
                                width: "50px",
                                height: "50px",
                                overflow: "hidden",
                                borderRadius: "50%",
                                border: "1px solid #ddd",
                              }}
                            >
                              <img
                                src={`data:image/jpeg;base64,${item.logo}`}
                                alt="Preview"
                                style={{
                                  maxWidth: "100%",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                          ) : (
                            item[col.name]
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div style={{ padding: "10px" }}>
              <div style={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "10px" }}>
                    <span style={{}}>Show : </span>
                    <select
                      value={rowsPerPage}
                      onChange={handleRowsPerPageChange}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                    </select>
                  </div>
                  <button
                    onClick={() => setShowColumnOptions(true)}
                    style={{ border: "none", background: "none", padding: 0 }}
                  >
                    <FontAwesomeIcon icon={faCog} size="lg" />
                  </button>


                  <div className="pagination" style={{ margin: "auto" }}>
                    <button
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      &laquo;
                    </button>

                    {[1, 2, 3].map((page) => (
                      <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={currentPage === page ? "active" : ""}
                      >
                        {page}
                      </button>
                    ))}

                    <button
                      disabled={filteredData.length < rowsPerPage}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      &raquo;
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <ToastContainer />
            <MySnackbar
              snackbarOpen={snackbarOpen}
              handleSnackbarClose={handleSnackbarClose}
              snackbarMessage={snackbarMessage}
              snackbarBgColor={snackbarBgColor}
              isSuccess={isSuccess}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Employee;
