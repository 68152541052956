import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Form, Alert } from 'react-bootstrap';
import { useAuth } from 'views/examples/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useApiUrl } from "views/examples/ApiUrlContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import MySnackbar from 'views/examples/Snackbar';
import useUtil from 'views/examples/Util';
import Topbar from "../examples/TopBar";

const Feedbacks = () => {
    const { userData } = useAuth();
    const { apiUrl } = useApiUrl();
    const { userLoginCode } = useAuth();
    const { instbasic } = useAuth();
    const [feedbacks, setFeedbacks] = useState([]);
    const [replyStates, setReplyStates] = useState({});
    const [replyComment, setReplyComment] = useState('');
    const [replyError, setReplyError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarBgColor, setSnackbarBgColor] = useState('');
    const [isSuccess, setIsSuccess] = useState(true);
    const [charCount, setCharCount] = useState(250); // Add state for character countdown
    const maxCharLimit = 250; // Define the max character limit
    const navigate = useNavigate();

    useEffect(() => {
        if (
            !userData ||
            userData === undefined ||
            userData === '' ||
            (typeof userData === 'object' && Object.keys(userData).length === 0)
        ) {
            navigate('/auth/login');
        }
    }, [userData, navigate]);

    const generateStars = (rating) => {
        const totalStars = 5;
        const filledStars = Math.round(rating);
        const emptyStars = totalStars - filledStars;

        return (
            <>
                {[...Array(filledStars)].map((_, index) => (
                    <span key={index} style={{ color: 'orange', fontSize: '25px' }}>
                        ★
                    </span>
                ))}
                {[...Array(emptyStars)].map((_, index) => (
                    <span key={index}>☆</span>
                ))}
            </>
        );
    };

    const handleReply = (userCode) => {
        setReplyStates((prevReplyStates) => ({
            ...prevReplyStates,
            [userCode]: !prevReplyStates[userCode],
        }));
        setReplyError('');
        setReplyComment(''); // Reset comment and countdown when reply box opens
        setCharCount(maxCharLimit);
    };

    const handleReplyChange = (e) => {
        const input = e.target.value;
        if (input.length <= maxCharLimit) {
            setReplyComment(input);
            setCharCount(maxCharLimit - input.length);
        }
    };

    const handleReplySubmit = async (userCode, instCode) => {
        try {
            if (!replyComment || replyComment.trim() === '') {
                setSnackbarMessage('Write your Response before submit reply');
                setSnackbarBgColor('red');
                setIsSuccess(false);
                setSnackbarOpen(true);
                return;
            }

            const bearerToken = userData.accessToken;
            const config = {
                headers: {
                    'Authorization': `Bearer ${bearerToken}`,
                    'Content-Type': 'application/json',
                },
            };

            const response = await axios.put(
                `${apiUrl}/feedback/updateResponse/${userCode}/${instCode}`,
                { response: replyComment },
                config
            );

            if (response.status === 200) {
                setSnackbarMessage('Response submitted successfully');
                setSnackbarBgColor('green');
                setIsSuccess(true);
                setSnackbarOpen(true);
            } else {
                setSnackbarMessage('Response not submitted successfully');
                setSnackbarBgColor('red');
                setIsSuccess(false);
                setSnackbarOpen(true);
            }

            setReplyComment('');
            setReplyStates((prevReplyStates) => ({
                ...prevReplyStates,
                [userCode]: false,
            }));
        } catch (error) {
            setSnackbarMessage('Error submitting reply. Please check the issue.');
            setSnackbarBgColor('red');
            setIsSuccess(false);
            setSnackbarOpen(true);
        }
    };

    const fetchFeedbacks = async () => {
        try {
            const bearerToken = userData.accessToken;
            const response = await fetch(`${apiUrl}/feedback/icode/${userLoginCode.name}`, {
                headers: {
                    'Authorization': `Bearer ${bearerToken}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            setFeedbacks(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchFeedbacks();
    }, []);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <Topbar />
            <div>
                {feedbacks.map((feedback) => (
                    <Card key={feedback.id} className="shadow-lg mb-4">
                        <Card.Body>
                            <p>
                                <strong>User:</strong> {feedback.uCode}
                            </p>
                            <p>
                                <strong>Rating:</strong> {generateStars(feedback.stars)}
                            </p>
                            <p>
                                <strong>Comment:</strong> {feedback.comment}
                            </p>
                            <p>
                                <strong>Date:</strong> {feedback.date}
                            </p>
                            <Button variant="primary" onClick={() => handleReply(feedback.uCode)}>
                                Reply <FontAwesomeIcon icon={faReply} />
                            </Button>

                            <div className="mt-2">
                                {replyStates[feedback.uCode] && (
                                    <Form>
                                        <Form.Group controlId={`replyComment-${feedback.uCode}`}>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                placeholder="Write your reply... (Max 250 characters)"
                                                value={replyComment}
                                                onChange={handleReplyChange}
                                            />
                                            <small className="text-muted">
                                                {charCount} characters remaining
                                            </small>
                                        </Form.Group>

                                        <Button
                                            variant="primary"
                                            onClick={() => handleReplySubmit(feedback.uCode, feedback.iCode)}
                                            style={{ marginTop: '10px' }}
                                        >
                                            Submit Reply
                                        </Button>
                                    </Form>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                ))}
            </div>
            <MySnackbar
                snackbarOpen={snackbarOpen}
                handleSnackbarClose={handleSnackbarClose}
                snackbarMessage={snackbarMessage}
                snackbarBgColor={snackbarBgColor}
                isSuccess={isSuccess}
            />
        </div>
    );
};

export default Feedbacks;
