import React, { useState, useEffect } from 'react';
import "./landing.css";
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from 'qrcode.react';
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
  faGlobe,
  faUserCircle,
  faBriefcase,
  faFileAlt,
  faUsers,
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FaInstagram, FaTwitter, FaYoutube, FaFacebook, FaLinkedin } from 'react-icons/fa';
import InstPlans from "../../assets/img/brand/InsPlans.png";
import TeachersPlans from "../../assets/img/brand/StaffPlans.png";
import Logo from "../../assets/img/brand/shishakbandulogo.PNG";
import Homelogo from "../../assets/img/brand/Shbandu.PNG";
import { env, localUrl } from '../examples/Constant';


const HomeComponent = () => {
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);
  const [backgroundImageUrls, setBackgroundImageUrls] = useState([]);
  const [titles, setTitles] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [featuredJobs, setFeaturedJobs] = useState([]);
  const [featuredJobsList, setFeaturedJobsList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showMoreJobsModal, setShowMoreJobsModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [isVisible, setIsVisible] = useState(true);
  const [visibleTextBoxes, setVisibleTextBoxes] = useState([]);

  const itemsPerPage = 5; // Adjust this as needed

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBackgroundIndex((prevIndex) => (prevIndex + 1) % backgroundImageUrls.length);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [backgroundImageUrls]);

  useEffect(() => {
    fetchBackgroundImages();
    fetchBlogs();
    fetchFeaturedJobs();
    fetchMoreFeaturedJobs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await fetch(`${localUrl}/blogs/allblogs`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      setBlogs(data);
    } catch (error) {

    }
  };


  const fetchFeaturedJobs = async () => {
    try {
      const response = await fetch(`${localUrl}/jobpost/featurejobs`);
      if (!response.ok) {
        throw new Error('Failed to fetch featured jobs data');
      }
      const data = await response.json();
      setFeaturedJobs(data);
    } catch (error) {

    }
  };


  const fetchMoreFeaturedJobs = async () => {
    try {
      const response = await fetch(`${localUrl}/jobpost/featurejobslist`);
      if (!response.ok) {
        throw new Error('Failed to fetch featured jobs data');
      }
      const data = await response.json();
      setFeaturedJobsList(data);
    } catch (error) {

    }
  };


  const fetchBackgroundImages = async () => {
    try {
      const response = await fetch(`${localUrl}/advertize/inst/i`);
      if (!response.ok) {
        throw new Error('Failed to fetch background images');
      }
      const data = await response.json();
      const urls = data.map(item => item.url); 
      const titles = data.map(item => item.title); 
      setBackgroundImageUrls(urls);
      setTitles(titles);
      setVisibleTextBoxes(new Array(data.length).fill(true));
    } catch (error) {

    }
  };

  const handleCloseTextBox = (index) => {
    // Update visibility for the specific index
    setVisibleTextBoxes(prevState => {
      const updatedState = [...prevState];
      updatedState[index] = false; // Set this particular text box to hidden
      return updatedState;
    });
  };

  const handleDownloadAppClick = () => {
    // Check if the user is on a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // If on mobile, redirect to the Play Store link directly
      window.location.href = 'https://play.google.com/store/apps/details?id=com.svastilabs.shishakbandhu';
    } else {
      // If on desktop, show the QR code modal
      setShowModal(true);
    }
  };

  const indexOfLastJob = currentPage * itemsPerPage;
  const indexOfFirstJob = indexOfLastJob - itemsPerPage;
  const currentJobs = featuredJobsList.slice(indexOfFirstJob, indexOfLastJob);
  const totalPages = Math.ceil(featuredJobsList.length / itemsPerPage);


  const filteredJobs = currentJobs.filter((job) => {
    const searchLower = searchTerm.toLowerCase();
    const cityMatch = job.instituteCity.toLowerCase().includes(searchLower);
    const distMatch = job.instituteDist.toLowerCase().includes(searchLower);
    const stateMatch = job.instituteState.toLowerCase().includes(searchLower);
    return cityMatch || distMatch || stateMatch;
  });

  const handleCloseModal = () => {
    // Close the modal
    setShowModal(false);
  };

  const handleCloseMoreJobs = () => {
    setShowMoreJobsModal(false);
    setCurrentPage(1);
  };
  const handleShowMoreJobs = () => {
    setShowMoreJobsModal(true);
  };
  return (
    <>
      <div
        className="home-component"
        style={{
          backgroundImage: `url(${backgroundImageUrls[currentBackgroundIndex] || Homelogo})`,

        }}
      >
         {visibleTextBoxes[currentBackgroundIndex] && (
        <div className="overlay-text-box">
          <button className="close-btn" onClick={() => handleCloseTextBox(currentBackgroundIndex)}>
            &times;
          </button>
          <h1 className="moving-text">WELCOME TO SHIKSHAK BANDHU</h1>
          <p>{titles[currentBackgroundIndex]}</p>
        </div>
      )}
        {/* Slider dots */}
        <div className="slider-dots-container">
          {backgroundImageUrls.map((url, index) => (
            <div
              key={index}
              className={`slider-dot ${index === currentBackgroundIndex ? 'active' : ''}`}
              onClick={() => setCurrentBackgroundIndex(index)}
            ></div>
          ))}
        </div>
      </div>

      <div style={{ textAlign: 'center', marginTop: '10px', }}>
        <h2 style={{ color: 'black', fontSize: '25px', marginTop: '40px' }}>Steps for Institutes to Post Jobs</h2>
        <div className="blog-card">
          <div className="steps-wrapper">
            <div className="step">
              <FontAwesomeIcon icon={faUserCircle} size="3x" style={{ color: '#1B2547' }} />
              <p style={{ fontWeight: 'bold' }}>1.Create a profile on our platform.</p>
            </div>
            <div className="step">
              <FontAwesomeIcon icon={faBriefcase} size="3x" style={{ color: '#1B2547' }} />
              <p style={{ fontWeight: 'bold' }}>2.Post your job listings with detailed descriptions.</p>
            </div>
            <div className="step">
              <FontAwesomeIcon icon={faFileAlt} size="3x" style={{ color: '#1B2547' }} />
              <p style={{ fontWeight: 'bold' }}>3.Review applications submitted by candidates.</p>
            </div>
            <div className="step">
              <FontAwesomeIcon icon={faUsers} size="3x" style={{ color: '#1B2547' }} />
              <p style={{ fontWeight: 'bold' }}>4.Hire suitable candidates for your institution.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-section">
        {blogs && blogs.length > 0 && (
          blogs.reduce((rows, blog, index) => {
            if (index % 3 === 0) rows.push([]); // Create a new row for every 3 blogs
            rows[rows.length - 1].push(
              <Col key={blog.id} md={4}> {/* 3 items per row */}
                <div
                  key={blog.id}
                  className="blog-card"
                  style={{
                    height: '710px', // Fixed width for the card
                    margin: '0 auto' // Center card within the column
                  }}
                >
                  <Card.Body>
                    <Card.Title className="custom-title">{blog.title}</Card.Title>
                    <Card.Img
                      variant="top"
                      src={blog.imageUrl}
                      alt="Blog Image"
                      style={{ cursor: 'pointer' }} // Apply cursor pointer only to image
                      onClick={() => window.open(`/blog/${blog.id}`)} // Click event only on image
                    />
                    <Card.Text style={{ fontFamily: "Arial" }}>
                      {blog.description}
                    </Card.Text>
                  </Card.Body>
                </div>
              </Col>
            );
            return rows;
          }, []).map((row, index) => (
            <Row key={index} className="justify-content-md-center">
              {row}
            </Row>
          ))
        )}
      </div>


      <div className="featured-jobs-section">
        <div className="d-flex justify-content-between align-items-center">
          <h1 style={{ color: 'black', textAlign: 'center', fontSize: '25px', flex: 1 }}>Featured Jobs</h1>
          <Button variant="outline-primary" onClick={handleShowMoreJobs} className="ml-auto">
            More Jobs <i className="fas fa-arrow-right ml-2"></i>
          </Button>
        </div>
        <div className="featured-jobs-row" style={{ marginTop: '10px' }}>
          {featuredJobs && featuredJobs.length > 0 && (
            featuredJobs.reduce((rows, job, index) => {
              if (index % 4 === 0) rows.push([]);
              rows[rows.length - 1].push(
                <Col key={job.id} md={3}>
                  <div className="job-card">
                    <Card.Body>
                      <Card.Header
                        className="text-truncate" // Ensures text will truncate if too long
                        style={{ backgroundColor: '#1B2547', color: 'white' }}
                      >
                        {job.roleVal}
                      </Card.Header>

                      <Card.Text className="custom-font-size d-flex align-items-center">
                        <i className="fas fa-money-bill-alt mr-2"></i>
                        <span className="text-truncate">₹{job.salaryFrom} - ₹{job.salaryTo}</span>
                      </Card.Text>

                      <Card.Text className="custom-font-size d-flex align-items-center">
                        <i className="far fa-calendar-alt mr-2"></i>
                        <span className="text-truncate">{job.schdate}</span>
                      </Card.Text>

                      <Card.Text className="custom-font-size d-flex align-items-center">
                        <i className="fas fa-user-clock mr-2"></i>
                        <span>{job.fulltime === 'PT' ? 'Part Time' : 'Full Time'}</span>
                      </Card.Text>

                      <div className="mt-4">
                        <Button
                          variant="warning"
                          onClick={handleDownloadAppClick}
                          className="w-100"
                        >
                          Apply
                        </Button>
                      </div>
                    </Card.Body>
                  </div>


                </Col>
              );
              return rows;
            }, []).map((row, index) => (
              <Row key={index} className="justify-content-md-center">
                {row}
              </Row>
            ))
          )}
        </div>
      </div>
      {showModal && (
        <div className="qrcode-modal-overlay">
          <div className="qrcode-modal-content">
            <div className="qrcode-modal-header">
              <h2 className="modal-title">Download the App</h2>
              <button className="cancel-button" onClick={handleCloseModal}>
                &#x2715;
              </button>
            </div>
            <div className="qrcode-modal-body">
              <div style={{ textAlign: 'center' }}>
                <p>Scan this QR code to download the app from the Play Store</p>
                <QRCode
                  value="https://play.google.com/store/apps/details?id=com.svastilabs.shishakbandhu"
                  size={200}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showMoreJobsModal && (
        <div className="featureJobs-modal-overlay">
          <div className="featureJobs-modal-content">
            <div className="featureJobs-modal-header">
              <h2 className="modal-title">Featured Jobs</h2>
              <button className="cancel-button" onClick={handleCloseMoreJobs}>
                &#x2715;
              </button>
            </div>

            <div className="featureJobs-modal-body">
              <div className="featurejob-search-bar">
                <input
                  type="text"
                  placeholder="Search by city, district, or state"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />

              </div>

              <table className="styled-table">
                <thead>
                  <tr>
                    <th>Role</th>
                    <th>Salary</th>
                    <th>Posted Date</th>
                    <th>Type</th>
                    <th>City</th>
                    <th>District</th>
                    <th>State</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredJobs.length > 0 ? (
                    filteredJobs.map((job) => (
                      <tr key={job.id}>
                        <td>{job.jobPost.roleVal}</td>
                        <td>₹{job.jobPost.salaryFrom} - ₹{job.jobPost.salaryTo}</td>
                        <td>{job.jobPost.schdate}</td>
                        <td>{job.jobPost.fulltime === 'PT' ? 'Part Time' : 'Full Time'}</td>
                        <td>{job.instituteCity}</td>
                        <td>{job.instituteDist}</td>
                        <td>{job.instituteState}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" style={{ textAlign: 'center' }}>
                        No jobs found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className="features-pagination-controls">
                <button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '5px' }} />
                  Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                  <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: '5px' }} />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>

  );
}

const AboutUsCard = () => {
  return (
    <div className="about-card">
      <Row>
        <Col md="5">
          <div style={{ paddingLeft: "20px", paddingRight: "20px", marginTop: "20px" }}>
            <div className="video-responsive" style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden", maxWidth: "100%", background: "#000", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", border: "3px solid #ddd", borderRadius: "8px" }}>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/tgbNymZ7vqY?controls=0"
                title="Shikshak Bandhu Introduction"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", borderRadius: "8px" }}>
              </iframe>
            </div>
          </div>
        </Col>

        <Col md="7">
          <Card className="shadow-lg mb-4" style={{ display: 'flex', flexDirection: 'column' }}>
            <CardBody style={{ flex: '1' }}>
              <h3 className="section-heading">India's First Network of Private School Teachers</h3>
              <p style={{ fontFamily: "Arial", textAlign: "left", fontSize: "17px", paddingLeft: "20px" }}>
                Welcome to Shikshak Bandhu, India's largest network that connects Teachers and Schools with more than 1 Lakh eligible teachers as members and 8000 schools spread in more than 20 states and territories nationwide.
              </p>

              <h4 className="section-heading">Vision</h4>
              <p style={{ fontFamily: "Arial", textAlign: "left", fontSize: "17px", paddingLeft: "20px" }}>
                To create opportunities for every student through his/her school to find suitable teachers. A school with sufficient number of Creative, Productive and Active teachers can be a farm field of emerging hopeful citizens.
              </p>

              <h4 className="section-heading">Mission</h4>
              <p style={{ fontFamily: "Arial", textAlign: "left", fontSize: "17px", paddingLeft: "20px" }}>
                The mission of Shikshak Bandhu is simple as well as noble: Every private school teacher in India should find it easy to find his destination school. Similarly, no school should compromise with the situation of not getting a suitable teacher. Also, helping the stakeholders should be at affordable charges unlike any other traditional ways of providing the manpower or job opportunities.
              </p>

              <h4 className="section-heading">Who are we?</h4>
              <p style={{ fontFamily: "Arial", textAlign: "left", fontSize: "17px", paddingLeft: "20px" }}>
                The idea of Shikshak Bandhu began in the year 2020 with a simple online form during the Covid Pandemic as it was difficult to find suitable faculty required for our Client Schools. Today, Shikshak Bandhu is equipped with a dedicated Website (for Institutions) and Mobile App (for teachers) to keep them on set any time to get connected in a completely self-directed and hassle-free automated software with a big database in the background. It is run by a team of Teachers having decades of academic and administrative experience. The platform is very much tailor-made to provide complete human resource accumulation-related issues.
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};


// ContactUsCard component for displaying the "Contact Us" content in a card
const ContactUsCard = () => {
  const iconSize = "1.2rem";
  const redIconStyle = { color: "red" };
  return (
    <div className="about-card">
      <h3 className="section-heading">Contact Information</h3>
      <Row className="mb-1">
        <Col md={12}>
          <Card rounded>
            <Card.Body>
              <Row>
                <Col md={1} className="text-right">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    size="2x"
                    style={redIconStyle}
                  />
                </Col>
                <Col md={11}>
                  <p>
                    <strong>
                      Address: Shikshak Bandhu, KAPPEC,
                      Werehouse No2, Amargol Hubli, Karnataka 580025{" "}
                    </strong>
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col md={12}>
          <Card rounded>
            <Card.Body>
              <Row>
                <Col md={1} className="text-right">
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="2x"
                    style={redIconStyle}
                  />
                </Col>
                <Col md={11}>
                  <p>
                    <strong>Phone: 0836-2955727, +91-889988988 </strong>
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col md={12}>
          <Card rounded>
            <Card.Body>
              <Row>
                <Col md={1} className="text-right">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="2x"
                    style={redIconStyle}
                  />
                </Col>
                <Col md={11}>
                  <p>
                    <strong>Email:</strong>{" "}
                    <strong>
                      <a href="mailto:support@shikshakbandhu.com">
                        support@shikshakbandhu.com
                      </a>
                    </strong>
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col md={12}>
          <Card rounded>
            <Card.Body>
              <Row>
                <Col md={1}>
                  <FontAwesomeIcon
                    icon={faGlobe}
                    size="2x"
                    style={redIconStyle}
                  />
                </Col>
                <Col md={11}>
                  <p>
                    <strong>Website:</strong>{" "}
                    <strong>
                      <a
                        href="http://www.shikshakbandhu.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        http://www.shikshakbandhu.com/
                      </a>
                    </strong>
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </div>
  );
}


const Planscard = () => {
  return (
    <div className="about-card">
      <h3 className="section-heading">
        Plans for Institutions
      </h3>
      <img src={InstPlans} alt="Plans for Institutions" />
      <h3 className="section-heading" style={{ marginTop: '10px' }}>
        Plans for Teachers & Staff
      </h3>
      <img src={TeachersPlans} alt="Plans for Teachers & Staff" />
    </div>
  );
}

const LandingPage = () => {
  const [visibleDiv, setVisibleDiv] = useState("div1");
  const [showModal, setShowModal] = useState(false);


  const handleDownloadAppClick = () => {
    // Check if the user is on a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // If on mobile, redirect to the Play Store link directly
      window.location.href = 'https://play.google.com/store/apps/details?id=com.svastilabs.shishakbandhu';
    } else {
      // If on desktop, show the QR code modal
      setShowModal(true);
    }
  };


  const handleHomeClick = () => {
    setVisibleDiv("div1");
  };

  const handleAboutClick = () => {
    setVisibleDiv("div2");
  };

  const handleplanclick = () => {
    setVisibleDiv("div3");
  };

  const handleContactClick = () => {
    setVisibleDiv("div4");
  };

  const handleCloseModal = () => {
    // Close the modal
    setShowModal(false);
  };
  return (
    <div>
      <div className="appbar">
        <img src={Logo} alt="Logo" className="logo" />
        <h2>{env === 'P' ? 'Welcome to Shikshak Bandhu' : 'Welcome to Shikshak Bandhu......'}</h2>
        <div className="nav-links">
          <button onClick={handleHomeClick}>Home</button>
          <button onClick={handleAboutClick}>About Us</button>
          <button onClick={handleplanclick}>Plans</button>
          <button onClick={handleContactClick}>Support</button>
          <button onClick={handleDownloadAppClick}>Download App for Teachers</button>
          <Link to="/login">Institute Login</Link>
        </div>
      </div>

      {visibleDiv === "div1" && (
        <HomeComponent />
      )}
      {visibleDiv === "div2" && (
        <div className="content-div">
          <div className="centered-card">
            <AboutUsCard />
          </div>
        </div>
      )}
      {visibleDiv === "div3" && (
        <div className="centered-card">
          <Planscard />
        </div>
      )}

      {visibleDiv === "div4" && (
        <div className="content-div">
          <div className="centered-card">
            <ContactUsCard />
          </div>
        </div>
      )}
      <div>
        <footer className="footer" style={{ textAlign: 'center' }}>
          <Row className="align-items-center justify-content-between">
            <Col xl="6" className="d-flex justify-content-start">
              <div style={{ marginLeft: '10px', fontWeight: 'bold' }} className="copyright text-center text-xl-left text-muted">
                Copyright© {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href={`${process.env.PUBLIC_URL}/TermsCondition.html`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Svasti Innovation Labs Pvt Ltd
                </a>
              </div>

            </Col>

            <Col xl="6" className="d-flex justify-content-end social-media-links">
              {/* <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>Follow us on</div> */}
              <a href="https://www.linkedin.com/in/shikshak-bandhu-4174a432b/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={32} style={{ color: 'blue', marginRight: '10px' }} />
              </a>
              <a href="https://www.facebook.com/profile.php?id=61565913976261" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={32} style={{ color: 'blue', marginRight: '10px' }} />
              </a>
              <a href="https://www.youtube.com/@Shikshakbandhu" target="_blank" rel="noopener noreferrer">
                <FaYoutube size={32} style={{ color: 'red', marginRight: '10px' }} />
              </a>
              <a href="https://x.com/shikshakbandhu1" target="_blank" rel="noopener noreferrer">
                <FaTwitter size={32} style={{ color: 'blue' }} />
              </a>
            </Col>
          </Row>
          <div style={{ marginLeft: '10px', fontWeight: 'bold', cursor: 'pointer', textAlign: 'left' }}>
            <a
              href={`${process.env.PUBLIC_URL}/privacy_policy.html`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'blue', textDecoration: 'none' }}
            >
              Privacy Policy
            </a>
          </div>

        </footer>
      </div>
      {showModal && (
        <div className="qrcode-modal-overlay">
          <div className="qrcode-modal-content">
            <div className="qrcode-modal-header">
              <h2 className="modal-title">Download the App</h2>
              <button className="cancel-button" onClick={handleCloseModal}>
                &#x2715;
              </button>
            </div>
            <div className="qrcode-modal-body">
              <div style={{ textAlign: 'center' }}>
                <p>Scan this QR code to download the app from the Play Store</p>
                <QRCode
                  value="https://play.google.com/store/apps/details?id=com.svastilabs.shishakbandhu"
                  size={200}
                />
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}

export default LandingPage;

