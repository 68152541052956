import React, { useState, useEffect } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { useSharedvars } from 'views/examples/Sharedvars';
import "react-toastify/dist/ReactToastify.css";
import { FaUpload, } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import { env, fileUrl, baseUrl } from '../examples/Constant';
import "./Prod.css"
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  Navbar,
  NavbarBrand,
  Nav,
  Col,
  Row,
  NavItem,
  Container,
} from "reactstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useAuth } from "views/examples/AuthContext";
import ColorHeader from "./ColorHeader";
import { useApiUrl } from "views/examples/ApiUrlContext";
import useUtil from "views/examples/Util"
import Topbar from "../examples/TopBar"
import MySnackbar from 'views/examples/Snackbar'

const Profile = () => {
  const { getDropdownData } = useSharedvars();
  const { userData } = useAuth();
  const { instbasic } = useAuth();
  const { apiUrl } = useApiUrl();
  const { userLoginCode } = useAuth();
  const [isValid, setIsValid] = useState(true);
  const [instituteid, setinstituteid] = useState("");
  const [ibasicicode, setibasicicode] = useState("");
  const [iclasscicode, seticlasscicode] = useState("");
  const [ifacilityicode, setifacilityicode] = useState("");
  const [iplanicode, setiplanicode] = useState("");
  const [ipaymenticode, setipaymenticode] = useState("");
  const [ipreferbasicicode, setipreferbasicicode] = useState("");
  const [name, setName] = useState("");
  const [nameError, setnameError] = useState("");
  const [apiLiveKey, setApiLiveKey] = useState("");
  const [currency, setCurrency] = useState('INR');
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [Stateoptions, setStateoptions] = useState([]);
  const [districtOptions, setDestictoption] = useState([]);
  const [talukaOptions, settalkuoptions] = useState([]);
  const [cityError, setCityError] = useState("");
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [syallbusoptions, setSyallbusoptions] = useState([{}]);
  const [firstLanguage, setFirstLanguage] = useState("");
  const [firstLanguageError, setFirstLanguageError] = useState("");
  const [classRun, setClassRun] = useState("");
  const [classRunError, setClassRunError] = useState("");
  const [classrunFrom, setClassromFrom] = useState("");
  const [classroomFromError, setclassromFromError] = useState("");
  const [classrunTo, setClassromTo] = useState("");
  const [classroomToError, setclassromToError] = useState("");
  const [selectedSecondLanguage, setSelectedSecondLanguage] = useState(null);
  const [selectedSecondLanguageError, setSelectedSecondLanguageError] =
    useState("");
  const [selectedlanguages, setSelectedlanguages] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [ClassesFromOption, setClassesFromOption] = useState([]);
  const [ClassesToOption, setClassesToOption] = useState([]);
  const [affiliationTypeOptions, setAffiliationTypeOptions] = useState([]);
  const [Langugeoptions, setLangugeoptions] = useState([]);
  const [thirdLanguage, setThirdLanguage] = useState("");
  const [thirdLanguageError, setThirdLanguageError] = useState("");
  const [nameOfTrust, setNameOfTrust] = useState("");
  const [nameOfTrustError, setNameOfTrustError] = useState("");
  const [medium, setMedium] = useState("");
  const [mediumError, setMediumError] = useState("");
  const [affiliationType, setAffiliationType] = useState("");
  const [affiliationTypeError, setAffiliationTypeError] = useState("");
  const [establishmentYear, setEstablishmentYear] = useState("");
  const [establishmentYearError, setEstablishmentYearError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [landline, setLandline] = useState("");
  const [landlineError, setLandlineError] = useState("");
  const [applicationNumber, setApplicationNumber] = useState("");
  const [applicationNumberError, setApplicationNumberError] = useState("");
  const [udisenumber, setUdiseNumber] = useState("");
  const [udisenumberNumberError, setUdiseNumberError] = useState("");
  const [completeAddress, setCompleteAddress] = useState("");
  const [completeAddressError, setCompleteAddressError] = useState("");
  const [address2, setAddress2] = useState("");
  const [address2Error, setAddress2Error] = useState("");
  const [taluka, setTaluka] = useState("");
  const [talukaError, setTalukaError] = useState("");
  const [district, setDistrict] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [principalNumber, setPrincipalNumber] = useState("");
  const [hrNumber, setHrNumber] = useState("");
  const [principalNumberError, setPrincipalNumberError] = useState("");
  const [hrNumberError, setHrNumberError] = useState("");
  const [stateError, setstateError] = useState("");
  const [instituteicode, setInstituteicode] = useState("");


  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarBgColor, setSnackbarBgColor] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);

  //Additional details
  const initialClassDetails = [
    { id: "", className: "", divisions: "", strength: "", fees: "" },
  ];
  const initialFacilities = [
    { id: "", type: "", value: "1" },
  ];
  const [isFieldsEmpty, setIsFieldsEmpty] = useState(false);
  const [classDetails, setClassDetails] = useState(initialClassDetails);
  const [removeRowIndex, setRemoveRowIndex] = useState(null);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const [facilitiesOptions, setFacilitiesOptions] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);

  const [classDetailsError, setClassDetailsError] = useState("");
  const [facilitiesError, setFacilitiesError] = useState("");

  //Upload documents validation and Usestate
  const [schoolWebsite, setSchoolWebsite] = useState("");
  const [schoolWebsiteError, setschoolWebsiteError] = useState("");
  const [videoURL, setVideourl] = useState("");
  const [videoURLError, setvideoURLError] = useState("");

  const [ChairmanmMessage, setChairmanmMessage] = useState("");
  const [ChairmanmMessageError, setChairmanmMessageError] = useState("");
  const [logoPath, setLogoPath] = useState(null);
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState("");
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadSuccessLogo, setUploadSuccessLogo] = useState(false);
  const [pdfLink, setPdfLink] = useState("");
  const [uploadSuccessPdf, setUploadSuccessPdf] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [photoPath, setPhotoPath] = useState("");
  const [pdfPath, setPdfPath] = useState("");

  //Employment startgies
  const [qualificationOptions, setqualificationOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const initialSalaryRanges = [
    { id: '', selectedQualification: '', salaryFrom: '', salaryTo: '' },
  ];
  const [salaryRanges, setSalaryRanges] = useState(initialSalaryRanges);
  const [selectedQualifications, setSelectedQualifications] = useState('');
  const [removeEmployeeRowIndex, setRemoveEmployeeRowIndex] = useState(null);
  const [isEmployeeConfirmationDialogOpen, setEmployeeConfirmationDialogOpen] = useState(false);
  //JOb security
  const [employeeIdPE, setEmployeeIdPE] = useState("");
  const [employeeIdESI, setEmployeeIdESI] = useState("");
  const [clTeacherStaff, setClTeacherStaff] = useState("");
  const [clTeacherNonStaff, setClTeacherNonStaff] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");
  const [originalCertificates, setOriginalCertificates] = useState("");
  const [showSalaryDuringVacation, setShowSalaryDuringVacation] = useState("");
  const [agreement, setAgreement] = useState("");
  const [agreementDuration, setAgreementDuration] = useState("");
  const [showAgreementDuration, setShowAgreementDuration] = useState(false);

  const [employeeIdESIError, setEmployeeIdESIError] = useState("");
  const [clTeacherStaffError, setClTeacherStaffError] = useState("");
  const [clTeacherNonStaffError, setClTeacherNonStaffError] = useState("");
  const [noticePeriodError, setNoticePeriodError] = useState("");
  const [originalCertificatesError, setOriginalCertificatesError] =
    useState("");
  const [showSalaryDuringVacationError, setShowSalaryDuringVacationError] =
    useState("");
  const [agreementError, setAgreementError] = useState("");
  const [agreementDurationError, setAgreementDurationError] = useState("");

  //plan  States
  const [planOptions, setPlanOptions] = useState([]);
  const [selectedplanoption, setselectedplanoption] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  const [paymentdate, setpaymentdate] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [planError, setplanError] = useState("");
  const [currentPlanDetails, setCurrentPlanDetails] = useState({
    currentPlan: "",
    planStartDate: "",
    planEndDate: "",
  });
  const [selectedPlan, setSelectedPlan] = useState("basic");
  const [availablePlans, setAvailablePlans] = useState([]);
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [userIdError, setUserIdError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  //Expand Pages States
  const [expanded, setExpanded] = useState(false);
  const [expandedAdditionalDetails, setExpandedAdditionalDetails] =
    useState(false);
  const [expandedUploadDocuments, setExpandedUploadDocuments] = useState(false);
  const [expandedPlan, setExpandedPlan] = useState(false);
  const [expandedSalaryOffered, setExpandedSalaryOffered] = useState(false);
  const [expandedJobSecurity, setExpandedJobSecurity] = useState(false);

  const [ibasicid, setibasicid] = useState("");
  const [iclasscid, seticlasscid] = useState("");
  const [ifacilityid, setifacilityid] = useState("");
  const [iplanid, setiplanid] = useState("");
  const [ipaymentid, setipaymentid] = useState("");
  const [ipreferbasicid, setipreferbasicid] = useState("");

  const [totalStrength, setTotalStrength] = useState("");
  const [selectedApplicationCopyPDF, setSelectedApplicationCopyPDF] =
    useState(null);
  const [primarySalary, setPrimarySalary] = useState("");
  const [secondarySalary, setSecondarySalary] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [paymentLink, setPaymentLink] = useState('');
  const [utrNumber, setUtrNumber] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !userData ||
      userData === undefined ||
      userData === '' ||
      (typeof userData === 'object' && Object.keys(userData).length === 0)
    ) {
      navigate('/auth/login');
    }
  }, [userData, navigate]);
  const handleLanguagesChange = (selectedOptions) => {
    if (selectedOptions.length <= 3) {
      const newLanguages = selectedOptions.map(option => option.value);
      setSelectedlanguages(newLanguages);
      setFirstLanguage(newLanguages[0] || '');
      setSelectedSecondLanguage(newLanguages[1] || '');
      setThirdLanguage(newLanguages[2] || '');
      setFirstLanguageError('');
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  //Onchange Method States
  const handlePrincipalChange = (e) => {
    setPrincipalNumber(e.target.value);
    setPrincipalNumberError("");
  };



  const handleMediumChange = (selectedOption) => {
    if (selectedOption) {
      const selectedMediumValue = selectedOption.value;
      setMedium(selectedMediumValue);
      setMediumError("");
    } else {
      setMedium('');
    }
  };

  const handleclassrunToChange = (selectedOption) => {
    if (selectedOption) {
      setClassromTo(selectedOption.value);
      setclassromToError('');
    } else {
      setClassromTo(null);
      setclassromToError('');
    }
  };


  const handleclassrunFromChange = (selectedOption) => {
    if (selectedOption) {
      const selectedclassrunFromValue = selectedOption.value;
      setClassromFrom(selectedclassrunFromValue);
      setclassromFromError('');
    } else {
      setClassromFrom(null);
      setclassromFromError('');
    }
  };


  const handleAffiliationTypeChange = (selectedOption) => {
    if (selectedOption) {
      const selectedAffiliationTypeValue = selectedOption.value;
      setAffiliationType(selectedAffiliationTypeValue);
      setAffiliationTypeError('');
    } else {
      setAffiliationType('');


    }
  };

  const handlePincodeChange = async (e) => {
    const enteredPincode = e.target.value.trim();
    setPincode(enteredPincode);
    setPincodeError("");

    if (enteredPincode.length !== 6 || isNaN(enteredPincode)) {
      setPincodeError("Please enter a valid 6-digit pincode.");
      return;
    }

    try {
      const response = await axios.get(`https://api.postalpincode.in/pincode/${enteredPincode}`);

      if (response.data && response.data.length > 0 && response.data[0].Status === "Success") {
        const postOffice = response.data[0].PostOffice[0];

        setCity(postOffice.Block);
        setDistrict(postOffice.District);
        setState(postOffice.State);
      } else {
        // Show error message if pincode not found
        setCity("");
        setDistrict("");
        setState("");
        setPincodeError("Pincode not found.");
      }
    } catch (error) {

      setPincodeError("Error fetching pincode details. Please try again later.");
    }
  }


  const handlePayment = async (amount) => {
    try {
      // First API call to get Razorpay config
      const configResponse = await axios.get(`${apiUrl}/plans/razorpay/config`);

      const data = configResponse.data;
      if (data.cd === 1) {
        setSnackbarMessage(data.description);
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
        return;
      } else {
        setApiLiveKey(data.apiLiveKey); // Set the API live key
      }

      // Second API call to create an order
      const orderResponse = await axios.post(`${apiUrl}/plans/create-order`, {
        amount: amount, // Convert amount to paise
        currency: currency,
      });

      const options = {
        key: apiLiveKey, // Use the live key obtained from the first API call
        amount: orderResponse.data.amount,
        currency: orderResponse.data.currency,
        order_id: orderResponse.data.id,
        name: 'Shikshak Bandhu',
        description: 'Plan Subscription Fee',
        handler: function (response) {
          setUtrNumber(response.razorpay_payment_id);
          // Additional actions upon successful payment
        },
        prefill: {
          name: name,
          email: email,
          contact: landline,
        },
        notes: {
          address: completeAddress,
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();

      rzp1.on('payment.failed', function (response) {
        setSnackbarMessage(`Payment failed: ${response.error.description}`);
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
      });
    } catch (error) {
      console.error('Error initiating payment', error);
      setSnackbarMessage('Payment initiation failed');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
    }
  };

  const handleFacilitiesChange = (selectedOptions) => {
    const newSelectedFacilities = selectedOptions.map(option => ({
      type: option.value,
      value: "1"
    }));
    setSelectedFacilities(newSelectedFacilities);
  };


  useEffect(() => {
    // Fetch available plans from the API
    const fetchAvailablePlans = async () => {
      try {
        const bearerToken = userData.accessToken;
        const response = await fetch(`${apiUrl}/plans/Type/i`,

          {
            headers: {
              'Authorization': `Bearer ${bearerToken}`,
              'Content-Type': 'application/json', // Adjust the content type if needed
            },
          })
        const data = await response.json();
        setAvailablePlans(data);

        if (response && (response.status === 403 || response.status === 400)) {
          const headers = response.headers;
          if (headers && headers.Exception === 'SignatureException') {
            await useUtil.logout();
            // clear shared Pref & perform logout
          } else if (headers && headers.Exception === 'ExpiredJwtException') {
            bearerToken = await useUtil.refreshToken();
            // Use the newBearerToken as needed
          }
        }// Update state with fetched plans
      } catch (error) {

      }
    };

    fetchAvailablePlans(); // Call the fetchAvailablePlans function when the component mounts
  }, []); // 

  const handleHrNumberChange = (e) => {
    setHrNumber(e.target.value);
    setHrNumberError("");
  };

  const handleOriginalCertificatesChange = (selectedOption) => {
    setOriginalCertificates(selectedOption.value);
  };

  const handleNoticePeriodChange = (e) => {
    const value = e.target.value;
    setNoticePeriod(value);
    validateNoticePeriod(value);
  };


  const validateNoticePeriod = (value) => {

    const inputValue = parseInt(value, 10);

    if (isNaN(inputValue) || inputValue < 1 || inputValue > 12) {
      setNoticePeriodError("Notice period must be a number between 1 and 12 Months.");
    } else {
      setNoticePeriodError("");
    }

    return !isNaN(inputValue) && inputValue >= 1 && inputValue <= 12;
  };

  const validateAgreementDuration = (value) => {

    const inputValue = parseInt(value, 10);

    if (isNaN(inputValue) || inputValue < 1 || inputValue > 12) {
      setAgreementDurationError("Agreement Duration must be a number between 1 and 12 Months.");
    } else {
      setAgreementDurationError("");
    }

    return !isNaN(inputValue) && inputValue >= 1 && inputValue <= 12;
  };

  const handleAgreementDurationChange = (e) => {
    const value = e.target.value;
    setAgreementDuration(value);
    validateAgreementDuration(value);
    setAgreementDurationError("");
  };


  const handleSalaryDuringVacationChange = (selectedOption) => {
    setShowSalaryDuringVacation(selectedOption.value);
    setShowSalaryDuringVacationError("");
  };

  const handleAddRow = () => {
    // Add a new empty row when the "Add Row" button is clicked
    setSalaryRanges((prevRanges) => [
      ...prevRanges,
      { selectedQualification: "", salaryFrom: "", salaryTo: "" },
    ]);
  };


  const handleSalaryChange = (index, field, value) => {
    // Allow empty values to clear the input field
    if (value === '') {
      const updatedRanges = [...salaryRanges];
      updatedRanges[index][field] = '';

      setSalaryRanges(updatedRanges);
      setErrorMessage(''); // Clear error message if any
      return;
    }

    // Parse the value as a number
    const numericValue = parseInt(value, 10);

    // Ensure the value is a valid number and not negative
    if (isNaN(numericValue) || numericValue < 0) {
      return;
    }

    const updatedRanges = [...salaryRanges];
    updatedRanges[index][field] = numericValue.toString();

    // Validate both Salary From and Salary To are entered
    if (
      updatedRanges[index].salaryFrom !== '' &&
      updatedRanges[index].salaryTo !== ''
    ) {
      const salaryFrom = parseFloat(updatedRanges[index].salaryFrom);
      const salaryTo = parseFloat(updatedRanges[index].salaryTo);

      // Validate that Salary To is greater than Salary From
      if (salaryTo <= salaryFrom) {
        // Display error message
        setErrorMessage('Maximum Salary must be greater than Minimum Salary');

      } else {
        // Clear error message if validation passes
        setErrorMessage('');
      }
    }

    setSalaryRanges(updatedRanges);
  };

  const handleQualificationChange = (index, selectedValue) => {
    // Update the specific index in the selectedQualifications array
    setSelectedQualifications((prevQualifications) => {
      const updatedQualifications = [...prevQualifications];
      updatedQualifications[index] = selectedValue; // Update the selected value at the specific index
      return updatedQualifications;
    });
  };

  // const handleQualificationChange = (index, selectedQualification) => {
  //   setSalaryRanges(prevRanges => {
  //     const updatedRanges = [...prevRanges];
  //     updatedRanges[index] = {
  //       ...updatedRanges[index],
  //       selectedQualification: selectedQualification
  //     };
  //     return updatedRanges;
  //   });
  //   setSelectedQualifications((prevQualifications) => {
  //     const updatedQualifications = [...prevQualifications];
  //     updatedQualifications[index] = selectedQualification; // Update the selected value at the specific index
  //     return updatedQualifications;
  //   });
  // };


  useEffect(() => {
    // Update End Date when selectedPlanOption changes
    setEndDate(getFormattedDate(getNextYearDate()));

  }, []);


  const updateClassDetail = (prevDetails, index, key, value) => {
    const updatedDetails = [...prevDetails];
    updatedDetails[index][key] = value;
    return updatedDetails;
  };

  const handleRemoveClassRow = (index) => {
    if (classDetails.length > 1) {
      setRemoveRowIndex(index);
      setConfirmationDialogOpen(true);
    }
  };

  const handleConfirmRemove = () => {
    const updatedDetails = [...classDetails];
    updatedDetails.splice(removeRowIndex, 1);
    setClassDetails(updatedDetails);
    setRemoveRowIndex(null);
    setConfirmationDialogOpen(false);
  };

  const handleCancelRemove = () => {
    setRemoveRowIndex(null);
    setConfirmationDialogOpen(false);
  };

  const handleAddClassRow = () => {
    setClassDetails((prevClassDetails) => [
      ...prevClassDetails,
      { className: '', divisions: '', strength: '', fees: '' },
    ]);
  };

  const handleRemoveRow = (index) => {
    if (salaryRanges.length > 1) {
      setRemoveEmployeeRowIndex(index);
      setEmployeeConfirmationDialogOpen(true);
    }
  };


  const handleConfirmEmployeeRemove = () => {
    const updatedSalaryRanges = [...salaryRanges];
    updatedSalaryRanges.splice(removeEmployeeRowIndex, 1);
    setSalaryRanges(updatedSalaryRanges);
    setSelectedQualifications((prevQualifications) =>
      prevQualifications.filter((_, idx) => idx !== removeEmployeeRowIndex)
    );
    setRemoveEmployeeRowIndex(null);
    setEmployeeConfirmationDialogOpen(false);
  };

  const handleCancelEmployeeRemove = () => {
    setRemoveEmployeeRowIndex(null);
    setEmployeeConfirmationDialogOpen(false);
  };





  function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function getNextYearDate() {
    const nextYear = new Date();
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    return nextYear;
  }

  //Input validation States method in profile Information
  const validatename = (value) => {
    if (!value.trim()) {
      setnameError("Name is required");
      return false;
    }
    setnameError("");
    return true;
  };
  const validateCity = (value) => {
    if (!value) {
      setCityError("City is required");
      return false;
    }
    setCityError("");
    return true;
  };
  const validatePrincipalnumber = (value) => {
    const numericRegex = /^[0-9]+$/;

    if (!value.trim() || !numericRegex.test(value.trim()) || value.trim().length !== 10) {
      setPrincipalNumberError("Enter a valid 10-digit numeric phone number");
      return false;
    }

    setPrincipalNumberError(""); // Clear the error when the validation passes
    return true;
  };

  const validateHRnumber = (value) => {
    const numericRegex = /^[0-9]+$/;

    if (!value.trim() || !numericRegex.test(value.trim()) || value.trim().length !== 10) {
      setHrNumberError("Enter a valid 10-digit numeric HR phone number");
      return false;
    }

    setHrNumberError(""); // Clear the error when the validation passes
    return true;
  };

  const validatePincode = (value) => {
    if (value === null || isNaN(value)) {
      setPincodeError("Pin Code is required");
      return false;
    }

    const pincodeRegex = /^[0-9]{6}$/; // Assuming a 6-digit pin code, adjust as needed

    if (!pincodeRegex.test(value)) {
      setPincodeError("Enter a valid 6-digit Pin Code");
      return false;
    }

    setPincodeError("");
    return true;
  };

  const validateFirstLanguage = () => {
    if (selectedlanguages.length === 0) {
      setFirstLanguageError("Language is required");
      return false;
    } else {
      setFirstLanguageError("");
      return true;
    }
  };

  const validateClassRunFrom = (value) => {
    if (!value) {
      setclassromFromError("Class is required");
      return false;
    }
    setclassromFromError("");
    return true;
  };

  const validateClassRunTo = (value) => {
    const intValue = parseInt(value, 10);
    const intClassRunFrom = parseInt(classrunFrom, 10);

    if (!value) {
      setclassromToError("Class is required");
      return false;
    }
    if (intValue <= intClassRunFrom) {
      setclassromToError("Classes Run To should be greater than classes Run From");
      return false;
    }
    setclassromToError("");
    return true;
  };


  const validateNameOfTrust = (value) => {
    if (!value.trim()) {
      setNameOfTrustError("Name of Trust or Society is required");
      return false;
    }
    setNameOfTrustError("");
    return true;
  };

  const validateMedium = (value) => {
    if (!value
    ) {
      setMediumError("Medium is required");
      return false;
    }
    setMediumError("");
    return true;
  };

  const validateEstablishmentYear = (value) => {
    const yearRegex = /^[0-9]{4}$/;
    const currentYear = new Date().getFullYear();

    if (!value || !yearRegex.test(value)) {
      setEstablishmentYearError("Enter a valid year (4 digits)");
      return false;
    }

    if (parseInt(value, 10) > currentYear) {
      setEstablishmentYearError(`Year cannot be greater than ${currentYear}`);
      return false;
    }

    setEstablishmentYearError("");
    return true;
  };
  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!value.trim() || !emailRegex.test(value.trim())) {
      setEmailError("Enter a valid email address");
      return false;
    }
    setEmailError("");
    return true;
  };

  const validateLandline = (value) => {
    const numericRegex = /^[0-9]+$/;

    // Check if the value is not empty and contains only numeric characters
    if (!value.trim() || !numericRegex.test(value.trim())) {
      setLandlineError("Enter a valid phone number");
      return false;
    }

    // Check if the number has exactly 10 digits
    if (value.trim().length !== 10) {
      setLandlineError("Phone number should be 10 digits");
      return false;
    }

    // Clear the error if all conditions are met
    setLandlineError("");
    return true;
  };

  const validateApplicationNumber = (value) => {
    if (!value) {
      setApplicationNumberError("Enter a valid affiliation number");
      return false;
    }

    if (value.length > 20) {
      setApplicationNumberError("Affiliation number cannot exceed 20 characters");
      return false;
    }

    setApplicationNumberError("");
    return true;
  };


  const validateUdisenumberNumber = (value) => {
    if (!value) {
      setUdiseNumberError("");
      return true;
    } else if (value) {
      const udisenumberRegex = /^[0-9]{11}$/; // Match exactly 11 digits
      if (!udisenumberRegex.test(value)) {
        setUdiseNumberError("Enter a valid 11 digit numeric values");
        return false;
      } else {
        return udisenumberRegex.test(value);
      }
    }

  };


  const validateCompleteAddress = (value) => {
    if (!value.trim()) {
      setCompleteAddressError("Address is required");
      return false;
    }
    setCompleteAddressError("");
    return true;
  };


  const validateDistrict = (value) => {
    if (!value) {
      setDistrictError("District is required");
      return false;
    }
    setDistrictError("");
    return true;
  };

  const validateSchoolwebsite = (value) => {
    const urlPattern = /^(ftp|http|https):\/\/(www\.)?[^\s/$.?#].[^\s]*$/;
    if (!value) {
      setschoolWebsiteError("School website is required");
      return false;
    } else if (!urlPattern.test(value)) {
      setschoolWebsiteError("Please enter a valid URL starting with http(s)");
      return false;
    }

    setschoolWebsiteError("");
    return true;
  };

  const validateVideoUrl = (value) => {
    const urlPattern = /^(ftp|http|https):\/\/(www\.)?[^\s/$.?#].[^\s]*$/;
    if (!value) {
      setvideoURLError("School premise's youtube video link is required");
      return false;
    }
    else if (!urlPattern.test(value)) {
      setvideoURLError("Please enter a valid URL starting with http(s)");
      return false;
    }
    setvideoURLError("");
    return true;
  };

  const validateChairmanMessage = (value) => {
    const urlPattern = /^(ftp|http|https):\/\/(www\.)?[^\s/$.?#].[^\s]*$/;
    if (!value) {
      setChairmanmMessageError(" Principal/Chairman's message Youtube Video Link required");
      return false;
    }
    else if (!urlPattern.test(value)) {
      setChairmanmMessageError("Please enter a valid URL starting with http(s)");
      return false;
    }
    setChairmanmMessageError("");
    return true;
  };


  const validateState = (value) => {
    if (!value

    ) {
      setstateError("State is required");
      return false;
    }
    setstateError("");
    return true;
  };

  const validateClTeacherStaff = (value) => {
    const trimmedValue = value.trim();

    if (!trimmedValue) {
      setClTeacherStaffError("CL per year is required");
      return false;
    }

    setClTeacherStaffError("");
    return true;
  };


  const validateClTeacherNonStaff = (value) => {
    if (!value.trim()) {
      setClTeacherNonStaffError("SL per year is required");
      return false;
    }
    setClTeacherNonStaffError("");
    return true;
  };



  const validateOriginalCertificates = (value) => {
    if (value === null || value === undefined || !value.trim()) {
      setOriginalCertificatesError("Original certificates field is required");
      return false;
    }
    setOriginalCertificatesError("");
    return true;
  };

  const validateOption = (value) => {
    if (value === null || value === undefined || !value.trim()) {
      setShowSalaryDuringVacationError(
        "Salary during vacation field is required"
      );
      return false;
    }
    setShowSalaryDuringVacationError("");
    return true;
  };

  const validateAgree = (value) => {
    if (value === null || value === undefined || !value.trim()) {
      setAgreementError("Agreement field is required");
      return false;
    }
    setAgreementError("");
    return true;
  };

  const handleAgreementChange = (selectedOption) => {
    setAgreement(selectedOption.value);
    setAgreementError("");
    setShowAgreementDuration(selectedOption.value === "Yes");
    if (selectedOption.value === "No") {
      setAgreementDuration("");
      setAgreementDurationError("");
    }
  };

  const handleInstitutionProfile = () => {
    const isNameValid = validatename(name);
    const isNameOfTrustValid = validateNameOfTrust(nameOfTrust);
    const isClassRunFromValid = validateClassRunFrom(classrunFrom);
    const isClassRunToValid = validateClassRunTo(classrunTo);
    const isFirstLanguageValid = validateFirstLanguage(firstLanguage);
    const isMediumValid = validateMedium(medium);

    const isEstablishmentYearValid =
      validateEstablishmentYear(establishmentYear);
    const isEmailValid = validateEmail(email);
    const isLandlineValid = validateLandline(landline);
    const isApplicationNumberValid =
      validateApplicationNumber(applicationNumber);
    const isUdiseNumberValid =
      validateUdisenumberNumber(udisenumber);
    const isPrincipalValid = validatePrincipalnumber(principalNumber);
    const isHRValid = validateHRnumber(hrNumber);
    const isCompleteAddressValid = validateCompleteAddress(completeAddress);
    const isCityValid = validateCity(city);
    const isPincodeValid = validatePincode(pincode);
    const isDistrictValid = validateDistrict(district);
    const isStateValid = validateState(state);

    // Check if all validations passed
    const isAllValidBasic =
      //Profile validations
      isNameValid &&
      isNameOfTrustValid &&
      isCityValid &&
      isPincodeValid &&
      isFirstLanguageValid &&
      isClassRunFromValid &&
      isClassRunToValid &&
      isMediumValid &&
      isEstablishmentYearValid &&
      isEmailValid &&
      isLandlineValid &&
      isApplicationNumberValid &&
      isUdiseNumberValid &&
      isCompleteAddressValid &&
      isDistrictValid &&
      isPrincipalValid &&
      isHRValid &&
      isStateValid;

    if (isAllValidBasic) {
      handleExpand();
      return true;
    } else {

      setSnackbarMessage(
        "Validation failed. Please check all the fields before submitting."
      );
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return false; // Validation failed
    }
  };

  const handleInstitutionProfileReset = () => {
    setName("");
    setnameError("");
    setNameOfTrust("");
    setNameOfTrustError("");
    setClassromFrom("");
    setclassromFromError("");
    setClassromTo("");
    setclassromToError("");
    setState("");
    setSelectedlanguages("");
    setFirstLanguageError("");
    setMedium('');
    setMediumError(" ");
    setAffiliationType('');
    setAffiliationTypeError("");
    setEstablishmentYear("");
    setEstablishmentYearError("");
    setEmail("");
    setEmailError("");
    setLandline("");
    setLandlineError("");
    setApplicationNumber("");
    setApplicationNumberError("");
    setPrincipalNumber("");
    setPrincipalNumberError("");
    setHrNumber("");
    setHrNumberError("");
    setAddress2("");
    setAddress2Error("");
    setCompleteAddress("");
    setCompleteAddressError("");
    setPincode("");
    setPincodeError("");
    setUdiseNumber("");
    setUdiseNumberError("")
    setCity("");
    setCityError("");
    setDistrict("");
    setDistrictError("");
    setstateError("");
  }

  const handleAdditionalDetails = () => {
    const isEmpty = classDetails.some(detail => (
      !detail.className || !detail.divisions || !detail.strength || !detail.fees
    ));

    if (isEmpty) {
      setIsFieldsEmpty(true);
      toast.warn('Please fill in all fields');
    } else if (!validateNumericValues()) {
      toast.warn('Please enter numeric values only');
    } else {
      handleExpandAdditionalDetails();
    }
  };

  const validateNumericValues = () => {
    for (const detail of classDetails) {
      if (isNaN(detail.divisions) || isNaN(detail.strength) || isNaN(detail.fees)) {
        return false;
      }
    }
    return true;
  };


  const handleAdditionalDetailsReset = () => {
    setClassDetails(initialClassDetails);
    setSelectedFacilities([]);
  }

  const handleNextUploadDocument = () => {
    // Upload Documents
    const isSchoolWebsiteValid = validateSchoolwebsite(schoolWebsite);
    const isVideoUrlValid = validateVideoUrl(videoURL);
    const isChairamanValid = validateChairmanMessage(ChairmanmMessage);

    // Check if all validations passed
    const isAllValidUploadDocument =
      !logoPreview || !photoPreview || !pdfLink
        ? false // Display an alert if any document is missing
        : isSchoolWebsiteValid && isVideoUrlValid && isChairamanValid;

    if (isAllValidUploadDocument) {
      handleExpandUploadDocuments();
      return true; // Validation passed
    } else {

      setSnackbarMessage(
        "Validation failed. Please check all the fields before submitting."
      );
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return false; // Validation failed
    }
  };

  const handleUploadDocumentReset = () => {
    setLogo(null);
    setPhoto(null);
    setPdf(null);
    setLogoPreview("");
    setPhotoPreview("");
    setPdfLink("");
    setSchoolWebsite("");
    setschoolWebsiteError("");
    setChairmanmMessage("");
    setChairmanmMessageError("");
    setvideoURLError("");
    setVideourl("");
    setUploadSuccessLogo(false);
    setUploadSuccess(false);
    setUploadSuccessPdf(false);
  }

  const handleEmploymentStrategies = () => {
    // Check if any field is empty
    const validate = salaryRanges.some(range => !range.salaryFrom || !range.salaryTo);
    if (validate) {
      // Display an error message if any field is empty
      toast.warning('Please fill in all fields');
      return;
    }
    const isQualificationSelected = salaryRanges.some((range, index) => !selectedQualifications[index]);
    if (isQualificationSelected) {
      toast.warning('Please Select Qualification');
      return;
    }
    // Validate if both "Salary From" and "Salary To" contain numeric values
    const isNumeric = salaryRanges.every(range => !isNaN(range.salaryFrom) && !isNaN(range.salaryTo));
    if (!isNumeric) {
      // Display an error message if any salary field is not numeric
      toast.warning('Please enter numeric values for salary range');
      return;
    }

    // Ensure that "Salary To" is greater than "Salary From"
    const isValidRange = salaryRanges.every(range => parseFloat(range.salaryTo) > parseFloat(range.salaryFrom));
    if (!isValidRange) {
      // Display an error message if any salary range is invalid
      toast.warning('Maximum Salary must be greater than Minimum Salary');
      return;
    }

    // If all validations pass, call handleExpandSalaryOffered
    handleExpandSalaryOffered();
  };

  const handleEmploymentStrategiesReset = () => {
    // setSelectedQualifications(new Array(salaryRanges.length).fill(""));
    setSalaryRanges([{ selectedQualification: "", salaryFrom: "", salaryTo: "" }]);
  }

  const handleNextJobSecurity = () => {
    const isOriginalCertificatesValid =
      validateOriginalCertificates(originalCertificates);
    const isShowSalaryDuringVacationValid = validateOption(
      showSalaryDuringVacation
    );
    const isNoticePeriodValid = noticePeriod ? validateNoticePeriod(noticePeriod) : true;
    const isAgreementValid = validateAgree(agreement);
    const isAgreementDurationValid = agreement === 'Yes' ? validateAgreementDuration(agreementDuration) : true;

    // Check if all validations passed
    const isAllValidJobSecurity =
      isOriginalCertificatesValid &&
      isShowSalaryDuringVacationValid &&
      isAgreementValid &&
      isAgreementDurationValid &&
      isNoticePeriodValid;


    if (isAllValidJobSecurity) {
      handleExpandJobSecurity();
      return true; // Validation passed
    } else {

      setSnackbarMessage(
        "Validation failed. Please check all the fields before submitting."
      );
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return false; // Validation failed
    }
  };

  const handleJobSecurityReset = () => {
    setEmployeeIdPE("");
    setEmployeeIdESI("");
    setClTeacherStaff("");
    setClTeacherNonStaff("");
    setNoticePeriod("");
    setOriginalCertificates("");
    setShowSalaryDuringVacation("");
    setAgreementDuration("");
    setAgreement("");
    setClTeacherStaffError("");
    setClTeacherNonStaffError("");
    setNoticePeriodError("");
    setOriginalCertificatesError("");
    setShowSalaryDuringVacationError("");
    setAgreementError("");
    setAgreementDurationError("");
  }


  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleExpandPlan = () => {
    setExpandedPlan(!expandedPlan);
  };
  const salaryDuringVacationOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ];
  const handleExpandUploadDocuments = () => {
    setExpandedUploadDocuments(!expandedUploadDocuments);
  };

  const handleExpandSalaryOffered = () => {
    setExpandedSalaryOffered(!expandedSalaryOffered);
  };

  const handleExpandJobSecurity = () => {
    setExpandedJobSecurity(!expandedJobSecurity);
  };
  const handleLogoUpload = (files) => {
    const selectedFile = files[0];
    setLogo(selectedFile);
    const previewURL = URL.createObjectURL(selectedFile);
    setLogoPreview(previewURL);
    setUploadSuccessLogo(true);
  };

  const handlePhotoUpload = (files) => {
    const selectedFile = files[0];
    setPhoto(selectedFile);
    const previewURL = URL.createObjectURL(selectedFile);
    setPhotoPreview(previewURL);
    setUploadSuccess(true);
  };

  const handleApplicationCopyPDFUpload = (files) => {
    const uploadedPdf = files[0];

    // Set PDF link for text display
    setPdf(uploadedPdf);
    const pdfURL = URL.createObjectURL(uploadedPdf);
    setPdfLink(pdfURL);

    // Set upload success message for PDF
    setUploadSuccessPdf(true);

    // Your other logic for handling PDF upload...
  };


  const handleExpandAdditionalDetails = () => {
    setExpandedAdditionalDetails(!expandedAdditionalDetails);
  };

  const handleupload = async () => {
    if (!landline) {
      setSnackbarMessage("Please complete Institution Profile Before Proceeding");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }
    if (!logoPreview) {
      setSnackbarMessage("Please attach logo");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }

    if (!photoPreview) {
      setSnackbarMessage("Please attach front view");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }

    if (!pdfLink) {
      setSnackbarMessage("Please attach RR/Affiliation Copy");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }
    const documents = [
      { file: logo, preview: logoPreview, nm: 'School Logo' },
      { file: photo, preview: photoPreview, nm: 'School Building Front View' },
      { file: pdf, preview: pdfLink, nm: 'RR/Affiliation Copy' }
    ];

    for (const { file, preview, nm } of documents) {
      if (file != null && file !== 'null') {
        if (file.size > 2 * 1024 * 1024) {
          setSnackbarMessage(`${nm} size exceeds 2 MB. Please upload a smaller file.`);
          setSnackbarBgColor('red');
          setIsSuccess(false);
          setSnackbarOpen(true);
          continue;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
          const response = await axios.post(`${fileUrl}&path=${landline}&env=${env}&projectcode=05&filetype=${nm}`, formData);
          const data = response.data;
          if (data.cd === 0) {
            switch (nm) {
              case 'School Logo':
                setLogoPath(data.techdescription);
                break;
              case 'School Building Front View':
                setPhotoPath(data.techdescription);
                break;
              case 'RR/Affiliation Copy':
                setPdfPath(data.techdescription);
                break;
              default:
                break;
            }
            toast.success(`${nm} Uploaded successfully.`);
          } else {
            toast.error(`Failed to upload ${nm}. Description: ${data.description}`);
          }
        } catch (error) {
          toast.error(`Error uploading ${nm}: ${error.message}`);
        }
      }
    }
  };






  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await getDropdownData('LN');
        const mappedOptions = response.map((option) => ({
          value: option.code,
          label: option.value,
        }));
        setLangugeoptions(mappedOptions);
      } catch (error) {

      }
    };

    fetchLanguages();
  }, [getDropdownData]);


  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        const response = getDropdownData('FA');
        const mappedOptions = response.map(option => ({
          value: option.code,
          label: option.value,
        }));
        setFacilitiesOptions(mappedOptions);
      } catch (error) {

      }
    };

    fetchFacilities();
  }, [getDropdownData]);


  useEffect(() => {
    const fetchQulifications = async () => {
      try {
        const response = getDropdownData('QA');
        const mappedOptions = response.map(option => ({
          value: option.code,
          label: option.value,
        }));
        setqualificationOptions(mappedOptions);
      } catch (error) {

      }
    };

    fetchQulifications();
  }, [getDropdownData]);

  useEffect(() => {
    const fetchMediumOptions = async () => {
      try {
        const response = getDropdownData('MD');
        const mappedOptions = response.map(option => ({
          value: option.code,
          label: option.value,
        }));

        setMediumOptions(mappedOptions);
      } catch (error) {

      }
    };

    fetchMediumOptions();
  }, [getDropdownData]);

  useEffect(() => {
    const fetchClassesFromOption = async () => {
      try {
        const response = getDropdownData('CF');
        const mappedOptions = response.map(option => ({
          value: option.code,
          label: option.value,
        }));

        setClassesFromOption(mappedOptions);
      } catch (error) {

      }
    };

    fetchClassesFromOption();
  }, [getDropdownData]);

  useEffect(() => {
    const fetchClassesToOption = async () => {
      try {
        const response = getDropdownData('CT');
        const mappedOptions = response.map(option => ({
          value: option.code,
          label: option.value,
        }));

        setClassesToOption(mappedOptions);
      } catch (error) {

      }
    };

    fetchClassesToOption();
  }, [getDropdownData]);

  useEffect(() => {
    const fetchAffiliationTypeOptions = async () => {
      try {
        // const bearerToken = userData.accessToken;
        const response = getDropdownData('AF');
        const mappedOptions = response.map(option => ({
          value: option.code,
          label: option.value,
        }));
        setAffiliationTypeOptions(mappedOptions);
      } catch (error) {

      }
    };

    fetchAffiliationTypeOptions();
    fetchAnotherData();

  }, [getDropdownData]);

  const fetchAnotherData = async () => {
    try {
      const bearerToken = userData.accessToken;
      const response = await fetch(
        `${apiUrl}/createaccount/${userLoginCode.name}`,
        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
        })
      const data = await response.json();

      setinstituteid(data.institute.id);
      setInstituteicode(data.institute.code);
      setibasicicode(data.i_Basic.i_code)
      setName(data.institute.name);
      setibasicid(data.i_Basic.id);
      setPincode(data.i_Basic.pin);
      setNameOfTrust(data.i_Basic.trust);
      setMedium(data.i_Basic.medium);
      setEstablishmentYear(data.i_Basic.estYear);
      const fetchedLanguages = [
        { value: data.i_Basic.lang1, label: data.i_Basic.lang1Val },
        { value: data.i_Basic.lang2, label: data.i_Basic.lang2Val },
        { value: data.i_Basic.lang3, label: data.i_Basic.lang3Val },
      ];
      setSelectedlanguages(fetchedLanguages.map((lang) => lang.value));
      setFirstLanguage(data.i_Basic.lang1);
      setSelectedSecondLanguage(data.i_Basic.lang2);
      setThirdLanguage(data.i_Basic.lang3);
      setCompleteAddress(data.i_Basic.address1);
      setAddress2(data.i_Basic.address2);
      setCity(data.i_Basic.city);
      setPhoto(data.i_Basic.pin);
      setTaluka(data.i_Basic.taluka);
      setDistrict(data.i_Basic.dist);
      setState(data.i_Basic.state);
      setLandline(data.i_Basic.phone);
      setEmail(data.i_Basic.email);
      setPrincipalNumber(data.i_Basic.princiNo);
      setHrNumber(data.i_Basic.hrNo);
      setAffiliationType(data.i_Basic.afflType);
      setApplicationNumber(data.i_Basic.afflNo);
      setUdiseNumber(data.i_Basic.udiceno);
      setVideourl(data.i_Basic.introLink);
      setSchoolWebsite(data.i_Basic.website);
      setChairmanmMessage(data.i_Basic.chairmanmsg);
      setSelectedApplicationCopyPDF(data.i_Basic.rrCopy);
      setClassromFrom(data.i_Basic.classrunFrom);
      setClassromTo(data.i_Basic.classrunTo);
      setEmployeeIdPE(data.i_Basic.pfId);
      setEmployeeIdESI(data.i_Basic.esiId);
      setClTeacherStaff(data.i_Basic.casualLeaves);
      setClTeacherNonStaff(data.i_Basic.sickLeaves);
      setShowSalaryDuringVacation(data.i_Basic.vacationPay === 1 ? "Yes" : "No");
      setOriginalCertificates(data.i_Basic.originalCertificates === 1 ? "Yes" : "No");
      setNoticePeriod(data.i_Basic.noticePeriod);
      setAgreement(data.i_Basic.agreement === 1 ? "Yes" : "No");
      setAgreementDuration(data.i_Basic.agreementdur);

      const classDetailsList = Array.isArray(data.i_Class) ? data.i_Class.map((classDetail) => ({
        id: classDetail.id,
        className: classDetail.className,
        divisions: classDetail.division,
        strength: classDetail.strengthPerDivision,
        fees: classDetail.fees,
      })) : [];

      setClassDetails(classDetailsList);
      const facilities = Array.isArray(data.i_Facilities)
        ? data.i_Facilities.map((facility) => ({
          id: facility.id,
          type: facility.type,
          value: facility.value,
        }))
        : [];
      const selectedFacilities = facilities.map((facility) => facility.type);
      setSelectedFacilities(selectedFacilities);


      const logoImage = baseUrl + data.i_Basic.logo
      const PhotoImage = baseUrl + data.i_Basic.schoolPhoto;
      const pdfview = baseUrl + data.i_Basic.rrCopy;
      setLogoPath(data.i_Basic.logo);
      setLogoPreview(logoImage);
      setPhotoPath(data.i_Basic.schoolPhoto);
      setPhotoPreview(PhotoImage);
      setPdfLink(pdfview);
      // Assuming data.i_Pref_Basic is an array of preferences
      const preferenceList = Array.isArray(data.i_Pref_Basic) ? data.i_Pref_Basic.map((preference) => ({
        id: preference.id,
        selectedQualification: preference.course,
        salaryFrom: preference.salaryFrom,
        salaryTo: preference.salaryTo,
        qual: preference.qual ? preference.qual.split(',') : []
      })) : [];
      setSelectedQualifications(preferenceList.map(pref => pref.qual));
      setSalaryRanges(preferenceList);
      setiplanid(data.i_Plan.id);
      setiplanicode(data.i_Plan.icode);
      setipaymenticode(data.i_IPayment.icode);
      setipaymentid(data.i_IPayment.id);
      setpaymentdate(data.i_IPayment.date);
      // setselectedplanoption(data.i_Plan.plan);
      setSelectedPlan(data.i_Plan.plan);
      setStartDate(data.i_Plan.startDate);
      setEndDate(data.i_Plan.endDate);

      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.Exception === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.Exception === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }
    } catch (error) {

    }
  };



  const handleUpdate = async () => {

    const salaryvacation = showSalaryDuringVacation === "Yes" ? "1" : "0";
    const certificate = originalCertificates === "Yes" ? "1" : "0";
    const Agreement = agreement === "Yes" ? "1" : "0";


    const IBasic = {
      id: ibasicid,
      name: name,
      trust: nameOfTrust,
      medium: medium,
      estYear: establishmentYear,
      lang1: firstLanguage,
      lang2: selectedSecondLanguage,
      lang3: thirdLanguage,
      address1: completeAddress,
      address2: address2,
      city: city,
      pin: pincode,
      dist: district,
      state: state,
      phone: landline,
      email: email,
      princiNo: principalNumber,
      hrNo: hrNumber,
      afflType: affiliationType,
      afflNo: applicationNumber,
      udiceno: udisenumber,
      classrunFrom: classrunFrom,
      classrunTo: classrunTo,
      logo: logoPath,
      schoolPhoto: photoPath,
      introLink: videoURL,
      website: schoolWebsite,
      chairmanmsg: ChairmanmMessage,
      rrCopy: pdfPath,
      pfId: employeeIdPE,
      esiId: employeeIdESI,
      casualLeaves: clTeacherStaff,
      sickLeaves: clTeacherNonStaff,
      vacationPay: salaryvacation,
      noticePeriod: noticePeriod,
      originalCertificates: certificate,
      agreement: Agreement,
      agreementdur: agreementDuration
    };

    const IClass = classDetails.map((detail) => ({
      id: detail.id,
      className: detail.className,
      division: detail.divisions,
      strengthPerDivision: detail.strength,
      fees: detail.fees,
    }));
    const IFacility = selectedFacilities.map((facility) => ({
      id: facility.id,
      type: facility.type,
      value: facility.value
    }));

    const IPreferBasic = salaryRanges.map((range, index) => ({
      id: range.id,
      course: selectedQualifications[index][0],
      salaryFrom: range.salaryFrom,
      salaryTo: range.salaryTo,
      qual: selectedQualifications[index].join(','),
    }));

    const IPlan = {
      id: iplanid,
      plan: selectedPlan.code,
      startDate: startDate,
      endDate: endDate,
      status: 'Open'
    };

    const IPayment = {
      id: ipaymentid,
      payType: 'phone pay',
      amount: selectedPlan.price,
      comment: 'Payment Suessfull',
      paymentChannel: 'UPI',
      referenceNumber: utrNumber,
      date: paymentdate
    };


    const AccountCreation = {
      i_Basic: IBasic,
      i_Class: IClass,
      i_Facilities: IFacility,
      i_Pref_Basic: IPreferBasic,
      i_Plan: IPlan,
      i_IPayment: IPayment,
    };

    try {
      const bearerToken = userData.accessToken;
      const response = await axios.put(
        `${apiUrl}/createaccount/${userLoginCode.name}`,
        AccountCreation,
        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.cd !== 1) {
        setSnackbarMessage(response.data.description);
        setSnackbarBgColor('green');
        setIsSuccess(true);
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage(response.data.description);
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
      }

      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.Exception === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.Exception === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }

    } catch (error) {
      setSnackbarMessage("Error Updating Account..");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
    }
  };


  const handleYearChange = (e) => {
    const inputYear = e.target.value;
    const isValidInput = /^\d*$/.test(inputYear); // Allow empty string or digits only
    setIsValid(isValidInput);

    if (isValidInput) {
      setEstablishmentYear(inputYear);
      setEstablishmentYearError('');
    } else {
      setEstablishmentYearError('Please enter a valid year.');
    }
  };

  const handleNumericChange = (
    inputValue,
    setStateFunction,
    setErrorFunction
  ) => {
    const isValidInput = /^\d+$/.test(inputValue);
    if (isValidInput) {
      setStateFunction(inputValue);
      setErrorFunction("");
    } else {
      setErrorFunction("Enter only numeric values");
    }
  };

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  var colors = {
    gray: {
      100: "#f6f9fc",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#8898aa",
      700: "#525f7f",
      800: "#32325d",
      900: "#212529",
    },
    darkblue: {
      100: "#cfd8e6",
      200: "#a2adc6",
      300: "#7573a6",
      400: "#494a86",
      500: "#1c1e66",
      600: "#171a54",
      620: "#101451",
      650: "#0f1241",
      700: "#121440",
      750: "#0e1135",
      800: "#0d1030",
      900: "#080b1f",
    },
    navy: {
      100: "#e6eaf0",
      200: "#c0c9d5",
      300: "#9ba7ba",
      400: "#74859f",
      500: "#4e6384",
      600: "#3e4e6a",
      700: "#2e3950",
      800: "#1f2436",
      900: "#0f0f1d",
    },
    theme: {
      default: "#172b4d",
      primary: "#5e72e4",
      secondary: "#f4f5f7",
      info: "#11cdef",
      success: "#2dce89",
      danger: "#f5365c",
      warning: "#fb6340",
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
  };


  return (
    <>
      <ColorHeader />
      <Topbar />
      <Container className=" mt-5"></Container>
      <Container className=" mt-5">
        <Navbar
          expand="md"
          style={{ borderRadius: "10px", marginTop: "20px", backgroundColor: colors.darkblue[650] }}
          onClick={handleExpand}
        >
          {/* Navbar content */}
          <Container className="d-flex justify-content-between align-items-center">
            <NavbarBrand
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              <i
                className="fas fa-user-circle"
                style={{ marginRight: "1rem" }}
              ></i>
              Institution Profile
            </NavbarBrand>
            <Nav navbar>
              <NavItem>
                <Button
                  onClick={handleExpand}
                  style={{
                    backgroundColor: colors.darkblue[650],
                    borderRadius: "50%",
                    width: "35px",
                    padding: "0",      // Remove padding
                    margin: "0",
                    height: "35px",
                    display: "flex",            // Added flex display
                    justifyContent: "center",   // Center horizontally
                    alignItems: "center",
                  }}
                >
                  {expanded ? (
                    <FaMinus size={25} color="white" />
                  ) : (
                    <FaPlus size={25} color="white" />
                  )}
                </Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>

        {/* Content */}
        <Card
          className={`bg-secondary shadow border-0 ${expanded ? "expanded" : "collapsed"
            }`}
        >
          {expanded && (
            <CardBody className="px-lg-5 py-lg-5">
              {/* Form */}
              <Form role="form">
                <FormGroup className="mb-3">
                  <Label for="name" className="font-weight-bold">
                    Institution Name<span className="required-asterisk">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="name"
                    placeholder="Enter Institution Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setnameError("");
                    }}
                    style={{ color: "black", borderColor: nameError ? "red" : "" }}
                  />

                  {nameError && <div style={{ color: "red" }}>{nameError}</div>}
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label for="nameOfTrust" className="font-weight-bold">
                    Name of Trust or Society <span className="required-asterisk">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="nameOfTrust"
                    placeholder="Enter the name of Trust or Society"
                    value={nameOfTrust}
                    onChange={(e) => {
                      setNameOfTrust(e.target.value);
                      setNameOfTrustError("");
                    }}
                    style={{ color: "black", borderColor: nameOfTrustError ? "red" : "" }}
                  />
                  {nameOfTrustError && (
                    <div style={{ color: "red" }}>{nameOfTrustError}</div>
                  )}
                </FormGroup>

                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="medium" className="font-weight-bold">
                        Classes Run From <span className="required-asterisk">*</span>
                      </Label>
                      <Select
                        name="medium"
                        id="medium"
                        value={ClassesFromOption.find(option => option.value === classrunFrom)}
                        onChange={handleclassrunFromChange}
                        placeholder="Select class"
                        options={ClassesFromOption}
                        isClearable
                      />
                      {classroomFromError && (
                        <div style={{ color: "red" }}>{classroomFromError}</div>
                      )}
                    </FormGroup>
                  </Col>

                  <Col md={6}>

                    <FormGroup className="mb-3">
                      <Label for="affiliationType" className="font-weight-bold">
                        Classes Run To <span className="required-asterisk">*</span>
                      </Label>
                      <Select
                        name="affiliationType"
                        id="affiliationType"
                        value={ClassesToOption.find(option => option.value === classrunTo)}
                        onChange={handleclassrunToChange}
                        placeholder="Select Classes"
                        options={ClassesToOption}
                        isClearable


                      />

                      {classroomToError && (
                        <div style={{ color: "red" }}>{classroomToError}</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup className="mb-3">
                  <Label for="firstLanguage" className="font-weight-bold">
                    Select Languages Taught(Select Maximum 3 Languages) <span className="required-asterisk">*</span>
                  </Label>
                  <Select
                    isMulti
                    name="languages"
                    id="firstLanguage"
                    value={Langugeoptions.filter(option => selectedlanguages.includes(option.value))}
                    onChange={handleLanguagesChange}
                    placeholder="Select languages"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    maxMenuHeight={150}
                    isOptionDisabled={(option) => selectedlanguages.length >= 3 && !selectedlanguages.map(lang => lang.value).includes(option.lebel)}
                    options={Langugeoptions}
                  />
                  {firstLanguageError && (
                    <div style={{ color: "red" }}>{firstLanguageError}</div>
                  )}
                </FormGroup>


                <Row>
                  <Col md={6}>

                    <FormGroup className="mb-3">
                      <Label for="medium" className="font-weight-bold">
                        Medium<span className="required-asterisk">*</span>
                      </Label>
                      <Select
                        name="medium"
                        id="medium"
                        value={mediumOptions.find(option => option.value === medium)}
                        onChange={handleMediumChange}
                        placeholder="Select medium"
                        options={mediumOptions}
                      />
                      {mediumError && (
                        <div style={{ color: "red" }}>{mediumError}</div>
                      )}
                    </FormGroup>
                  </Col>

                  <Col md={6}>

                    <FormGroup className="mb-3">
                      <Label for="affiliationType" className="font-weight-bold">
                        Affiliation Type
                      </Label>
                      <Select
                        name="affiliationType"
                        id="affiliationType"
                        value={affiliationTypeOptions.find(option => option.value === affiliationType)}
                        onChange={handleAffiliationTypeChange}
                        placeholder="Select affiliationType"
                        options={affiliationTypeOptions}


                      />


                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup className="mb-3">
                  <Row>
                    <Col md={6}>
                      <Label
                        for="establishmentYear"
                        className="font-weight-bold"
                      >
                        Year of Establishment <span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="establishmentYear"
                        placeholder="Enter the year of establishment"
                        value={establishmentYear}
                        onChange={handleYearChange}
                        style={{
                          color: "black",
                          borderColor: establishmentYearError ? "red" : "",
                        }}
                      />
                      {establishmentYearError && (
                        <div style={{ color: "red" }}>
                          {establishmentYearError}
                        </div>
                      )}
                    </Col>
                    <Col md={6}>
                      <Label for="email" className="font-weight-bold">
                        E-Mail <span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value.toLowerCase());
                          setEmailError("");
                        }}
                        style={{ color: "black", borderColor: emailError ? "red" : "" }}
                      />
                      {emailError && (
                        <div style={{ color: "red" }}>{emailError}</div>
                      )}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label for="landline" className="font-weight-bold">
                    Phone Number <span className="required-asterisk">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="landline"
                    placeholder="Enter your Phone number"
                    value={landline}
                    onChange={(e) =>
                      handleNumericChange(
                        e.target.value,
                        setLandline,
                        setLandlineError
                      )
                    }
                    style={{ color: "black", borderColor: landlineError ? "red" : "" }}
                  />
                  {landlineError && (
                    <div style={{ color: "red" }}>{landlineError}</div>
                  )}
                </FormGroup>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="udisenumber" className="font-weight-bold">
                        U-DISE Number
                        <i
                          className="fa fa-question-circle"
                          aria-hidden="true"
                          onClick={() => {
                            setSnackbarMessage("Format of U-DISE Number: [01-001-12345]");
                            setSnackbarBgColor('green');
                            setIsSuccess(true);
                            setSnackbarOpen(true);
                          }}
                          style={{ color: 'blue', cursor: 'pointer', marginLeft: '10px' }}
                        ></i>
                      </Label>
                      <div style={{ display: 'flex' }}>
                        <Input
                          type="text"
                          id="udisenumber"
                          placeholder="Enter  U-DISE Number"
                          value={udisenumber}
                          onChange={(e) => {
                            setUdiseNumber(e.target.value);
                            setUdiseNumberError("");
                          }}
                          style={{
                            color: "black",
                            borderColor: udisenumberNumberError ? "red" : "",
                            marginRight: '10px', // Adjust spacing between input field and button
                          }}
                        />


                      </div>
                      {udisenumberNumberError && (
                        <div style={{ color: "red" }}>
                          {udisenumberNumberError}
                        </div>
                      )}

                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label
                        for="applicationNumber"
                        className="font-weight-bold"
                      >
                        Affiliation Number<span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="applicationNumber"
                        placeholder="Enter  affiliation number"
                        value={applicationNumber}
                        onChange={(e) => {
                          setApplicationNumber(e.target.value);
                          setApplicationNumberError("");
                        }}
                        style={{
                          color: "black",
                          borderColor: applicationNumberError ? "red" : "",
                        }}
                      />
                      {applicationNumberError && (
                        <div style={{ color: "red" }}>
                          {applicationNumberError}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="principal" className="font-weight-bold">
                        Principal Phone Number<span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="principal"
                        placeholder="Enter Principal Phone Number"
                        value={principalNumber}
                        onChange={handlePrincipalChange}
                        style={{
                          color: "black",
                          borderColor: principalNumberError ? "red" : "",
                        }}
                      />
                      {principalNumberError && (
                        <div style={{ color: "red" }}>
                          {principalNumberError}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="hrnumber" className="font-weight-bold">
                        HR Phone Number<span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="Numaric"
                        id="hrnumber"
                        placeholder="Enter HR Phone Number"
                        value={hrNumber}
                        onChange={handleHrNumberChange}
                        style={{ color: "black", borderColor: hrNumberError ? "red" : "" }}
                      />
                      {hrNumberError && (
                        <div style={{ color: "red" }}>{hrNumberError}</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup className="mb-3">
                  <Label for="completeAddress" className="font-weight-bold">
                    Address1<span className="required-asterisk">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="completeAddress"
                    placeholder="Enter the complete address"
                    value={completeAddress}
                    onChange={(e) => {
                      setCompleteAddress(e.target.value);
                      setCompleteAddressError("");
                    }}
                    style={{ color: "black", borderColor: completeAddressError ? "red" : "" }}
                  />
                  {completeAddressError && (
                    <div style={{ color: "red" }}>{completeAddressError}</div>
                  )}
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label for="completeAddress" className="font-weight-bold">
                    Address2
                  </Label>
                  <Input
                    type="text"
                    id="completeAddress"
                    placeholder="Enter the complete address"
                    value={address2}
                    onChange={(e) => {
                      setAddress2(e.target.value);
                      setAddress2Error("");
                    }}
                    style={{ color: "black", borderColor: address2Error ? "red" : "" }}
                  />
                  {address2Error && (
                    <div style={{ color: "red" }}>{address2Error}</div>
                  )}
                </FormGroup>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="pincode" className="font-weight-bold">
                        Pin Code<span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="pincode"
                        placeholder="Enter your pin code"
                        value={pincode}
                        onChange={handlePincodeChange}
                        style={{ color: "black", borderColor: pincodeError ? "red" : "" }}
                      />
                      {pincodeError && (
                        <div style={{ color: "red" }}>{pincodeError}</div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="city" className="font-weight-bold">
                        City<span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="city"
                        value={city}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="district" className="font-weight-bold">
                        District<span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="district"
                        value={district}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="state" className="font-weight-bold">
                        State<span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="state"
                        value={state}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="text-center">
                  <Button
                    className="my-4 font-weight-bold"
                    color="success"
                    type="button"
                    style={{ fontSize: "1.2rem" }}
                    onClick={() => {
                      handleInstitutionProfile();
                    }}
                  >
                    Continue
                  </Button>
                  <Button
                    className="my-4 ml-2 font-weight-bold"
                    color="danger"
                    type="button"
                    style={{ fontSize: "1.2rem" }}
                    onClick={handleInstitutionProfileReset}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            </CardBody>
          )}
        </Card>
        <Navbar
          expand="md"
          style={{ marginTop: "20px", borderRadius: "10px", backgroundColor: colors.darkblue[650] }}
          onClick={handleExpandAdditionalDetails}
        >
          <Container className="d-flex justify-content-between align-items-center">
            <NavbarBrand
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              <i
                className="fas fa-info-circle"
                style={{ marginRight: "1rem" }}
              ></i>
              Additional Details
            </NavbarBrand>
            <Nav navbar>
              <NavItem>
                <Button
                  onClick={handleExpandAdditionalDetails}
                  style={{
                    backgroundColor: colors.darkblue[650],
                    borderRadius: "50%",
                    width: "35px",
                    padding: "0",      // Remove padding
                    margin: "0",
                    height: "35px",
                    display: "flex",            // Added flex display
                    justifyContent: "center",   // Center horizontally
                    alignItems: "center",
                  }}
                >
                  {expandedAdditionalDetails ? (
                    <FaMinus size={25} color="white" />
                  ) : (
                    <FaPlus size={25} color="white" />
                  )}
                </Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>

        {/* Content for "Additional Details" */}
        <Card
          className={`bg-secondary shadow border-0 ${expandedAdditionalDetails ? "expanded" : "collapsed"
            }`}
        >
          {expandedAdditionalDetails && (
            <CardBody className="px-lg-5 py-lg-5">
              {/* Additional Details Form */}
              <Form role="form">
                <Card>
                  <FormGroup className="mb-3">
                    <Table striped bordered hover className="mt-3">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Class <span className="required-asterisk">*</span></b>
                          </TableCell>
                          <TableCell>
                            <b>No of Division <span className="required-asterisk">*</span></b>
                          </TableCell>
                          <TableCell>
                            <b>Total Strength <span className="required-asterisk">*</span></b>
                          </TableCell>
                          <TableCell>
                            <b>Tuition Fees (₹) <span className="required-asterisk">*</span></b>
                          </TableCell>
                          <TableCell style={{ width: "23%" }}>
                            <b>Actions</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {classDetails.map((detail, index) => (
                          <TableRow key={index} >
                            <TableCell>
                              <Input
                                type="text"
                                placeholder="Enter Class Name"
                                value={detail.className}
                                onChange={(e) =>
                                  setClassDetails((prevClassDetails) =>
                                    updateClassDetail(
                                      prevClassDetails,
                                      index,
                                      "className",
                                      e.target.value
                                    )
                                  )
                                }
                                style={{ color: "black", }}
                              />
                            </TableCell>
                            <TableCell>
                              <Input
                                type="text"
                                placeholder="Enter Divisions"
                                value={detail.divisions}
                                onChange={(e) =>
                                  setClassDetails((prevClassDetails) =>
                                    updateClassDetail(
                                      prevClassDetails,
                                      index,
                                      "divisions",
                                      e.target.value
                                    )
                                  )
                                }
                                style={{ color: "black", }}
                              />
                            </TableCell>
                            <TableCell>
                              <Input
                                type="text"
                                placeholder="Enter Strength"
                                value={detail.strength}
                                onChange={(e) =>
                                  setClassDetails((prevClassDetails) =>
                                    updateClassDetail(
                                      prevClassDetails,
                                      index,
                                      "strength",
                                      e.target.value
                                    )
                                  )
                                }
                                style={{ color: "black", }}
                              />
                            </TableCell>
                            <TableCell>
                              <Input
                                type="text"
                                placeholder="Enter Fees"
                                value={detail.fees}
                                onChange={(e) =>
                                  setClassDetails((prevClassDetails) =>
                                    updateClassDetail(
                                      prevClassDetails,
                                      index,
                                      "fees",
                                      e.target.value
                                    )
                                  )
                                }
                                style={{ color: "black", }}
                              />
                            </TableCell>
                            <TableCell>
                              {index > 0 && (
                                <Button
                                  onClick={() => handleRemoveClassRow(index)}
                                  color="danger"
                                  aria-label="remove row"
                                >
                                  Remove
                                </Button>
                              )}
                              <Button
                                onClick={() => handleAddClassRow()}
                                color="primary"
                                aria-label="add row"
                              >
                                Add
                              </Button>

                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </FormGroup>
                </Card>

                {/* Confirmation Dialog */}
                <Dialog
                  open={isConfirmationDialogOpen}
                  onClose={handleCancelRemove}
                >
                  <DialogTitle style={{ color: 'black', fontWeight: 'bold' }}>Confirm Removal</DialogTitle>

                  <DialogContent>
                    Are you sure you want to remove this row?
                  </DialogContent>
                  <DialogActions>
                    <button
                      tabIndex="0"
                      className="cancel-remove-styled-button"
                      onClick={handleCancelRemove}
                    >
                      Cancel
                    </button>

                    <button
                      tabIndex="1"
                      className="cancel-remove-styled-button"
                      onClick={handleConfirmRemove}
                    >
                      Remove
                    </button>
                  </DialogActions>
                </Dialog>

                <Card>
                  <CardBody className="px-lg-5 py-lg-5">
                    <FormGroup className="mb-3">
                      <Label for="facilities" className="font-weight-bold">
                        Facilities
                      </Label>
                      <Select
                        isMulti
                        name="facilities"
                        id="facilities"
                        value={facilitiesOptions.filter(option => selectedFacilities.includes(option.value))}
                        onChange={handleFacilitiesChange}
                        options={facilitiesOptions}
                        placeholder="Select Facilities"
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                      />
                    </FormGroup>
                  </CardBody>
                </Card>

                <div className="text-center">
                  <Button
                    className="my-4"
                    color="success"
                    type="button"
                    style={{ fontSize: "1.2rem" }}
                    onClick={() => {
                      handleAdditionalDetails();
                    }}
                  >
                    Continue
                  </Button>
                  <Button
                    className="my-4 ml-2 font-weight-bold"
                    color="danger"
                    type="button"
                    style={{ fontSize: "1.2rem" }}
                    onClick={handleAdditionalDetailsReset}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            </CardBody>
          )}
        </Card>
        <Navbar
          expand="md"
          style={{ marginTop: "20px", borderRadius: "10px", backgroundColor: colors.darkblue[650] }}
          onClick={handleExpandUploadDocuments}
        >
          <Container className="d-flex justify-content-between align-items-center">
            <NavbarBrand
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              <i
                className="fas fa-file-upload"
                style={{ marginRight: "1rem" }}
              ></i>
              Upload Documents
            </NavbarBrand>
            <Nav navbar>
              <NavItem>
                <Button
                  onClick={handleExpandUploadDocuments}
                  style={{
                    backgroundColor: colors.darkblue[650],
                    borderRadius: "50%",
                    width: "35px",
                    padding: "0",      // Remove padding
                    margin: "0",
                    height: "35px",
                    display: "flex",            // Added flex display
                    justifyContent: "center",   // Center horizontally
                    alignItems: "center",
                  }}
                >
                  {expandedUploadDocuments ? (
                    <FaMinus size={25} color="white" />
                  ) : (
                    <FaPlus size={25} color="white" />
                  )}
                </Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>

        {/* Content for "Upload Documents" */}
        <Card
          className={`bg-secondary shadow border-0 ${expandedUploadDocuments ? "expanded" : "collapsed"
            }`}
        >
          {expandedUploadDocuments && (
            <CardBody className="px-lg-5 py-lg-5">
              {/* Upload Documents Form */}
              <Form role="form">
                <Row>
                  <Col md={6}>
                    {/* Logo Upload */}
                    <FormGroup className="mb-3 border rounded p-3 bg-light">
                      <Label for="logoUpload" className="font-weight-bold">
                        Upload Logo (PNG and JPG)<span className="required-asterisk">*</span>
                        <div className="custom-file">
                          <Input
                            type="file"
                            className="custom-file-input"
                            id="logoUpload"
                            accept=".png, .jpg, .jpeg"
                            onChange={(e) => handleLogoUpload(e.target.files)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="logoUpload"
                          >
                            <FaUpload className="mr-2" /> Choose file
                          </label>
                        </div>
                      </Label>
                    </FormGroup>
                    {logoPreview && (
                      <div className="mt-3 p-3 border rounded bg-light">
                        <p className="font-weight-bold">
                          Uploaded Logo Preview:
                        </p>
                        <div
                          style={{
                            width: "150px",
                            height: "150px",
                            overflow: "hidden",
                            border: "1px solid #ddd",
                          }}
                        >
                          <img
                            src={logoPreview}
                            alt="Logo Preview"
                            style={{ width: "100%", height: "auto" }}
                          />
                        </div>
                      </div>
                    )}


                    {/* Photo Upload */}
                    <FormGroup className="mb-3 border rounded p-3 bg-light">
                      <Label for="photoUpload" className="font-weight-bold">
                        School Building Front View<span className="required-asterisk">*</span>
                        <div className="custom-file">
                          <Input
                            type="file"
                            className="custom-file-input"
                            id="photoUpload"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handlePhotoUpload(e.target.files)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="photoUpload"
                          >
                            <FaUpload className="mr-2" /> Choose file
                          </label>
                        </div>
                      </Label>
                    </FormGroup>
                    {photoPreview && (
                      <div className="mt-3 p-3 border rounded bg-light">
                        <p className="font-weight-bold">
                          Uploaded Photo Preview:
                        </p>
                        <div
                          style={{
                            width: "150px",
                            height: "150px",
                            overflow: "hidden",
                            border: "1px solid #ddd",
                          }}
                        >
                          <img
                            src={photoPreview}
                            alt="Photo Preview"
                            style={{ width: "100%", height: "auto" }}
                          />
                        </div>
                      </div>
                    )}

                  </Col>

                  <Col md={6}>
                    {/* Application Copy PDF Upload */}
                    <FormGroup className="mb-3 border rounded p-3 bg-light">
                      <Label
                        for="applicationCopyPDF"
                        className="font-weight-bold"
                      >
                        Attach RR/Affiliation Copy (PDF)<span className="required-asterisk">*</span>
                      </Label>
                      <div className="custom-file">
                        <Input
                          type="file"
                          id="applicationCopyPDF"
                          accept=".pdf"
                          onChange={(e) =>
                            handleApplicationCopyPDFUpload(e.target.files)
                          }
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="applicationCopyPDF"
                        >
                          <FaUpload className="mr-2" /> Choose file
                        </label>
                      </div>
                    </FormGroup>
                    {pdfLink && (
                      <div className="mt-3 p-3 border rounded bg-light">
                        <p className="font-weight-bold">PDF Link:</p>
                        <a
                          href={pdfLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "blue", // Change to your desired link color
                            textDecoration: "underline", // Add underline to the link
                          }}
                        >
                          {pdfLink}
                        </a>
                      </div>
                    )}


                  </Col>
                </Row>

                {/* School Website */}
                <FormGroup className="mb-3">
                  <Label for="schoolWebsite" className="font-weight-bold">
                    School Website<span className="required-asterisk">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="schoolWebsite"
                    placeholder="Enter School Website"
                    value={schoolWebsite}
                    onChange={(e) => {
                      setSchoolWebsite(e.target.value);
                      setschoolWebsiteError("");
                    }}
                    style={{ color: "black", borderColor: schoolWebsiteError ? "red" : "" }}
                  />
                  {schoolWebsiteError && (
                    <div style={{ color: "red" }}>{schoolWebsiteError}</div>
                  )}
                </FormGroup>

                {/* School Website */}
                <FormGroup className="mb-3">
                  <Label for="schoolWebsite" className="font-weight-bold">
                    School Premise's Youtube Video Link<span className="required-asterisk">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="schoolWebsite"
                    placeholder="Enter School Premise's Youtube Video Link"
                    value={videoURL}
                    onChange={(e) => {
                      setVideourl(e.target.value);
                      setvideoURLError("");
                    }}
                    style={{ color: "black", borderColor: videoURLError ? "red" : "" }}
                  />

                  {videoURLError && (
                    <div style={{ color: "red" }}>{videoURLError}</div>
                  )}
                </FormGroup>

                {/* School Website */}
                <FormGroup className="mb-3">
                  <Label for="youtubeVideo" className="font-weight-bold">
                    Principal/Chairman's Message - Youtube Video Link<span className="required-asterisk">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="youtubeVideo"
                    placeholder="Enter Principal/Chairman's Message - Youtube Video Link"
                    value={ChairmanmMessage}
                    onChange={(e) => {
                      setChairmanmMessage(e.target.value);
                      setChairmanmMessageError("");
                    }}
                    style={{ color: "black", borderColor: ChairmanmMessageError ? "red" : "" }}
                  />
                  {ChairmanmMessageError && (
                    <div style={{ color: "red" }}>{ChairmanmMessageError}</div>
                  )}
                </FormGroup>


                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    onClick={handleupload}
                  >
                    <FaUpload className="mr-2" /> Upload
                  </Button>
                  <Button
                    className="my-4"
                    color="success"
                    type="button"
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    onClick={() => {
                      handleNextUploadDocument();
                    }}
                  >
                    Continue
                  </Button>
                  <Button
                    className="my-4 mx-2"
                    color="danger"
                    type="button"
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    onClick={handleUploadDocumentReset}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            </CardBody>
          )}
        </Card>

        <Navbar
          expand="md"
          style={{ marginTop: "20px", borderRadius: "10px", backgroundColor: colors.darkblue[650] }}
          onClick={handleExpandSalaryOffered}
        >
          <Container className="d-flex justify-content-between align-items-center">
            <NavbarBrand
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              <i
                className="fas fa-briefcase"
                style={{ marginRight: "1rem" }}
              ></i>
              Employment Strategies
            </NavbarBrand>
            <Nav navbar>
              <NavItem>
                <Button
                  onClick={handleExpandSalaryOffered}
                  style={{
                    backgroundColor: colors.darkblue[650],
                    borderRadius: "50%",
                    width: "35px",
                    padding: "0",      // Remove padding
                    margin: "0",
                    height: "35px",
                    display: "flex",            // Added flex display
                    justifyContent: "center",   // Center horizontally
                    alignItems: "center",
                  }}
                >
                  {expandedSalaryOffered ? (
                    <FaMinus size={25} color="white" />
                  ) : (
                    <FaPlus size={25} color="white" />
                  )}
                </Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>

        {/* Content for "Salary Offered" */}
        <Card
          className={`bg-secondary shadow border-0 ${expandedSalaryOffered ? "expanded" : "collapsed"
            }`}
        >
          {expandedSalaryOffered && (
            <CardBody className="px-lg-5 py-lg-5">
              {/* Salary Offered Form */}
              <Form role="form">

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Qualification <span className="required-asterisk">*</span></b>
                      </TableCell>
                      <TableCell>
                        <b>Minimum Salary (Per Month) <span className="required-asterisk">*</span></b>
                      </TableCell>
                      <TableCell>
                        <b>Maximum Salary (Per Month) <span className="required-asterisk">*</span></b>
                      </TableCell>
                      <TableCell>
                        <b>Actions</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {salaryRanges.map((range, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Select
                            name={`qualification_${index}`}
                            id={`qualification_${index}`}
                            value={
                              selectedQualifications[index]
                                ? selectedQualifications[index].map((qual) =>
                                  qualificationOptions.find((option) => option.value === qual)
                                )
                                : []
                            }
                            onChange={(selectedOptions) =>
                              handleQualificationChange(index, selectedOptions.map((opt) => opt.value))
                            }
                            placeholder="Select Qualification"
                            options={qualificationOptions}
                            isMulti
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                minHeight: "42px",
                                fontSize: "16px",
                              }),
                              indicatorSeparator: (provided, state) => ({
                                ...provided,
                                display: "none",
                              }),
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Input
                            value={range.salaryFrom || ""}
                            onChange={(e) => handleSalaryChange(index, "salaryFrom", e.target.value)}
                            style={{ color: "black" }}
                            placeholder="Enter minimum salary"
                          />
                        </TableCell>
                        <TableCell>
                          <Input
                            value={range.salaryTo || ""}
                            onChange={(e) => handleSalaryChange(index, "salaryTo", e.target.value)}
                            style={{ color: "black" }}
                            placeholder="Enter maximum salary"
                          />
                        </TableCell>
                        <TableCell>
                          {index === salaryRanges.length - 1 && (
                            <FaPlus
                              size={25}
                              color="green"
                              onClick={() => handleAddRow()}
                              style={{ marginRight: '10px' }}
                            />
                          )}
                          {index !== 0 && (
                            <FaMinus
                              size={25}
                              color="red"
                              onClick={() => handleRemoveRow(index)}
                              style={{ marginLeft: '10px' }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>


                <div className="text-center">
                  <Button
                    className="my-4"
                    color="success"
                    type="button"
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    onClick={() => {
                      handleEmploymentStrategies();
                    }}
                  >
                    Continue
                  </Button>
                  <Button
                    className="my-4 mx-2"
                    color="danger"
                    type="button"
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    onClick={handleEmploymentStrategiesReset}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            </CardBody>
          )}
        </Card>
        <Dialog
          open={isEmployeeConfirmationDialogOpen}
          onClose={handleCancelRemove}
        >
          <DialogTitle style={{ color: 'black', fontWeight: 'bold' }}>Confirm Removal</DialogTitle>
          <DialogContent>
            Are you sure you want to remove this row?
          </DialogContent>
          <DialogActions>
            <button
              tabIndex="0"
              className="cancel-remove-styled-button"
              onClick={handleCancelEmployeeRemove}
            >
              Cancel
            </button>

            <button
              tabIndex="1"
              className="cancel-remove-styled-button"
              onClick={handleConfirmEmployeeRemove}
            >
              Remove
            </button>
          </DialogActions>
        </Dialog>
        <Navbar
          expand="md"
          style={{ marginTop: "20px", borderRadius: "10px", backgroundColor: colors.darkblue[650] }}
          onClick={handleExpandJobSecurity}
        >
          <Container className="d-flex justify-content-between align-items-center">
            <NavbarBrand
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              <i className="fas fa-lock" style={{ marginRight: "1rem" }}></i>
              Job Security
            </NavbarBrand>
            <Nav navbar>
              <NavItem>
                <Button
                  onClick={handleExpandJobSecurity}
                  style={{
                    backgroundColor: colors.darkblue[650],
                    borderRadius: "50%",
                    width: "35px",
                    padding: "0",      // Remove padding
                    margin: "0",
                    height: "35px",
                    display: "flex",            // Added flex display
                    justifyContent: "center",   // Center horizontally
                    alignItems: "center",
                  }}
                >
                  {expandedJobSecurity ? (
                    <FaMinus size={25} color="white" />
                  ) : (
                    <FaPlus size={25} color="white" />
                  )}
                </Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>

        {/* Content for "Job Security" */}
        <Card
          className={`bg-secondary shadow border-0 ${expandedJobSecurity ? "expanded" : "collapsed"
            }`}
        >
          {expandedJobSecurity && (
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                {/* Row 1: Normal Textfields */}
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="employeeIdPE" className="font-weight-bold">
                        PF Number
                      </Label>
                      <Input
                        type="text"
                        id="employeeIdPE"
                        placeholder="Enter PF number"
                        value={employeeIdPE}
                        onChange={(e) => {
                          setEmployeeIdPE(e.target.value);

                        }}
                        style={{ color: "black", }}

                      />

                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="employeeIdESI" className="font-weight-bold">
                        ESI Number
                      </Label>
                      <Input
                        type="text"
                        id="employeeIdESI"
                        placeholder="Enter ESI number"
                        value={employeeIdESI}
                        onChange={(e) => {
                          setEmployeeIdESI(e.target.value);
                          setEmployeeIdESIError("");
                        }}
                        style={{ color: "black", borderColor: employeeIdESIError ? "red" : "" }}
                      />
                      {employeeIdESIError && (
                        <div style={{ color: "red" }}>{employeeIdESIError}</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                {/* Row 2: Normal Textfields */}
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="clTeacherStaff" className="font-weight-bold">
                        Casual Leaves per year
                      </Label>
                      <Input
                        type="Number"
                        min="1"
                        id="clTeacherStaff"
                        placeholder="Enter the number of CL provided to teacher staff per year"
                        value={clTeacherStaff}
                        onChange={(e) => {
                          const inputValue = parseInt(e.target.value, 10);

                          if (
                            !isNaN(inputValue) &&
                            inputValue >= 0 &&
                            inputValue <= 365
                          ) {
                            setClTeacherStaff(inputValue);
                            setClTeacherStaffError("");
                          }
                        }}
                        style={{
                          color: "black",
                          borderColor: clTeacherStaffError ? "red" : "",
                        }}
                      />

                      {clTeacherStaffError && (
                        <div style={{ color: "red" }}>
                          {clTeacherStaffError}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label
                        for="clTeacherNonStaff"
                        className="font-weight-bold"
                      >
                        Sick Leaves per year
                      </Label>
                      <Input
                        type="Number"
                        min="0"
                        id="clTeacherNonStaff"
                        placeholder="Enter the number of SL provided to teacher staff per year"
                        value={clTeacherNonStaff}
                        onChange={(e) => {
                          const inputValue = parseInt(e.target.value, 10);

                          if (
                            !isNaN(inputValue) &&
                            inputValue >= 0 &&
                            inputValue <= 365
                          ) {
                            setClTeacherNonStaff(inputValue);
                            setClTeacherNonStaffError("");
                          }
                        }}
                        style={{
                          color: "black",
                          borderColor: clTeacherNonStaffError ? "red" : "",
                        }}
                      />

                      {clTeacherNonStaffError && (
                        <div style={{ color: "red" }}>
                          {clTeacherNonStaffError}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup className="mb-3">
                  <Label for="noticePeriod" className="font-weight-bold">
                    Notice Period in Months
                  </Label>
                  <Input
                    type="text"
                    id="noticePeriod"
                    placeholder="Enter notice period"
                    value={noticePeriod}
                    onChange={(e) => {
                      handleNoticePeriodChange(e);
                    }}
                    style={{ color: "black", borderColor: noticePeriodError ? "red" : "" }}
                  />
                  {noticePeriodError && (
                    <div style={{ color: "red" }}>{noticePeriodError}</div>
                  )}
                </FormGroup>

                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="originalCertificates" className="font-weight-bold">
                        Original Certificates To be Submitted?<span className="required-asterisk">*</span>
                      </Label>
                      <Select
                        name="originalCertificates"
                        id="originalCertificates"

                        value={salaryDuringVacationOptions.find(option => option.value === originalCertificates)}
                        onChange={handleOriginalCertificatesChange}
                        placeholder="Select Option"
                        options={salaryDuringVacationOptions}
                      />
                      {originalCertificatesError && (
                        <div style={{ color: "red" }}>{originalCertificatesError}</div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="showSalaryDuringVacation" className="font-weight-bold">
                        Salary Paid During Vacation?<span className="required-asterisk">*</span>
                      </Label>
                      <Select
                        name="showSalaryDuringVacation"
                        id="showSalaryDuringVacation"

                        value={salaryDuringVacationOptions.find(option => option.value === showSalaryDuringVacation)}
                        onChange={handleSalaryDuringVacationChange}
                        placeholder="Select Option"
                        options={salaryDuringVacationOptions}
                      />
                      {showSalaryDuringVacationError && (
                        <div style={{ color: "red" }}>{showSalaryDuringVacationError}</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup className="mb-3" style={{ display: "flex" }}>
                  <div style={{ flex: "1", marginRight: "10px" }}>
                    <Label for="agreement" className="font-weight-bold">
                      Agreement of Service?<span className="required-asterisk">*</span>
                    </Label>
                    <Select
                      name="agreement"
                      id="agreement"
                      value={salaryDuringVacationOptions.find(option => option.value === agreement)}
                      onChange={handleAgreementChange}
                      placeholder="Select Option"
                      options={salaryDuringVacationOptions}
                    />
                    {agreementError && (
                      <div style={{ color: "red" }}>{agreementError}</div>
                    )}
                  </div>

                  {agreement === "Yes" && (
                    <div style={{ flex: "1" }}>
                      <Label
                        for="agreementDuration"
                        className="font-weight-bold"
                      >
                        Agreement Duration in Months
                      </Label>
                      <Input
                        type="Number"
                        min="0"
                        id="agreementDuration"
                        placeholder="Enter Agreement Duration"
                        value={agreementDuration}
                        onChange={(e) => {
                          handleAgreementDurationChange(e);
                        }}
                        style={{ color: "black", borderColor: agreementDurationError ? "red" : "" }}
                      />
                      {agreementDurationError && (
                        <div style={{ color: "red" }}>
                          {agreementDurationError}
                        </div>
                      )}
                    </div>
                  )}
                </FormGroup>

                <div className="text-center">
                  <Button
                    className="my-4"
                    color="success"
                    type="button"
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    onClick={() => {
                      handleNextJobSecurity(6);
                    }}
                  >
                    Continue
                  </Button>
                  <Button
                    className="my-4 mx-2"
                    color="danger"
                    type="button"
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    onClick={handleJobSecurityReset}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            </CardBody>
          )}
        </Card>
        <Navbar
          expand="md"
          style={{ marginTop: "20px", borderRadius: "10px", backgroundColor: colors.darkblue[650] }}
          onClick={handleExpandPlan}
        >
          <Container className="d-flex justify-content-between align-items-center">
            <NavbarBrand
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              <i className="fas fa-cogs" style={{ marginRight: '1rem' }}></i>
              Upgrade the Plan
            </NavbarBrand>
            <Nav navbar>
              <NavItem>
                <Button
                  onClick={handleExpandPlan}
                  style={{
                    backgroundColor: colors.darkblue[650],
                    borderRadius: "50%",
                    width: "35px",
                    padding: "0",      // Remove padding
                    margin: "0",
                    height: "35px",
                    display: "flex",            // Added flex display
                    justifyContent: "center",   // Center horizontally
                    alignItems: "center",
                  }}
                >
                  {expandedPlan ? (
                    <FaMinus size={25} color="white" />
                  ) : (
                    <FaPlus size={25} color="white" />
                  )}
                </Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>

        {/* Content for "Plan" */}
        <Card
          className={`bg-secondary shadow border-0 ${expandedPlan ? "expanded" : "collapsed"
            }`}
        >
          {expandedPlan && (
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <div>
                  <Row>
                    {availablePlans.map((plan) => (
                      <Col key={plan.id} md="3">
                        <Card
                          style={{
                            height: "250px",
                            overflowY: "auto", // Enable vertical scrolling if content exceeds the fixed height
                            backgroundColor: (selectedPlan.code === plan.code || selectedPlan === plan.code) ? "green" : "orange",
                          }}
                          className={selectedPlan && (selectedPlan.code === plan.id || selectedPlan === plan.code) ? "selected" : ""}
                          onClick={() => {
                            // Only allow selecting plans with price of 0
                            if (plan.price === 0) {
                              handleSelectPlan(plan);
                            }
                          }}
                        >
                          <CardBody>
                            <h5 style={{ fontWeight: "bold" }}>{plan.name}</h5>
                            <p style={{ fontWeight: 'bold' }}>{plan.details}</p>
                            <p style={{ fontWeight: 'bold' }}>Amount: ₹{plan.price}</p>
                            {plan.price > 0 && (
                              <Button
                                style={{
                                  backgroundColor: colors.darkblue[650],
                                  color: "white",
                                }}
                                onClick={() => {
                                  handleSelectPlan(plan);
                                  handlePayment(plan.price);
                                }}
                                className="mt-3"
                              >
                                Perform Payment
                              </Button>
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>

                </div>



              </Form>
            </CardBody>
          )}
        </Card>
        <div className="text-center">
          <Button
            className="my-4"
            color="primary"
            type="button"
            style={{ fontSize: "1.2rem", fontWeight: "bold", backgroundColor: colors.darkblue[650] }}
            onClick={handleUpdate}
          >
            Update
          </Button>
        </div>
        <ToastContainer />
        <MySnackbar
          snackbarOpen={snackbarOpen}
          handleSnackbarClose={handleSnackbarClose}
          snackbarMessage={snackbarMessage}
          snackbarBgColor={snackbarBgColor}
          isSuccess={isSuccess}
        />
      </Container>
    </>
  );
};
export default Profile;
