// HelpPage.js

import React, {useEffect } from "react";
import {Row, Col, Card } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "views/examples/AuthContext";
import "./landing.css";
import Topbar from "../examples/TopBar"

const HelpPage = () => {
  const { userData } = useAuth();
  const { instbasic } = useAuth();
  const redIconStyle = { color: "red" };
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !userData || 
      userData === undefined || 
      userData === '' || 
      (typeof userData === 'object' && Object.keys(userData).length === 0)
    ) {
      navigate('/auth/login'); 
    }
  }, [userData, navigate]);

  return (
    <>
    
    <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh",margin:"5px" }}
      >
      <Topbar />
        <Row className="mt-10">
          <Col md={12}>
            <Card rounded>
              <Card.Body>
                <Row>
                  <Col md={1} className="text-right">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      size="2x"
                      style={redIconStyle}
                    />
                  </Col>
                  <Col md={11}>
                    <p>
                      <strong>
                        Address: Shikshak Bandhu, KAPPEC,
                        Werehouse No2, Amargol Hubli, Karnataka 580025{" "}
                      </strong>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={12}>
            <Card rounded>
              <Card.Body>
                <Row>
                  <Col md={1} className="text-right">
                    <FontAwesomeIcon
                      icon={faPhone}
                      size="2x"
                      style={redIconStyle}
                    />
                  </Col>
                  <Col md={11}>
                    <p>
                      <strong>Phone: 0836-2955727, +91-889988988 </strong>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={12}>
            <Card rounded>
              <Card.Body>
                <Row>
                  <Col md={1} className="text-right">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      size="2x"
                      style={redIconStyle}
                    />
                  </Col>
                  <Col md={11}>
                    <p>
                      <strong>Email:</strong>{" "}
                      <strong>
                        <a href="mailto:support@shikshakbandhu.com">
                          support@shikshakbandhu.com
                        </a>
                      </strong>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={12}>
            <Card rounded>
              <Card.Body>
                <Row>
                  <Col md={1} className="text-right">
                    <FontAwesomeIcon
                      icon={faGlobe}
                      size="2x"
                      style={redIconStyle}
                    />
                  </Col>
                  <Col md={11}>
                    <p>
                      <strong>Website:</strong>{" "}
                      <strong>
                        <a
                          href="https://shikshakbandhu.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                         https://shikshakbandhu.com/
                        </a>
                      </strong>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </div>
    </>
  );
};

export default HelpPage;
