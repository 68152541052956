import React, { useState, useEffect } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import axios from "axios";
import MySnackbar from 'views/examples/Snackbar'
import "./Prod.css";
import Select from "react-select";
import { FaUpload, FaCheck } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEyeSlash, faEye, faRedo } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { useApiUrl } from 'views/examples/ApiUrlContext';
import { useAuth } from 'views/examples/AuthContext';
import { useSharedvars } from 'views/examples/Sharedvars';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../examples/Constant";
import { ClipLoader } from 'react-spinners';
import { env, fileUrl, baseUrl } from '../examples/Constant';
import {
  StepContainer,
  StepWrapper,
  StepStyle,
  StepCount,
  StepsLabelContainer,
  StepLabel,
  stepsData,
} from "../examples/StepProgressBar";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  Navbar,
  NavbarBrand,
  Nav,
  Col,
  Row,
  NavItem,
  Container,
  Alert,
  FormFeedback,
} from "reactstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import useUtil from "views/examples/Util"
import ProfileCompletionProgressBar from "views/examples/ProfileCompletionProgressBar"
import PaymentHandler from './PaymentHandler';


const SignUp = () => {
  const [userId, setUserId] = useState('');
  const [userIdError, setUserIdError] = useState('');
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [phoneResendTimer, setPhoneResendTimer] = useState(180);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(180);
  const [phonevarifiedOnce, setPhonevarifiedOnce] = useState(false);
  const [isPhoneOtpVerified, setIsPhoneOtpVerified] = useState(false);
  const [captchaInput, setCaptchaInput] = useState('');
  const [captchaError, setCaptchaError] = useState('');
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState('INR');
  const [showPaymentHandler, setShowPaymentHandler] = useState(false);


  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarBgColor, setSnackbarBgColor] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);

  const [isPhoneOtpSent, setIsPhoneOtpSent] = useState(false);
  // const [isPhoneOtpVerified, setphoneIsOtpVerified] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [phoneOtpError, setPhoneOtpError] = useState('');
  const [Phoneotp, setPhoneOtp] = useState('');
  const [phoneVerificationOtp, setPhoneVerificationOtp] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const { getDropdownData } = useSharedvars();
  const { apiUrl } = useApiUrl();
  const { userData } = useAuth();
  const { userLoginCode } = useAuth();
  const { userLoginName } = useAuth();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [apiLiveKey, setApiLiveKey] = useState("");
  const [nameError, setnameError] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [token, setToken] = useState("");
  const [cityError, setCityError] = useState("");
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [firstLanguage, setFirstLanguage] = useState("");
  const [firstLanguageError, setFirstLanguageError] = useState("");
  const [classRun, setClassRun] = useState("");
  const [classRunError, setClassRunError] = useState("");
  const [selectedSecondLanguage, setSelectedSecondLanguage] = useState(null);
  const [selectedSecondLanguageError, setSelectedSecondLanguageError] =
    useState("");
  const [selectedlanguages, setSelectedlanguages] = useState([]);
  const [selectedmedium, setSelectedmedium] = useState('');
  const [mediumOptions, setMediumOptions] = useState([]);
  const [ClassesFromOption, setClassesFromOption] = useState([]);
  const [ClassesToOption, setClassesToOption] = useState([]);
  const [affiliationTypeOptions, setAffiliationTypeOptions] = useState([]);
  const [Langugeoptions, setLangugeoptions] = useState([]);
  const [thirdLanguage, setThirdLanguage] = useState("");
  const [thirdLanguageError, setThirdLanguageError] = useState("");
  const [nameOfTrust, setNameOfTrust] = useState("");
  const [nameOfTrustError, setNameOfTrustError] = useState("");
  const [medium, setMedium] = useState("");
  const [mediumError, setMediumError] = useState("");
  const [classrunFrom, setClassromFrom] = useState("");
  const [classroomFromError, setclassromFromError] = useState("");
  const [classrunTo, setClassromTo] = useState(null);
  const [classroomToError, setclassromToError] = useState("");
  const [affiliationType, setAffiliationType] = useState("");
  const [affiliationTypeError, setAffiliationTypeError] = useState("");
  const [establishmentYear, setEstablishmentYear] = useState("");
  const [establishmentYearError, setEstablishmentYearError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [landline, setLandline] = useState("");
  const [landlineError, setLandlineError] = useState("");
  const [applicationNumber, setApplicationNumber] = useState("");
  const [applicationNumberError, setApplicationNumberError] = useState("");
  const [udisenumber, setUdiseNumber] = useState("");
  const [udisenumberNumberError, setUdiseNumberError] = useState("");
  const [completeAddress, setCompleteAddress] = useState("");
  const [completeAddressError, setCompleteAddressError] = useState("");
  const [address2, setAddress2] = useState("");
  const [address2Error, setAddress2Error] = useState("");
  const [instituteNameError, setInstituteNameError] = useState("");
  const [district, setDistrict] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [principalNumber, setPrincipalNumber] = useState("");
  const [instutename, setInstituteName] = useState("");
  const [hrNumber, setHrNumber] = useState("");
  const [principalNumberError, setPrincipalNumberError] = useState("");
  const [hrNumberError, setHrNumberError] = useState("");
  const [stateError, setstateError] = useState("");
  const [showPostOfficeModal, setShowPostOfficeModal] = useState(false);
  const [postOffices, setPostOffices] = useState([]);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [hrNumberError, setHrNumberError] = useState("");

  //Additional details
  const initialClassDetails = [
    { className: "", divisions: "", strength: "", fees: "" },
  ];
  const [isFieldsEmpty, setIsFieldsEmpty] = useState(false);
  const [classDetails, setClassDetails] = useState(initialClassDetails);
  const [removeRowIndex, setRemoveRowIndex] = useState(null);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const [facilitiesOptions, setFacilitiesOptions] = useState([]);

  const [selectedFacilities, setSelectedFacilities] = useState([]);

  const [classDetailsError, setClassDetailsError] = useState("");
  const [facilitiesError, setFacilitiesError] = useState("");
  //Upload documents validation and Usestate
  const [schoolWebsite, setSchoolWebsite] = useState("");
  const [schoolWebsiteError, setschoolWebsiteError] = useState("");
  const [videoURL, setVideourl] = useState("");
  const [videoURLError, setvideoURLError] = useState("");

  const [ChairmanmMessage, setChairmanmMessage] = useState("");
  const [ChairmanmMessageError, setChairmanmMessageError] = useState("");

  const [logoPath, setLogoPath] = useState(null);
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState("");
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadSuccessLogo, setUploadSuccessLogo] = useState(false);
  const [pdfLink, setPdfLink] = useState("");
  const [uploadSuccessPdf, setUploadSuccessPdf] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [photoPath, setPhotoPath] = useState("");
  const [pdfPath, setPdfPath] = useState("");

  //Employment startgies
  const [qualificationOptions, setqualificationOptions] = useState([]);
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const initialsalaryRanges = [
    { course: "", salaryFrom: "", salaryTo: "" },
  ];
  const [salaryRanges, setSalaryRanges] = useState(initialsalaryRanges);
  const [selectedQualifications, setSelectedQualifications] = useState(
    new Array(salaryRanges.length).fill("")
  );
  const [removeEmployeeRowIndex, setRemoveEmployeeRowIndex] = useState(null);
  const [isEmployeeConfirmationDialogOpen, setEmployeeConfirmationDialogOpen] =
    useState(false);

  //JOb security
  const [employeeIdPE, setEmployeeIdPE] = useState("");
  const [employeeIdESI, setEmployeeIdESI] = useState("");
  const [clTeacherStaff, setClTeacherStaff] = useState("");
  const [clTeacherNonStaff, setClTeacherNonStaff] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");
  const [originalCertificates, setOriginalCertificates] = useState("");
  const [showSalaryDuringVacation, setShowSalaryDuringVacation] = useState("");
  const [agreement, setAgreement] = useState("");
  const [agreementDuration, setAgreementDuration] = useState("");
  const [showAgreementDuration, setShowAgreementDuration] = useState(false);


  const [employeeIdESIError, setEmployeeIdESIError] = useState("");
  const [clTeacherStaffError, setClTeacherStaffError] = useState("");
  const [clTeacherNonStaffError, setClTeacherNonStaffError] = useState("");
  const [noticePeriodError, setNoticePeriodError] = useState("");
  const [originalCertificatesError, setOriginalCertificatesError] =
    useState("");
  const [showSalaryDuringVacationError, setShowSalaryDuringVacationError] =
    useState("");
  const [agreementError, setAgreementError] = useState("");
  const [agreementDurationError, setAgreementDurationError] = useState("");

  //plan  States


  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");

  const [selectedPlan, setSelectedPlan] = useState("");
  const [availablePlans, setAvailablePlans] = useState([]);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [utrNumber, setUtrNumber] = useState('');


  //Expand Pages States
  const [expanded, setExpanded] = useState(false);
  const [expandedAdditionalDetails, setExpandedAdditionalDetails] =
    useState(false);
  const [expandedUploadDocuments, setExpandedUploadDocuments] = useState(false);
  const [expandedPlan, setExpandedPlan] = useState(false);
  const [expandedCreateAccount, setExpandedCreateAccount] = useState(false);
  const [expandedSalaryOffered, setExpandedSalaryOffered] = useState(false);
  const [expandedJobSecurity, setExpandedJobSecurity] = useState(false);

  //Profile completion statesF
  const [currentStep, setCurrentStep] = useState(0);
  const [isValid, setIsValid] = useState(true);
  const [instituteid, setinstituteid] = useState("");
  const [ibasicid, setibasicid] = useState("");
  const [profileCompletionPercentage, setprofileCompletionPercentage] =
    useState(userLoginCode.percomp !== null &&
      userLoginCode.percomp !== undefined &&
      userLoginCode.percomp !== '' ?
      userLoginCode.percomp :
      0);
  //success updation states
  const [createAccountsuccess, setcreateAccountsuccess] = useState(false);
  const [instituteProfileSuccess, setInstituteProfileSuccess] = useState(false);
  const [additionalDetailsSuccess, setAdditionalDetailsSuccess] = useState(false);
  const [uploadDocumentsSuccess, setUploadDocumentsSuccess] = useState(false);
  const [employmentStrategiesSuccess, setemploymentStrategiesSuccess] = useState(false);
  const [jobSecuritySuccess, setjobSecuritySuccess] = useState(false);
  const [subscriptionPlansSuccess, setsubscriptionPlansSuccess] = useState(false);

  const generateCaptcha = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let captcha = '';
    for (let i = 0; i < 6; i++) { // Length of captcha code
      captcha += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return captcha;
  };

  const [captchaCode, setCaptchaCode] = useState(generateCaptcha());

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handlePayment = async (amount) => {
    try {
      // First API call to get Razorpay config
      const configResponse = await axios.get(`${apiUrl}/plans/razorpay/config`);

      const data = configResponse.data;
      if (data.cd === 1) {
        setSnackbarMessage(data.description);
        setSnackbarBgColor('red');
        setSnackbarOpen(true);
        return;
      } else {
        setApiLiveKey(data.apiLiveKey); // Set the API live key
      }

      // Second API call to create an order
      const orderResponse = await axios.post(`${apiUrl}/plans/create-order`, {
        amount: amount, // Convert amount to paise
        currency: currency,
      });

      const options = {
        key: apiLiveKey, // Use the live key obtained from the first API call
        amount: orderResponse.data.amount,
        currency: orderResponse.data.currency,
        order_id: orderResponse.data.id,
        name: 'Shikshak Bandhu',
        description: 'Plan Subscription Fee',
        handler: function (response) {
          setUtrNumber(response.razorpay_payment_id);
          // Additional actions upon successful payment
        },
        prefill: {
          name: name,
          email: email,
          contact: landline,
        },
        notes: {
          address: completeAddress,
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();

      rzp1.on('payment.failed', function (response) {
        setSnackbarMessage(`Payment failed: ${response.error.description}`);
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
      });
    } catch (error) {
      setSnackbarMessage('Payment initiation failed');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const toggleConfirmPasswordVisibility = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };

  const handleBack = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleConfirmBack = () => {
    window.location.href = "/auth/login";
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (event) => {
    setAgreedToTerms(event.target.checked);
  };
  // Function to toggle the visibility of the modal
  const toggleModal = () => {
    setShowModal(!showModal);
  };


  const handlelanguagesChange = (selectedOptions) => {
    // Ensure maximum two selections
    if (selectedOptions.length <= 3) {
      //setSelectedlanguages(selectedOptions);
      const newlanguages = selectedOptions.map((option) => option.value);
      setSelectedlanguages(newlanguages);
      if (newlanguages.length > 3) {
        newlanguages.splice(3);
      }
      setFirstLanguage(newlanguages[0] || '');
      setSelectedSecondLanguage(newlanguages[1] || '');
      setThirdLanguage(newlanguages[2] || '');
      setFirstLanguageError('');
    }
  };

  const handleMediumChange = (selectedOption) => {
    if (selectedOption) {
      const selectedMediumValue = selectedOption.value;
      setMedium(selectedMediumValue);
      setMediumError('');
    } else {
      setMedium('');
    }
  };



  const handleclassrunToChange = (selectedOption) => {
    if (selectedOption) {
      setClassromTo(selectedOption.value);
      setclassromToError('');
    } else {
      setClassromTo(null);
      setclassromToError('');
    }
  };


  const handleclassrunFromChange = (selectedOption) => {
    if (selectedOption) {
      setClassromFrom(selectedOption.value);
      setclassromFromError('');
    } else {
      setClassromFrom(null);
      setclassromFromError('');
    }
  };




  const handleAffiliationTypeChange = (selectedOption) => {
    if (selectedOption) {
      const selectedAffiliationTypeValue = selectedOption.value;
      setAffiliationType(selectedAffiliationTypeValue);
      setAffiliationTypeError('');
    } else {
      setAffiliationType('');


    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };


  //Onchange Method States
  const handlePrincipalChange = (e) => {
    setPrincipalNumber(e.target.value);
    setPrincipalNumberError("");
  };

  const handlePincodeChange = async (e) => {
    const enteredPincode = e.target.value.trim();
    setPincode(enteredPincode);
    setPincodeError("");

    if (enteredPincode.length !== 6 || isNaN(enteredPincode)) {
      setPincodeError("Please enter a valid 6-digit pincode.");
      return;
    }

    try {
      const response = await axios.get(`https://api.postalpincode.in/pincode/${enteredPincode}`);

      if (response.data && response.data.length > 0 && response.data[0].Status === "Success") {
        const postOffice = response.data[0].PostOffice[0];

        setCity(postOffice.Block);
        setDistrict(postOffice.District);
        setState(postOffice.State);
      } else {
        // Show error message if pincode not found
        setCity("");
        setDistrict("");
        setState("");
        setPincodeError("Pincode not found.");
      }
    } catch (error) {

      setPincodeError("Error fetching pincode details. Please try again later.");
    }
  }



  useEffect(() => {
    // Fetch available plans from the API
    const fetchAvailablePlans = async () => {
      try {

        const response = await fetch(`${apiUrl}/plans/Type/i`,
          {
            headers: {

              'Content-Type': 'application/json', // Adjust the content type if needed
            },
          })
        const data = await response.json();
        setAvailablePlans(data);

      } catch (error) {

      }
    };

    fetchAvailablePlans(); // Call the fetchAvailablePlans function when the component mounts
  }, []); //

  const handleHrNumberChange = (e) => {
    setHrNumber(e.target.value);
    setHrNumberError("");
  };

  const handleOriginalCertificatesChange = (selectedOption) => {
    setOriginalCertificates(selectedOption.value);
    setOriginalCertificatesError("");
  };

  const handleNoticePeriodChange = (e) => {
    const value = e.target.value;
    setNoticePeriod(value);
    validateNoticePeriod(value);
  };

  const handleAgreementDurationChange = (e) => {
    const value = e.target.value;
    setAgreementDuration(value);
    validateAgreementDuration(value);
  };

  const handleSalaryDuringVacationChange = (selectedOption) => {
    setShowSalaryDuringVacation(selectedOption.value);
    setShowSalaryDuringVacationError("");
  };

  const handleAddRow = () => {
    // Add a new empty row when the "Add Row" button is clicked
    setSalaryRanges((prevRanges) => [
      ...prevRanges,
      { qualification: "", salaryFrom: "", salaryTo: "" },
    ]);
  };

  const handleFacilitiesChange = (selectedOptions) => {
    const newFacilities = selectedOptions.map(option => option.value);
    setSelectedFacilities(newFacilities);
  };


  const handleRemoveRow = (index) => {
    if (salaryRanges.length > 1) {
      setRemoveEmployeeRowIndex(index);
      setEmployeeConfirmationDialogOpen(true);
    }
  };


  const handleConfirmEmployeeRemove = () => {
    const updatedSalaryRanges = [...salaryRanges];
    updatedSalaryRanges.splice(removeEmployeeRowIndex, 1);
    setSalaryRanges(updatedSalaryRanges);
    setSelectedQualifications((prevQualifications) =>
      prevQualifications.filter((_, idx) => idx !== removeEmployeeRowIndex)
    );
    setRemoveEmployeeRowIndex(null);
    setEmployeeConfirmationDialogOpen(false);
  };

  const handleCancelEmployeeRemove = () => {
    setRemoveEmployeeRowIndex(null);
    setEmployeeConfirmationDialogOpen(false);
  };

  const handleSalaryChange = (index, fieldName, value) => {
    const updatedRanges = [...salaryRanges];
    const updatedRange = { ...updatedRanges[index], [fieldName]: value };

    // Check if Salary To is greater than Salary From
    if (
      fieldName === "salaryTo" &&
      parseInt(value, 10) <= parseInt(updatedRange.salaryFrom, 10)
    ) {

    }

    updatedRanges[index] = updatedRange;
    setSalaryRanges(updatedRanges);
  };

  const handleQualificationChange = (index, selectedValue) => {
    // Update the specific index in the selectedQualifications array
    setSelectedQualifications((prevQualifications) => {
      const updatedQualifications = [...prevQualifications];
      updatedQualifications[index] = selectedValue; // Update the selected value at the specific index
      return updatedQualifications;
    });
  };





  useEffect(() => {
    // Update End Date when selectedPlanOption changes
    setEndDate(getFormattedDate(getNextYearDate()));
  }, []);

  const updateClassDetail = (prevClassDetails, index, field, value) => {
    const updatedClassDetails = [...prevClassDetails];
    updatedClassDetails[index] = {
      ...updatedClassDetails[index],
      [field]: value,
    };
    return updatedClassDetails;
  };
  const handleRemoveClassRow = (index) => {
    if (classDetails.length > 1) {
      setRemoveRowIndex(index);
      setConfirmationDialogOpen(true);
    }
  };

  const handleConfirmRemove = () => {
    const updatedDetails = [...classDetails];
    updatedDetails.splice(removeRowIndex, 1);
    setClassDetails(updatedDetails);
    setRemoveRowIndex(null);
    setConfirmationDialogOpen(false);
  };

  const handleCancelRemove = () => {
    setRemoveRowIndex(null);
    setConfirmationDialogOpen(false);
  };

  const handleAddClassRow = () => {
    setClassDetails((prevClassDetails) => [
      ...prevClassDetails,
      { className: "", divisions: "", strength: "", fees: "" },
    ]);
  };

  function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function getNextYearDate() {
    const nextYear = new Date();
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    return nextYear;
  }

  //Input validation States method in profile Information
  const validatename = (value) => {
    if (!value.trim()) {
      setnameError("Name is required");
      return false;
    }
    setnameError("");
    return true;
  };

  const validatepasswordempty = (value) => {
    if (!value.trim()) {
      setnameError("Password is required");
      return false;
    }
    setnameError("");
    return true;
  };



  const validateCity = (value) => {
    if (!value.trim()) {
      setCityError("City is required");
      return false;
    }
    setCityError("");
    return true;
  };
  const validatePrincipalnumber = (value) => {
    const numericRegex = /^[0-9]+$/;

    if (
      !value.trim() ||
      !numericRegex.test(value.trim()) ||
      value.trim().length !== 10
    ) {
      setPrincipalNumberError("Enter a valid 10-digit numeric phone number");
      return false;
    }

    setPrincipalNumberError(""); // Clear the error when the validation passes
    return true;
  };

  const validateHRnumber = (value) => {
    const numericRegex = /^[0-9]+$/;

    if (
      !value.trim() ||
      !numericRegex.test(value.trim()) ||
      value.trim().length !== 10
    ) {
      setHrNumberError("Enter a valid 10-digit numeric HR phone number");
      return false;
    }

    setHrNumberError(""); // Clear the error when the validation passes
    return true;
  };

  const validatePincode = (value) => {
    if (!value) {
      setPincodeError("Pin Code is required");
      return false;
    }

    const pincodeRegex = /^[0-9]{6}$/; // Assuming a 6-digit pin code, adjust as needed

    if (!pincodeRegex.test(value)) {
      setPincodeError("Enter a valid 6-digit Pin Code");
      return false;
    }

    setPincodeError("");
    return true;
  };

  const validateFirstLanguage = () => {
    if (selectedlanguages.length === 0) {
      setFirstLanguageError("Language is required");
      return false;
    } else {
      setFirstLanguageError("");
      return true;
    }
  };


  const validateClassRunFrom = (value) => {
    if (!value) {
      setclassromFromError("Class is required");
      return false;
    }
    setclassromFromError("");
    return true;
  };


  const validateClassRunTo = (value) => {
    const intValue = parseInt(value, 10);
    const intClassRunFrom = parseInt(classrunFrom, 10);

    if (!value) {
      setclassromToError("Class is required");
      return false;
    }
    if (intValue <= intClassRunFrom) {
      setclassromToError("Classes Run To should be greater than classes Run From");
      return false;
    }
    setclassromToError("");
    return true;
  };



  const validateNameOfTrust = (value) => {
    if (!value.trim()) {
      setNameOfTrustError("Name of Trust or Society is required");
      return false;
    }
    setNameOfTrustError("");
    return true;
  };

  const validateMedium = (value) => {
    if (!value) {
      setMediumError("Medium is required");
      return false;
    }
    setMediumError("");
    return true;
  };

  const validateEstablishmentYear = (value) => {
    const yearRegex = /^[0-9]{4}$/;
    const currentYear = new Date().getFullYear();

    if (!value || !yearRegex.test(value)) {
      setEstablishmentYearError("Enter a valid year (4 digits)");
      return false;
    }

    if (parseInt(value, 10) > currentYear) {
      setEstablishmentYearError(`Year cannot be greater than ${currentYear}`);
      return false;
    }

    setEstablishmentYearError("");
    return true;
  };


  const validateLandline = (value) => {
    const numericRegex = /^[0-9]+$/;

    // Check if the value is not empty and contains only numeric characters
    if (!value || !numericRegex.test(value)) {
      setLandlineError("Enter a valid phone number");
      return false;
    }

    // Check if the number has exactly 10 digits
    if (value.length !== 10) {
      setLandlineError("Phone number should be 10 digits");
      return false;
    }

    // Clear the error if all conditions are met
    setLandlineError("");
    return true;
  };

  const validateApplicationNumber = (value) => {
    if (!value) {
      setApplicationNumberError("Enter a valid affiliation number");
      return false;
    }

    if (value.length > 20) {
      setApplicationNumberError("Affiliation number cannot exceed 20 characters");
      return false;
    }

    setApplicationNumberError("");
    return true;
  };


  const validateUdisenumberNumberEmpty = (value) => {
    if (!value) {
      setUdiseNumberError("");
      return true;
    } else if (value) {
      const udisenumberRegex = /^[0-9]{11}$/; // Match exactly 11 digits
      if (!udisenumberRegex.test(value)) {
        setUdiseNumberError("Enter a valid 11 digit numeric values");
        return false;
      } else {
        return udisenumberRegex.test(value);
      }
    }

  };



  const validateCompleteAddress = (value) => {
    if (!value) {
      setCompleteAddressError("Address is required");
      return false;
    }
    setCompleteAddressError("");
    return true;
  };




  const validateDistrict = (value) => {
    if (!value) {
      setDistrictError("District is required");
      return false;
    }
    setDistrictError("");
    return true;
  };


  const validateSchoolwebsite = (value) => {
    const urlPattern = /^(ftp|http|https):\/\/(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})+(\/\S*)?$/;
    if (!value) {
      setschoolWebsiteError("School website is required");
      return false;
    } else if (!urlPattern.test(value)) {
      setschoolWebsiteError("Please enter a valid URL starting with http(s)");
      return false;
    }
    setschoolWebsiteError("");
    return true;
  };


  const validateVideoUrl = (value) => {
    const urlPattern = /^(ftp|http|https):\/\/(www\.)?[^\s/$.?#].[^\s]*$/;
    if (!value) {
      setvideoURLError("School premise's youtube video link is required");
      return false;
    }
    else if (!urlPattern.test(value)) {
      setvideoURLError("Please enter a valid URL starting with http(s)");
      return false;
    }
    setvideoURLError("");
    return true;
  };

  const validateChairmanMessage = (value) => {
    const urlPattern = /^(ftp|http|https):\/\/(www\.)?[^\s/$.?#].[^\s]*$/;
    if (!value) {
      setChairmanmMessageError("Principal/Chairman's message Youtube Video Link required");
      return false;
    }
    else if (!urlPattern.test(value)) {
      setChairmanmMessageError("Please enter a valid URL starting with http(s)");
      return false;
    }
    setChairmanmMessageError("");
    return true;
  };

  const validateState = (value) => {
    if (!value) {
      setstateError("State is required");
      return false;
    }
    setstateError("");
    return true;
  };


  const validateNoticePeriod = (value) => {
    const inputValue = parseInt(value, 10);

    if (isNaN(inputValue) || inputValue < 1 || inputValue > 12) {
      setNoticePeriodError("Notice period must be a number between 1 and 12 Months.");
    } else {
      setNoticePeriodError("");
    }

    return !isNaN(inputValue) && inputValue >= 1 && inputValue <= 12;
  };

  const validateAgreementDuration = (value) => {
    const inputValue = parseInt(value, 10);

    if (isNaN(inputValue) || inputValue < 1 || inputValue > 12) {
      setAgreementDurationError("Agreement Duration must be a number between 1 and 12 Months.");
    } else {
      setAgreementDurationError("");
    }

    return !isNaN(inputValue) && inputValue >= 1 && inputValue <= 12;
  };



  const validateOriginalCertificates = (value) => {
    if (value === null || value === undefined || !value) {
      setOriginalCertificatesError("Original certificates field is required");
      return false;
    }
    setOriginalCertificatesError("");
    return true;
  };

  const validateOption = (value) => {
    if (value === null || value === '0' || value === undefined || !value) {
      setShowSalaryDuringVacationError("Salary during vacation field is required");
      return false;
    }
    setShowSalaryDuringVacationError("");
    return true;
  };

  const validateAgree = (value) => {
    if (value === null || value === undefined || !value) {
      setAgreementError("Agreement field is required");
      return false;
    }
    setAgreementError("");
    return true;
  };

  const validateUseid = (value) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!value) {
      setUserIdError("Email is required");
      return false;
    } else if (!emailRegex.test(value)) {
      setUserIdError("Please enter a valid email address");
      return false;
    }

    setUserIdError("");
    return true;
  };


  const validateConfirmPasswordEmpty = (value) => {
    if (!value) {
      setConfirmPasswordError("Confirm Password  is required");
      return false;
    }
    setConfirmPasswordError("");
    return true;
  };

  const validateConfirmPassword = (value1, value2) => {
    if (value1 != value2) {
      setConfirmPasswordError("Passwords do not match");
      return false;
    }
    else if (password !== confirmPassword) {
      setPasswordError("Password must be at least 8 characters long and contain at least one special character");
      return false;
    }
    setConfirmPasswordError("");
    return true;
  };

  const validatePassword = (value) => {
    const passwordRegex = /^(?=.*[!@#$%^&*.])[A-Za-z\d!@#$%^&*.]{8,}$/;

    if (!value) {
      setPasswordError("Password is required");
      return false;
    } else if (!passwordRegex.test(value)) {
      setPasswordError("Password must be at least 8 characters long and contain at least one special character");
      return false;
    }

    setPasswordError("");
    return true;
  };


  const validateClassDetails = () => {
    let isValid = true;

    // Your validation logic for class details
    // For example, check if any of the required fields are empty
    classDetails.forEach((detail, index) => {
      if (
        !detail.className ||
        !detail.divisions ||
        !detail.strength ||
        !detail.fees
      ) {
        // Set an error message for the specific row
        setClassDetailsError(
          `Please fill in all fields for Class ${index + 1}`
        );
        isValid = false;
      }
    });

    return isValid;
  };

  const handleAgreementChange = (selectedOption) => {
    setAgreement(selectedOption.value);
    setAgreementError("");
    setShowAgreementDuration(selectedOption.value === "Yes");
    if (selectedOption.value === "No") {
      setAgreementDuration("");
      setAgreementDurationError("");
    }
  };

  const fetchAnotherData = async () => {
    try {
      const bearerToken = userData.accessToken;
      const response = await fetch(
        `${apiUrl}/createaccount/${userLoginCode.name}`,
        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
        })
      const data = await response.json();
      setinstituteid(data.institute.id);
      setName(data.institute.name);
      setUserId(data.institute.login);
      setibasicid(data.i_Basic.id);
      setPincode(data.i_Basic.pin);
      setNameOfTrust(data.i_Basic.trust);
      setMedium(data.i_Basic.medium);
      setEstablishmentYear(data.i_Basic.estYear);
      setFirstLanguage(data.i_Basic.lang1);
      setSelectedSecondLanguage(data.i_Basic.lang2);
      setThirdLanguage(data.i_Basic.lang3);
      setSelectedlanguages([data.i_Basic.lang1, data.i_Basic.lang2, data.i_Basic.lang3]);
      setCompleteAddress(data.i_Basic.address1);
      setAddress2(data.i_Basic.address2);
      setCity(data.i_Basic.city);
      setPhoto(data.i_Basic.pin);
      setDistrict(data.i_Basic.dist);
      setState(data.i_Basic.state);
      if (data.i_Basic?.phone) {
        setLandline(data.i_Basic.phone);
        setPhonevarifiedOnce(true);
      } else {
        setPhonevarifiedOnce(false);
      }
      setLandline(data.i_Basic.phone);
      setPrincipalNumber(data.i_Basic.princiNo);
      setHrNumber(data.i_Basic.hrNo);
      setAffiliationType(data.i_Basic.afflType);
      setApplicationNumber(data.i_Basic.afflNo);
      setUdiseNumber(data.i_Basic.udiceno);
      setPdfPath(data.i_Basic.rrCopy);
      setVideourl(data.i_Basic.introLink);
      setSchoolWebsite(data.i_Basic.website);
      setChairmanmMessage(data.i_Basic.chairmanmsg);
      setClassromFrom(data.i_Basic.classrunFrom);
      setClassromTo(data.i_Basic.classrunTo);
      setEmployeeIdPE(data.i_Basic.pfId);
      setEmployeeIdESI(data.i_Basic.esiId);
      setClTeacherStaff(data.i_Basic.casualLeaves);
      setClTeacherNonStaff(data.i_Basic.sickLeaves);
      setShowSalaryDuringVacation(data.i_Basic.vacationPay === 1 ? "Yes" : "No");
      setOriginalCertificates(data.i_Basic.originalCertificates === 1 ? "Yes" : "No");
      setNoticePeriod(data.i_Basic.noticePeriod);
      setAgreement(data.i_Basic.agreement === 1 ? "Yes" : "No");
      setAgreementDuration(data.i_Basic.agreementdur);

      const classDetailsList = data.i_Class.map((classDetail) => ({
        id: classDetail.id,
        className: classDetail.className,
        divisions: classDetail.division,
        strength: classDetail.strengthPerDivision,
        fees: classDetail.fees,
      }));
      setClassDetails(classDetailsList.length > 0 ? classDetailsList : initialClassDetails);

      const facilityList = data.i_Facilities.map((facility) => ({
        id: facility.id,
        type: facility.type,
        value: facility.value,
        icode: facility.icode,
      }));
      const selectedFacilities = facilityList.map((facility) => facility.type);
      setSelectedFacilities(selectedFacilities);
      const logoImage = baseUrl + data.i_Basic.logo
      const PhotoImage = baseUrl + data.i_Basic.schoolPhoto;
      const pdfview = baseUrl + data.i_Basic.rrCopy;
      setLogoPreview(logoImage);
      setPhotoPreview(PhotoImage);
      setPdfLink(pdfview);
      setLogoPath(data.i_Basic.logo);
      setPhotoPath(data.i_Basic.schoolPhoto);

      const preferenceList = data.i_Pref_Basic.map((preference) => ({
        course: preference.course,
        salaryFrom: preference.salaryFrom,
        salaryTo: preference.salaryTo,
        qual: preference.qual ? preference.qual.split(',') : []
      }));
      const qulifications = preferenceList.map((qulifications) => qulifications.qual);

      setSalaryRanges(preferenceList.length > 0 ? preferenceList : initialsalaryRanges);
      setSelectedQualifications(qulifications);

      //setiplanid(data.i_Plan.id);
      setSelectedPlan(data.i_Plan.plan);
      setStartDate(data.i_Plan.startDate);
      setEndDate(data.i_Plan.endDate);

      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.Exception === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.Exception === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }
    } catch (error) {

    }
  };




  useEffect(() => {
    if (Object.keys(userLoginCode).length > 0) {
      fetchAnotherData();
    }
  }, [userLoginCode]);


  const handleroute = () => {
    window.location.href = "/auth/Login";
  }

  const accountActivate = async () => {
    try {
      const url = `${apiUrl}/otp/sendwelcomeemail`;
      const response = await axios.post(url, {
        email: userId,
      });

      if (response.data.code === 0) {
        setSnackbarMessage(response.data.description);
        setSnackbarBgColor('green');
        setIsSuccess(true);
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage(response.data.description);
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Error Activating Account');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
    }
  };

  const handleInstitutionProfile = () => {
    //Profile validations
    const isNameValid = validatename(name);
    const isNameOfTrustValid = validateNameOfTrust(nameOfTrust);
    const isClassRunFromValid = validateClassRunFrom(classrunFrom);
    const isClassRunToValid = validateClassRunTo(classrunTo);
    const isFirstLanguageValid = validateFirstLanguage(firstLanguage);
    const isMediumValid = validateMedium(medium);

    const isEstablishmentYearValid =
      validateEstablishmentYear(establishmentYear);
    const isUserIdValid = validateUseid(userId);
    const isLandlineValid = validateLandline(landline);
    const isApplicationNumberValid =
      validateApplicationNumber(applicationNumber);
    const isUdiseNumberValidEmpty =
      validateUdisenumberNumberEmpty(udisenumber);
    //const isUdiseNumberValid = validateUdisenumberNumber(udisenumber);
    const isPrincipalValid = validatePrincipalnumber(principalNumber);
    const isHRValid = validateHRnumber(hrNumber);
    const isCompleteAddressValid = validateCompleteAddress(completeAddress);
    const isCityValid = validateCity(city);
    const isPincodeValid = validatePincode(pincode);
    const isDistrictValid = validateDistrict(district);
    const isStateValid = validateState(state);

    // Check if all validations passed
    const isAllValidBasic =
      isNameValid &&
      isNameOfTrustValid &&
      isCityValid &&
      isPincodeValid &&
      isFirstLanguageValid &&
      isClassRunFromValid &&
      isClassRunToValid &&
      isMediumValid &&
      isEstablishmentYearValid &&
      isUserIdValid &&
      isLandlineValid &&
      isApplicationNumberValid &&
      isUdiseNumberValidEmpty &&
      isCompleteAddressValid &&
      isDistrictValid &&
      isPrincipalValid &&
      isHRValid &&
      isStateValid;

    if (isAllValidBasic) {
      if (!phonevarifiedOnce) {
        if (!isPhoneVerified || !isPhoneOtpVerified) {
          setSnackbarMessage('Please verify your phone number before proceeding.');
          setSnackbarBgColor('red');
          setIsSuccess(false);
          setSnackbarOpen(true);
          return;
        }
      }

      if (!userId || (!password && profileCompletionPercentage == 0)) {
        setSnackbarMessage('Please Enter Login Credentials..');
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
        return false;
      }
      handlesubmit();
      setCurrentStep();
      handleExpand();
      setInstituteProfileSuccess(true);
      setExpandedAdditionalDetails(true);
      return true;
    } else {
      setSnackbarMessage("Validation failed. Please check all the fields before submitting.");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return false;
    }
  };

  const handleInstitutionProfileReset = () => {
    setName("");
    setnameError("");
    setNameOfTrust("");
    setNameOfTrustError("");
    setClassromFrom("");
    setclassromFromError("");
    setClassromTo(null);
    setclassromToError("");
    setState("");
    setSelectedlanguages("");
    setFirstLanguageError("");
    setMedium('');
    setMediumError("");
    setUserIdError("");
    setAffiliationType('');
    setAffiliationTypeError("");
    setEstablishmentYear("");
    setEstablishmentYearError("");
    setEmail("");
    setEmailError("");
    setLandline("");
    setLandlineError("");
    setApplicationNumber("");
    setApplicationNumberError("");
    setPrincipalNumber("");
    setPrincipalNumberError("");
    setHrNumber("");
    setHrNumberError("");
    setAddress2("");
    setAddress2Error("");
    setCompleteAddress("");
    setCompleteAddressError("");
    setPincode("");
    setPincodeError("");
    setUdiseNumber("");
    setUdiseNumberError("")
    setCity("");
    setCityError("");
    setDistrict("");
    setDistrictError("");
    setstateError("");
    setInstituteProfileSuccess(false);
  }

  const handleAdditionalDetails = async () => {
    const isEmpty = classDetails.some(detail => (
      !detail.className || !detail.divisions || !detail.strength || !detail.fees
    ));
    if (isEmpty) {
      setIsFieldsEmpty(true);
      toast.warn('Please fill in all fields');
    } else if (!validateNumericValues()) {
      toast.warn('Please enter numeric values only');
    } else {

      if (!userId || (!password && profileCompletionPercentage == 0)) {
        setSnackbarMessage('Please Enter Login Credentials..');
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
        return false;
      }
      handlesubmit();
      handleExpandAdditionalDetails();
      setAdditionalDetailsSuccess(true);
      setExpandedUploadDocuments(true);

    }
  };



  const validateNumericValues = () => {
    for (const detail of classDetails) {
      if (isNaN(detail.divisions) || isNaN(detail.strength) || isNaN(detail.fees)) {
        return false;
      }
    }
    return true;
  };


  const handleAdditionalDetailsReset = () => {
    setClassDetails(initialClassDetails);
    setSelectedFacilities([]);
    setAdditionalDetailsSuccess(false);
  }

  const handleNextUploadDocument = async () => {
    // Validate uploaded documents
    const isSchoolWebsiteValid = validateSchoolwebsite(schoolWebsite);
    const isVideoUrlValid = validateVideoUrl(videoURL);
    const isChairmanValid = validateChairmanMessage(ChairmanmMessage);

    // Check if all validations passed
    const isAllValidUploadDocument =
      !logoPath || !photoPath || !pdfPath ? false : isSchoolWebsiteValid && isVideoUrlValid && isChairmanValid;

    if (isAllValidUploadDocument) {

      if (!userId || (!password && profileCompletionPercentage === 0)) {
        setSnackbarMessage('Please Enter Login Credentials..');
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
        return false;
      }
      handlesubmit();
      setCurrentStep();
      handleExpandUploadDocuments();
      setUploadDocumentsSuccess(true);
      setExpandedSalaryOffered(true);
      return true; // Return true when all conditions are met
    } else {
      // Display alert if validations fail
      setSnackbarMessage("Validation failed. Please check all the fields before submitting OR check for files to be uploaded.");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return false;
    }
  };


  const handleUploadDocumentReset = () => {
    setLogo(null);
    setPhoto(null);
    setPdf(null);
    setLogoPreview("");
    setPhotoPreview("");
    setPdfLink("");
    setSchoolWebsite("");
    setschoolWebsiteError("");
    setvideoURLError("");
    setChairmanmMessage("");
    setChairmanmMessageError("");
    setVideourl("");
    setUploadSuccessLogo(false);
    setUploadSuccess(false);
    setUploadSuccessPdf(false);
    setUploadDocumentsSuccess(false);
  }

  const handleEmploymentStrategies = () => {
    // Check if any field is empty
    const validate = salaryRanges.some(range => !range.salaryFrom || !range.salaryTo);
    if (validate) {
      // Display an error message if any field is empty
      toast.warning('Please Enter Salary Range');
      return;
    }
    const isQualificationSelected = salaryRanges.some((range, index) => !selectedQualifications[index]);
    if (isQualificationSelected) {
      toast.warning('Please Select Qualification');
      return;
    }

    // Validate if both "Salary From" and "Salary To" contain numeric values
    const isNumeric = salaryRanges.every(range => !isNaN(range.salaryFrom) && !isNaN(range.salaryTo));
    if (!isNumeric) {
      // Display an error message if any salary field is not numeric
      toast.warning('Please enter numeric values for salary range');
      return;
    }

    // Ensure that "Salary To" is greater than "Salary From"
    const isValidRange = salaryRanges.every(range => parseFloat(range.salaryTo) > parseFloat(range.salaryFrom));
    if (!isValidRange) {
      // Display an error message if any salary range is invalid
      toast.warning('Maximum Salary must be greater than Minimum Salary');
      return;
    }

    if (!userId || (!password && profileCompletionPercentage == 0)) {
      setSnackbarMessage('Please Enter Login Credentials..');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return false;
    }
    handlesubmit();
    handleExpandSalaryOffered();
    setemploymentStrategiesSuccess(true);
    setExpandedJobSecurity(true);
  };

  const handleEmploymentStrategiesReset = () => {
    setSelectedQualifications("");
    setSalaryRanges([
      { qualification: "", salaryFrom: "", salaryTo: "" },
    ]);
    setemploymentStrategiesSuccess(false);
  }

  const handleNextJobSecurity = () => {
    const isOriginalCertificatesValid =
      validateOriginalCertificates(originalCertificates);
    const isShowSalaryDuringVacationValid = validateOption(
      showSalaryDuringVacation
    );
    const isNoticePeriodValid = noticePeriod ? validateNoticePeriod(noticePeriod) : true;
    const isAgreementValid = validateAgree(agreement);
    const isAgreementDurationValid = agreement === 'Yes' ? validateAgreementDuration(agreementDuration) : true;

    // Check if all validations passed
    const isAllValidJobSecurity =
      isOriginalCertificatesValid &&
      isShowSalaryDuringVacationValid &&
      isAgreementValid &&
      isAgreementDurationValid &&
      isNoticePeriodValid;


    if (isAllValidJobSecurity) {

      if (!userId || (!password && profileCompletionPercentage == 0)) {
        setSnackbarMessage('Please Enter Login Credentials..');
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
        return false;
      }
      handlesubmit();
      setCurrentStep();
      handleExpandJobSecurity();
      setjobSecuritySuccess(true);
      setExpandedPlan(true);
      return true;
    } else {

      setSnackbarMessage(
        "Validation failed. Please check all the fields before submitting."
      );
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return false;
    }
  };

  const handleJobSecurityReset = () => {
    setEmployeeIdPE("");
    setEmployeeIdESI("");
    setClTeacherStaff("");
    setClTeacherNonStaff("");
    setNoticePeriod("");
    setOriginalCertificates(null);
    setShowSalaryDuringVacation("");
    setAgreementDuration("");
    setAgreement("");
    setClTeacherStaffError("");
    setClTeacherNonStaffError("");
    setNoticePeriodError("");
    setOriginalCertificatesError("");
    setShowSalaryDuringVacationError("");
    setAgreementError("");
    setAgreementDurationError("");
    setjobSecuritySuccess(false);
  }

  const handleCreateAccount = async () => {
    const isUserIdValid = validateUseid(userId);
    const isNameValid = validatename(name);
    const isPasswordEmpty = validatepasswordempty(password);
    const isPasswordValid = validatePassword(password);
    const isConfirmPasswordValidEmpty = validateConfirmPasswordEmpty(confirmPassword);
    const isConfirmPasswordValid = validateConfirmPassword(password, confirmPassword);

    if (!isUserIdValid || !isPasswordValid || !isNameValid || !isPasswordEmpty || !isConfirmPasswordValidEmpty || !isConfirmPasswordValid) {
      if (!isNameValid) {
        setnameError("Institution name is required");
      } else {
        setnameError("");
      }

      if (!isPasswordEmpty) {
        setPasswordError("Password is required");
      } else if (!isPasswordValid) {
        setPasswordError("Password must be at least 8 characters long and contain at least one special character");
      } else {
        setPasswordError("");
      }

      if (!isConfirmPasswordValidEmpty) {
        setConfirmPasswordError("Confirm Password is required");
      } else if (!isConfirmPasswordValid) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
      return; // Exit function if any validation fails
    }

    if (!isEmailVerified) {
      setSnackbarMessage('Please verify your email and OTP before proceeding.');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }

    // Proceed if all validations pass
    if (profileCompletionPercentage === 0 || profileCompletionPercentage === undefined || profileCompletionPercentage === null) {
      try {
        let s = `${apiUrl}/auth/available/${userId}`;
        const response = await axios.get(s, {
          headers: {
            'name': name,
            'pw': password,
            'type': 'i',
            'Content-Type': 'application/json',
            'ctype': ''
          },
        });
        const data = response.data;

        if (data === true) {
          setUserIdError('Email already exists. Please choose a different one.');
        } else {
          if (data.cd === 1) {
            setSnackbarMessage(data.description);
            setSnackbarBgColor('red');
            setIsSuccess(false);
            setSnackbarOpen(true);
          } else {
            setprofileCompletionPercentage(data.cd);
            setSnackbarMessage('Your draft account created successfully');
            setSnackbarBgColor('green');
            setIsSuccess(true);
            setSnackbarOpen(true);
            const loginData = { name: userId, password: password, type: 'i' };
            axios.post(`${apiUrl}/token/login`, loginData)
              .then((res) => {
                if (res.data) {
                  if (res.data.cd === 1) {
                    setSnackbarMessage(res.data.description);
                    setSnackbarBgColor('red');
                    setIsSuccess(false);
                    setSnackbarOpen(true);
                  } else {
                    setToken(res.data.accessToken);
                    setExpandedCreateAccount(false);
                    setcreateAccountsuccess(true);
                    setExpanded(true);
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setExpandedCreateAccount(false);
    }
  };

  const handlePlan = () => {
    if (!selectedPlan) {
      setSnackbarMessage('Please Select Plan.');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return false;
    }
    if (!agreedToTerms) {
      setSnackbarMessage('Please agree to the Terms and Conditions.');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return false;
    }
    if (!userId || (!password && profileCompletionPercentage == 0)) {
      setSnackbarMessage('Please Enter Login Credentials..');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return false;
    }
    handlesubmit();
    handleExpandPlan();
    setsubscriptionPlansSuccess(true);
  };



  const handleCreateAccountReset = () => {
    setUserId("");
    setUserIdError("");
    setPassword("");
    setPasswordError("");
    setName("");
    setnameError("");
    setConfirmPassword("");
    setConfirmPasswordError("");
    setcreateAccountsuccess(false);
  }

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleExpandPlan = () => {
    setExpandedPlan(!expandedPlan);
  };

  const handleExpandCreateAccount = () => {
    setExpandedCreateAccount(!expandedCreateAccount);
  };


  const salaryDuringVacationOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ];

  const handleExpandUploadDocuments = () => {
    setExpandedUploadDocuments(!expandedUploadDocuments);
  };

  const handleExpandSalaryOffered = () => {
    setExpandedSalaryOffered(!expandedSalaryOffered);
  };

  const handleExpandJobSecurity = () => {
    setExpandedJobSecurity(!expandedJobSecurity);
  };
  const handleLogoUpload = (files) => {
    const selectedFile = files[0];
    setLogo(selectedFile);
    const previewURL = URL.createObjectURL(selectedFile);
    setLogoPreview(previewURL);
    setUploadSuccessLogo(true);
  };

  const handlePhotoUpload = (files) => {

    const selectedFile = files[0];
    // Set the selected logo file in the state
    setPhoto(selectedFile);
    // Create a preview URL for the uploaded photo
    const previewURL = URL.createObjectURL(selectedFile);
    setPhotoPreview(previewURL);
    setUploadSuccess(true);






  };

  const handleApplicationCopyPDFUpload = (files) => {
    const uploadedPdf = files[0];

    // Set PDF link for text display
    setPdf(uploadedPdf);
    const pdfURL = URL.createObjectURL(uploadedPdf);
    setPdfLink(pdfURL);

    // Set upload success message for PDF
    setUploadSuccessPdf(true);

    // Your other logic for handling PDF upload...
  };

  const handleExpandAdditionalDetails = () => {
    setExpandedAdditionalDetails(!expandedAdditionalDetails);
  };

  const handleupload = async () => {
    if (!landline) {
      setSnackbarMessage("Please complete Institution Profile Before Proceeding");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }
    if (!logoPreview) {
      setSnackbarMessage("Please attach logo");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }

    if (!photoPreview) {
      setSnackbarMessage("Please attach front view");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }

    if (!pdfLink) {
      setSnackbarMessage("Please attach RR/Affiliation Copy");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }

    const documents = [
      { file: logo, preview: logoPreview, nm: 'School Logo' },
      { file: photo, preview: photoPreview, nm: 'School Building Front View' },
      { file: pdf, preview: pdfLink, nm: 'RR/Affiliation Copy' }
    ];

    for (const { file, preview, nm } of documents) {
      if (file != null && file !== 'null') {
        if (file.size > 2 * 1024 * 1024) {
          setSnackbarMessage(`${nm} size exceeds 2 MB. Please upload a smaller file.`);
          setSnackbarBgColor('red');
          setIsSuccess(false);
          setSnackbarOpen(true);
          continue;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
          const response = await axios.post(`${fileUrl}&path=${landline}&env=${env}&projectcode=05&filetype=${nm}`, formData);
          const data = response.data;
          if (data.cd === 0) {
            switch (nm) {
              case 'School Logo':
                setLogoPath(data.techdescription);
                break;
              case 'School Building Front View':
                setPhotoPath(data.techdescription);
                break;
              case 'RR/Affiliation Copy':
                setPdfPath(data.techdescription);
                break;
              default:
                break;
            }
            toast.success(`${nm} Uploaded successfully.`);
          } else {
            toast.error(`Failed to upload ${nm}. Description: ${data.description}`);
          }
        } catch (error) {
          toast.error(`Error uploading ${nm}: ${error.message}`);
        }
      }
    }
  };



  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await getDropdownData('LN');
        const mappedOptions = response.map((option) => ({
          value: option.code,
          label: option.value,
        }));
        setLangugeoptions(mappedOptions);
      } catch (error) {

      }
    };

    fetchLanguages();
  }, [getDropdownData]);

  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        const response = getDropdownData('FA');
        const mappedOptions = response.map(option => ({
          value: option.code,
          label: option.value,
        }));
        setFacilitiesOptions(mappedOptions);
      } catch (error) {

      }
    };

    fetchFacilities();
  }, [getDropdownData]);


  useEffect(() => {
    const fetchQulifications = async () => {
      try {
        const response = getDropdownData('QA');
        const mappedOptions = response.map(option => ({
          value: option.code,
          label: option.value,
        }));
        setqualificationOptions(mappedOptions);
      } catch (error) {

      }
    };

    fetchQulifications();
  }, [getDropdownData]);

  useEffect(() => {
    const fetchMediumOptions = async () => {
      try {
        const response = getDropdownData('MD');
        const mappedOptions = response.map(option => ({
          value: option.code,
          label: option.value,
        }));

        setMediumOptions(mappedOptions);
      } catch (error) {

      }
    };

    fetchMediumOptions();
  }, [getDropdownData]);

  useEffect(() => {
    const fetchClassesFromOption = async () => {
      try {
        const response = getDropdownData('CF');
        const mappedOptions = response.map(option => ({
          value: option.code,
          label: option.value,
        }));

        setClassesFromOption(mappedOptions);
      } catch (error) {

      }
    };

    fetchClassesFromOption();
  }, [getDropdownData]);

  useEffect(() => {
    const fetchClassesToOption = async () => {
      try {
        const response = getDropdownData('CT');
        const mappedOptions = response.map(option => ({
          value: option.code,
          label: option.value,
        }));

        setClassesToOption(mappedOptions);
      } catch (error) {

      }
    };

    fetchClassesToOption();
  }, [getDropdownData]);


  useEffect(() => {
    const fetchAffiliationTypeOptions = async () => {
      try {

        const response = getDropdownData('AF');
        const mappedOptions = response.map(option => ({
          value: option.code,
          label: option.value,
        }));
        setAffiliationTypeOptions(mappedOptions);
      } catch (error) {

      }
    };

    fetchAffiliationTypeOptions();

  }, [getDropdownData]);


  const handlesubmit = async () => {
    if (utrNumber || selectedPlan) {
      if (!agreedToTerms) {
        setSnackbarMessage('Please agree to the Terms and Conditions.');
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
        return;
      }
    }
    if (!userId || (!password && profileCompletionPercentage == 0)) {
      setSnackbarMessage('Please Enter Login Credentials..');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }

    // const selectedCourses = selectedQualification.split("-")[0];
    const salaryvacation = showSalaryDuringVacation === "Yes" ? "1" : "0";
    const certificate = originalCertificates === "Yes" ? "1" : "0";
    const Agreement = agreement === "Yes" ? "1" : "0";
    // const planvalidate = selectedplanoption.split("-")[0];

    const Institute = {
      name: name,
      login: userId,
      status: "Open",
    };

    const IBasic = {
      name: name,
      trust: nameOfTrust,
      medium: medium,
      estYear: establishmentYear,
      lang1: firstLanguage,
      lang2: selectedSecondLanguage,
      lang3: thirdLanguage,
      address1: completeAddress,
      address2: address2,
      city: city,
      pin: pincode,
      dist: district,
      state: state,
      phone: landline,
      email: userId,
      princiNo: principalNumber,
      hrNo: hrNumber,
      afflType: affiliationType,
      afflNo: applicationNumber,
      udiceno: udisenumber,
      classrunFrom: classrunFrom,
      classrunTo: classrunTo,
      logo: logoPath,
      schoolPhoto: photoPath,
      introLink: videoURL,
      website: schoolWebsite,
      chairmanmsg: ChairmanmMessage,
      rrCopy: pdfPath,
      pfId: employeeIdPE,
      esiId: employeeIdESI,
      casualLeaves: clTeacherStaff,
      sickLeaves: clTeacherNonStaff,
      vacationPay: salaryvacation,
      noticePeriod: noticePeriod,
      originalCertificates: certificate,
      agreement: Agreement,
      agreementdur: agreementDuration
    };

    var IClass = classDetails.map((detail) => ({
      className: detail.className,
      division: detail.divisions,
      strengthPerDivision: detail.strength,
      fees: detail.fees,
    }));

    if (IClass !== null && IClass.length === 1 && IClass[0].className === '') {
      IClass = null;
    }

    const IFacility = selectedFacilities.map(facility => ({
      type: facility,
      value: "1",
    }));


    const IPreferBasic = salaryRanges.map((range, index) => ({
      course: selectedQualifications[index] && selectedQualifications[index][0] ? selectedQualifications[index][0] : '',
      salaryFrom: range.salaryFrom ? range.salaryFrom : 0,
      salaryTo: range.salaryTo ? range.salaryTo : 0,
      qual: selectedQualifications[index] ? selectedQualifications[index].join(',') : '',
    }));

    const IPlan = {
      plan: selectedPlan !== null ? selectedPlan.code : null,

    };


    const IPayment = {
      payType: selectedPlan ? 'UPI' : null,
      amount: selectedPlan ? selectedPlan.price : 0,
      comment: selectedPlan ? 'Payment Successful' : null,
      paymentChannel: selectedPlan ? 'Razorpay ' : null,
      referenceNumber: selectedPlan ? utrNumber : null,
    };


    const Authrozide = {
      name: userId,
      password: password,
      type: "i",
    };

    const AccountCreation = {
      institute: Institute,
      i_Basic: IBasic,
      i_Class: IClass,
      i_Facilities: IFacility,
      i_Pref_Basic: IPreferBasic,
      i_Plan: IPlan,
      i_IPayment: IPayment,
      auth: Authrozide,
    };

    const bearerToken = token ? token : userData.accessToken;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearerToken}`,

      },
    };

    try {

      var response = await axios.post(`${apiUrl}/createaccount/saveInst`, AccountCreation, config);

      if (response.data.cd !== 1) {
        setprofileCompletionPercentage(response.data.cd === '' ? 0 : response.data.cd)
        if (response.data.cd === 100) {
          setSnackbarMessage("Your account will be activated within 48 hours. Thank you for registering.");
          setSnackbarBgColor('green');
          setIsSuccess(true);
          setSnackbarOpen(true);
          accountActivate();
          handleroute();
        } else {
          setSnackbarMessage("Your draft account is saved successfully..");
          setSnackbarBgColor('green');
          setIsSuccess(true);
          setSnackbarOpen(true);
        }
      } else {
        setSnackbarMessage(
          response.data.description
        );
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
      }
    } catch (error) {


    }

  };


  const handleYearChange = (e) => {
    const inputYear = e.target.value;
    const isValidInput = /^\d*$/.test(inputYear); // Allow empty string or digits only
    setIsValid(isValidInput);

    if (isValidInput) {
      setEstablishmentYear(inputYear);
      setEstablishmentYearError('');
    } else {
      setEstablishmentYearError('Please enter a valid year.');
    }
  };



  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const handleUserIdChange = (e) => {
    const email = e.target.value.toLowerCase();
    setUserId(email);
    setUserIdError('');
    setIsOtpSent(false);
    setIsOtpVerified(false);
    setIsEmailVerified(false);
    setOtp('');
  };


  const isValidEmail = (email) => {
    // Simple email validation regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  useEffect(() => {
    let timer;
    if (isOtpSent && resendTimer > 0) {
      // Countdown logic
      timer = setInterval(() => {
        setResendTimer((prevTime) => prevTime - 1);
      }, 1000);
    }
    if (resendTimer === 0) {
      clearInterval(timer); // Stop the timer once it hits 0
    }
    return () => clearInterval(timer); // Cleanup on unmount
  }, [isOtpSent, resendTimer]);

  const sendEmailOTP = async () => {
    setIsOtpSent(true);
    setSnackbarMessage("OTP sent successfully");
    setSnackbarBgColor('green');
    setIsSuccess(true);
    setSnackbarOpen(true);
    setUserIdError('');
    setResendTimer(180);
    try {
      const response = await axios.post(`${apiUrl}/otp/generateemailotp`, {
        email: userId,
      });

      if (response.data.cd === 0) {
        setTimeout(() => {
          setSnackbarMessage("Special Note: If you don't see the OTP in your inbox, please check your Spam or Junk folder.");
          setSnackbarBgColor('green');
          setIsSuccess(true);
          setSnackbarOpen(true);
        }, 3000); // Delay to show this message after the OTP success message

      } else {
        setSnackbarMessage('Failed to send OTP');
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while sending OTP");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
    }

  };
  const resendOtp = () => {
    sendEmailOTP();
  };

  const handleVerifyEmailOtp = async () => {
    try {
      if (otp.length !== 6) {
        setOtpError('Please enter a 6-digit OTP');
        return;
      }
      const response = await axios.post(`${apiUrl}/otp/verifyemailotp`, {
        email: userId,
        otp: otp,
      });
      if (response.data.cd === 0) {
        setIsOtpSent(true);
        setSnackbarMessage('Email Varified Sucessfull');
        setSnackbarBgColor('green');
        setIsSuccess(true);
        setSnackbarOpen(true);
        setIsOtpVerified(true);
        setIsEmailVerified(true);
        setIsOtpSent(false); // Reset OTP sent state
        setOtpError('');
      } else {
        setOtpError(response.data.description);
      }

    } catch (error) {
      setSnackbarMessage('Error verifying OTP');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      // Handle error if needed
    }
  };

  useEffect(() => {
    let timer;

    // Only set up the timer if OTP has been sent and the timer is greater than 0
    if (isPhoneOtpSent && phoneResendTimer > 0) {
      timer = setInterval(() => {
        setPhoneResendTimer((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer); // Clear the interval when reaching 0
            return 0; // Ensure it doesn't go negative
          }
          return prevTime - 1; // Decrease the timer by 1 second
        });
      }, 1000);
    }

    return () => clearInterval(timer); // Cleanup on unmount
  }, [isPhoneOtpSent, phoneResendTimer]);


  const validatePhoneNumber = (number) => {
    // Check if the phone number is exactly 10 digits long
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(number);
  };


  const handleNumericChange = (value, setValue, setError) => {
    // Function to handle numeric input and validation
    const numericRegex = /^[0-9\b]+$/;
    if (value === '' || numericRegex.test(value)) {
      setValue(value);
      setError('');
    } else {
      setError('Please enter a valid phone number');
    }
  };

  const handleGetPhoneOtp = async () => {
    if (!validatePhoneNumber(landline)) {
      setLandlineError('Please enter a valid phone number');
      return;
    }

    if (captchaInput !== captchaCode) {
      setCaptchaError('Invalid captcha. Please try again.')
      setSnackbarMessage('Invalid captcha. Please try again.');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }

    try {
      await sendOTP(otp); // Send OTP
    } catch (error) {
      setPhoneOtpError('Failed to send OTP');
    }
  };

  const handleResendPhoneOtp = async () => {
    sendOTP();
  }

  const sendOTP = async () => {
    const url = `${apiUrl}/otp/sendphoneotp/${landline}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const responseBody = await response.json();

      if (response.ok && responseBody.cd === 0) {
        setIsPhoneOtpSent(true);
        setSnackbarMessage(responseBody.description);
        setSnackbarBgColor('green');
        setIsSuccess(true);
        setSnackbarOpen(true);
        setPhoneOtpError("");
        setPhoneResendTimer(180);
      } else {
        setSnackbarMessage(responseBody.description);
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
        setIsPhoneOtpSent(false);
        setPhoneOtpError(responseBody.description);
      }
    } catch (error) {
      setPhoneOtpError('Failed to send OTP');
    }
  };

  const handleReloadCaptcha = () => {
    setCaptchaCode(generateCaptcha()); // Generate a new captcha code
    setCaptchaInput(''); // Clear the input field
    setCaptchaError(''); // Clear any previous error
  };


  const handleVerifyPhoneOtp = async () => {
    try {
      if (Phoneotp.length !== 6) {
        setPhoneOtpError('Please enter a 6-digit OTP');
        return;
      }
      const response = await axios.post(`${apiUrl}/otp/verifyephoneotp`, {
        phone: landline,
        otp: Phoneotp,
      });
      if (response.data.cd === 0) {
        setSnackbarMessage("Your phone number has been successfully verified!");
        setSnackbarBgColor('green');
        setIsSuccess(true);
        setSnackbarOpen(true);
        setIsPhoneOtpVerified(true);
        setIsPhoneVerified(true);
        setIsPhoneOtpSent(false);
        setPhoneOtp('');
        setPhoneOtpError('');
        setPhoneResendTimer(180);
      } else {
        setPhoneOtpError(response.data.description);
      }
    } catch (error) {
      setSnackbarMessage('Error verifying OTP');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
      // Handle error if needed
    }
  };


  return (
    <>
      <Container className="mt-1">
        <StepContainer currentStep={currentStep}>
          {stepsData.map((step) => (
            <StepWrapper key={step.step}>
              <StepStyle completed={currentStep >= step.step}>
                <StepCount>{step.step}</StepCount>
              </StepStyle>
              <StepsLabelContainer>
                <StepLabel completed={currentStep >= step.step}>
                  {step.label}
                </StepLabel>
              </StepsLabelContainer>
            </StepWrapper>
          ))}
        </StepContainer>
      </Container>
      <Container className="mt-6 d-flex justify-content-center">
        <ProfileCompletionProgressBar percentage={profileCompletionPercentage} />
      </Container>

      <Container className=" mt-3">

        <Navbar
          color={createAccountsuccess ? "success" : "primary"}
          light
          expand="md"
          style={{ marginTop: "10px", borderRadius: "10px" }}
          onClick={handleExpandCreateAccount}
        >
          <Container className="d-flex justify-content-between align-items-center">
            <NavbarBrand
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              <FontAwesomeIcon icon={faLock} style={{ marginRight: "1rem" }} />
              Login Details
            </NavbarBrand>
            <Nav navbar>
              <NavItem>
                <Button
                  color={createAccountsuccess ? "success" : "primary"}
                  onClick={handleExpandCreateAccount}
                  style={{
                    borderRadius: "50%",
                    width: "50px",
                    padding: "0",
                    margin: "0",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {expandedCreateAccount ? (
                    <FaMinus size={25} color="white" />
                  ) : (
                    createAccountsuccess ? (
                      <FaCheck size={25} color="white" />
                    ) : (
                      <FaPlus size={25} color="white" />
                    )
                  )}
                </Button>

              </NavItem>
            </Nav>
          </Container>
        </Navbar>

        <Card
          className={`bg-secondary shadow border-0 ${expandedCreateAccount ? "expanded" : "collapsed"
            }`}
        >
          {expandedCreateAccount && (
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup className="mb-3">
                  <Label for="name" className="font-weight-bold">
                    Institution Name <span className="required-asterisk">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="name"
                    placeholder="Enter Institution Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setnameError("");
                    }}
                    invalid={nameError !== ""}
                    style={{ color: "black", borderColor: nameError ? "red" : "" }}
                  />
                  <FormFeedback>{nameError}</FormFeedback>
                </FormGroup>
                <Card>
                  <CardBody>
                    <FormGroup>
                      <Label for="userId" className="font-weight-bold">
                        Email <span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="userId"
                        placeholder="Enter Email"
                        value={userId}
                        onChange={(e) => {
                          setUserId(e.target.value);
                          setUserIdError('');
                          setIsOtpSent(false); // Reset OTP sent state when email changes
                          setIsEmailVerified(false); // Reset OTP verified state when email changes
                          setOtp(''); // Clear OTP input
                          setResendTimer(180); // Reset the timer
                        }}
                        invalid={userIdError !== ''}
                        style={{ color: 'black', borderColor: userIdError ? 'red' : '' }}
                        disabled={isEmailVerified || profileCompletionPercentage > 0} // Disable the input if email is verified
                      />
                      <FormFeedback>{userIdError}</FormFeedback>
                    </FormGroup>

                    {/* Show Get OTP Button or Timer/Resend OTP Button */}
                    {!isEmailVerified && !isOtpSent && (
                      <Button
                        color="primary"
                        onClick={sendEmailOTP}
                        disabled={!isValidEmail(userId) || profileCompletionPercentage > 0} // Disable if email is not valid
                      >
                        Get OTP
                      </Button>
                    )}

                    {isOtpSent && !isEmailVerified && (
                      <>
                        {/* OTP Input Field and Verify Button (only show if resendTimer > 0) */}
                        {resendTimer > 0 && (
                          <>
                            <FormGroup>
                              <Label className="font-weight-bold">Enter OTP</Label>
                              <Input
                                type="text"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => {
                                  setOtp(e.target.value);
                                  setOtpError('');
                                }}
                                invalid={otpError !== ''}
                                style={{ color: 'black', borderColor: otpError ? 'red' : '' }}
                                disabled={resendTimer === 0} // Disable OTP field after 3 minutes
                              />
                              <FormFeedback>{otpError}</FormFeedback>
                            </FormGroup>

                            <Button
                              color="success"
                              className="mt-3"
                              onClick={handleVerifyEmailOtp}
                              disabled={otp.length !== 6 || resendTimer === 0} // Disable button if OTP is less than 6 digits or after 3 minutes
                            >
                              Verify OTP
                            </Button>
                          </>
                        )}

                        {/* Resend OTP Timer */}
                        {resendTimer > 0 ? (
                          <div className="mt-3">
                            <span style={{ color: 'red', fontWeight: 'bold' }}>
                              Resend OTP in {Math.floor(resendTimer / 60)}:{resendTimer % 60 < 10 ? '0' : ''}{resendTimer % 60}
                            </span>
                          </div>
                        ) : (
                          <Button color="danger" onClick={resendOtp} className="mt-3">
                            Resend OTP
                          </Button>
                        )}
                      </>
                    )}

                  </CardBody>
                </Card>
                <Row className="my-3">
                  <Col md="6">
                    <FormGroup>
                      <Label for="password" className="font-weight-bold">
                        Password <span className="required-asterisk">*</span>
                      </Label>
                      <div style={{ position: "relative" }}>
                        <Input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          placeholder="Enter Password"
                          value={password}
                          onChange={handlePasswordChange}
                          onCopy={(e) => e.preventDefault()}
                          invalid={passwordError !== ""}
                          style={{
                            color: "black",
                            borderColor: passwordError ? "red" : "",
                            paddingRight: "2.5rem",
                          }}
                        />
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                          onClick={togglePasswordVisibility}
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            zIndex: 1000,
                            color: "grey",
                          }}
                        />
                        {passwordError && (
                          <FormFeedback style={{ position: "absolute", top: "100%" }}>
                            {passwordError}
                          </FormFeedback>
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="6">

                    <FormGroup>
                      <Label for="confirmPassword" className="font-weight-bold">
                        Confirm Password <span className="required-asterisk">*</span>
                      </Label>
                      <div style={{ position: "relative" }}>
                        <Input
                          type={showConfirmPassword ? "text" : "password"}
                          id="confirmPassword"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setConfirmPasswordError("");
                          }}
                          onCopy={(e) => e.preventDefault()}
                          invalid={confirmPasswordError !== ""}
                          style={{
                            color: "black",
                            borderColor: confirmPasswordError ? "red" : "",
                            paddingRight: "2.5rem", // Ensure the icon doesn't overlap with the text
                          }}
                        />
                        <FontAwesomeIcon
                          icon={showConfirmPassword ? faEyeSlash : faEye}
                          onClick={toggleConfirmPasswordVisibility}
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            zIndex: 1000,
                            color: "grey",
                          }}
                        />
                        {confirmPasswordError && (
                          <FormFeedback style={{ position: "absolute", top: "100%" }}>
                            {confirmPasswordError}
                          </FormFeedback>
                        )}
                      </div>
                    </FormGroup>

                  </Col>
                </Row>

                <div className="text-center">
                  <Button
                    className="my-4"
                    color={profileCompletionPercentage > 0 ? "secondary" : "primary"}
                    type="button"
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    onClick={handleCreateAccount}
                    disabled={profileCompletionPercentage > 0}
                  >
                    Save and Continue
                  </Button>
                  <Button
                    className="my-4 ml-2 font-weight-bold"
                    color="danger"
                    type="button"
                    style={{ fontSize: "1.2rem" }}
                    onClick={handleCreateAccountReset}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            </CardBody>
          )}
        </Card>
        <Navbar
          color={instituteProfileSuccess ? "success" : "primary"}
          light
          expand="md"
          style={{ borderRadius: "10px", marginTop: "20px" }}
          onClick={handleExpand}
        >
          {/* Navbar content */}
          <Container className="d-flex justify-content-between align-items-center">
            <NavbarBrand
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              <i
                className="fas fa-user-circle"
                style={{ marginRight: "1rem" }}
              ></i>
              Institution Profile
            </NavbarBrand>
            <Nav navbar>
              <NavItem>
                <Button
                  color={instituteProfileSuccess ? "success" : "primary"}
                  onClick={handleExpand}
                  style={{
                    borderRadius: "50%",
                    width: "50px",
                    padding: "0",      // Remove padding
                    margin: "0",
                    height: "50px",
                    display: "flex",            // Added flex display
                    justifyContent: "center",   // Center horizontally
                    alignItems: "center",
                  }}
                >
                  {expanded ? (
                    <FaMinus size={25} color="white" />
                  ) : (
                    instituteProfileSuccess ? (
                      <FaCheck size={25} color="white" />
                    ) : (
                      <FaPlus size={25} color="white" />
                    )
                  )}
                </Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>

        {/* Content */}
        <Card
          className={`bg-secondary shadow border-0 ${expanded ? "expanded" : "collapsed"
            }`}
        >
          {expanded && (
            <CardBody className="px-lg-5 py-lg-5">
              {/* Form */}
              <Form role="form">
                <FormGroup className="mb-3">
                  <Label for="name" className="font-weight-bold">
                    Institution Name <span className="required-asterisk">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="name"
                    placeholder="Enter Institution name"
                    value={name}
                    readOnly
                    onChange={(e) => {
                      setName(e.target.value);
                      setnameError("");
                    }}
                    style={{ color: "black", borderColor: nameError ? "red" : "" }}
                  />

                  {nameError && <div style={{ color: "red" }}>{nameError}</div>}
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label for="nameOfTrust" className="font-weight-bold">
                    Name of Trust or Society <span className="required-asterisk">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="nameOfTrust"
                    placeholder="Enter the name of Trust or Society"
                    value={nameOfTrust}
                    onChange={(e) => {
                      setNameOfTrust(e.target.value);
                      setNameOfTrustError("");
                    }}
                    style={{ color: "black", borderColor: nameOfTrustError ? "red" : "" }}
                  />
                  {nameOfTrustError && (
                    <div style={{ color: "red" }}>{nameOfTrustError}</div>
                  )}
                </FormGroup>

                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="classrunfrom" className="font-weight-bold">
                        Classes Run From <span className="required-asterisk">*</span>
                      </Label>
                      <Select
                        name="classrunfrom"
                        id="classrunfrom"
                        value={classrunFrom ? ClassesFromOption.find(option => option.value === classrunFrom) : null}
                        // value={ClassesFromOption.find(option => option.value === classrunFrom)}
                        onChange={handleclassrunFromChange}
                        placeholder="Select class"
                        options={ClassesFromOption}
                        isClearable
                      />
                      {classroomFromError && (
                        <div style={{ color: "red" }}>{classroomFromError}</div>
                      )}
                    </FormGroup>
                  </Col>

                  <Col md={6}>

                    <FormGroup className="mb-3">
                      <Label for="classrunto" className="font-weight-bold">
                        Classes Run To <span className="required-asterisk">*</span>
                      </Label>
                      <Select

                        name="classrunto"
                        id="classrunto"
                        value={classrunTo ? ClassesToOption.find(option => option.value === classrunTo) : null}

                        onChange={handleclassrunToChange}
                        placeholder="Select Classes"
                        options={ClassesToOption}
                        isClearable

                      />

                      {classroomToError && (
                        <div style={{ color: "red" }}>{classroomToError}</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup className="mb-3">
                  <Label for="firstLanguage" className="font-weight-bold">
                    Select Languages Taught(Select Maximum 3 Languages) <span className="required-asterisk">*</span>
                  </Label>
                  <Select
                    isMulti
                    name="languages"
                    id="firstLanguage"
                    value={Langugeoptions.filter(option => selectedlanguages.includes(option.value))}
                    onChange={handlelanguagesChange}
                    placeholder="Select languages"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    maxMenuHeight={150}
                    isOptionDisabled={(option) => selectedlanguages.length >= 3 && !selectedlanguages.map(lang => lang.value).includes(option.lebel)}
                    options={Langugeoptions}
                  />
                  {firstLanguageError && (
                    <div style={{ color: "red" }}>{firstLanguageError}</div>
                  )}
                </FormGroup>


                <Row>
                  <Col md={6}>

                    <FormGroup className="mb-3">
                      <Label for="medium" className="font-weight-bold">
                        Medium <span className="required-asterisk">*</span>
                      </Label>
                      <Select
                        name="medium"
                        id="medium"
                        //value={mediumOptions.find(option => option.value === medium)}
                        value={medium ? mediumOptions.find(option => option.value === medium) : null}
                        onChange={handleMediumChange}
                        placeholder="Select medium"
                        options={mediumOptions}
                      />
                      {mediumError && (
                        <div style={{ color: "red" }}>{mediumError}</div>
                      )}
                    </FormGroup>
                  </Col>

                  <Col md={6}>

                    <FormGroup className="mb-3">
                      <Label for="affiliationType" className="font-weight-bold">
                        Affiliation Type
                      </Label>
                      <Select
                        name="affiliationType"
                        id="affiliationType"
                        value={affiliationType ? affiliationTypeOptions.find(option => option.value === affiliationType) : null}
                        onChange={handleAffiliationTypeChange}
                        placeholder="Select Affiliation Type"
                        options={affiliationTypeOptions}


                      />


                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup className="mb-3">
                  <Row>
                    <Col md={6}>
                      <Label
                        for="establishmentYear"
                        className="font-weight-bold"
                      >
                        Year of Establishment <span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="establishmentYear"
                        placeholder="Enter the year of establishment"
                        value={establishmentYear}
                        onChange={handleYearChange}
                        style={{
                          color: "black",
                          borderColor: establishmentYearError ? "red" : "",
                        }}
                      />
                      {establishmentYearError && (
                        <div style={{ color: "red" }}>
                          {establishmentYearError}
                        </div>
                      )}
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="userId" className="font-weight-bold">
                          Email <span className="required-asterisk">*</span>
                        </Label>
                        <Input
                          type="text"
                          id="userId"
                          placeholder="Enter Email"
                          value={userId}
                          readOnly
                          onChange={(e) => {
                            setUserId(e.target.value);
                            setUserIdError("");
                          }}
                          invalid={userIdError !== ""}
                          style={{ color: "black", borderColor: userIdError ? "red" : "" }}
                        />
                        {userIdError && (
                          <div style={{ color: "red" }}>{userIdError}</div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Card>
                    <CardBody>
                      <FormGroup className="mb-3">
                        <div className="d-flex align-items-center" style={{ gap: '15px' }}>
                          {/* Phone input */}
                          <div style={{ flex: 1 }}>
                            <Label for="landline" className="font-weight-bold">
                              Phone <span className="required-asterisk">*</span>
                            </Label>
                            <Input
                              type="text"
                              id="landline"
                              placeholder="Enter Phone number"
                              value={landline}
                              onChange={(e) => {
                                handleNumericChange(e.target.value, setLandline, setLandlineError);
                                setIsPhoneOtpSent(false); // Reset OTP sent state when phone changes
                                setIsPhoneOtpVerified(false); // Reset OTP verified state when phone changes
                                setPhoneOtp(''); // Reset OTP input
                                setPhoneResendTimer(180); // Reset timer to 180 seconds
                              }}
                              invalid={landlineError !== ''}
                              style={{ color: 'black', borderColor: landlineError ? 'red' : '' }}
                              disabled={isPhoneOtpVerified || phonevarifiedOnce} // Disable input if OTP is verified
                            />
                            {landlineError && <div style={{ color: 'red' }}>{landlineError}</div>}
                          </div>

                          {/* Captcha input and reload */}
                          <div style={{ flex: 1 }}>
                            <label htmlFor="captcha" style={{ fontWeight: 'bold' }}>
                              Captcha
                            </label>
                            <div className="d-flex align-items-center" style={{ gap: '10px' }}>
                              <Input
                                id="captcha"
                                type="text"
                                placeholder="Enter Captcha"
                                value={captchaInput}
                                onChange={(e) => {
                                  setCaptchaInput(e.target.value);
                                  setCaptchaError('');
                                }}
                                invalid={captchaError !== ''}
                                style={{ color: 'black', borderColor: captchaError ? 'red' : '' }}
                                disabled={isPhoneOtpSent} // Disable captcha if OTP is sent
                              />
                              <div
                                className="captcha-container d-flex align-items-center"
                                style={{
                                  border: '1px solid #ced4da',
                                  padding: '5px 10px',
                                  borderRadius: '5px',
                                  backgroundColor: 'yellow',
                                  userSelect: 'none',
                                }}
                              >
                                <small
                                  className="text-muted"
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                  }}
                                >
                                  {captchaCode}
                                </small>
                                <FontAwesomeIcon
                                  icon={faRedo}
                                  onClick={handleReloadCaptcha}
                                  style={{ cursor: 'pointer', marginLeft: '10px', color: 'black' }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </FormGroup>

                      {/* OTP Section */}
                      {isPhoneOtpSent && resendTimer > 0 && (
                        <>
                          <FormGroup>
                            <Label className="font-weight-bold">Enter OTP</Label>
                            <Input
                              type="text"
                              placeholder="Enter OTP"
                              value={Phoneotp}
                              onChange={(e) => {
                                setPhoneOtp(e.target.value);
                                setPhoneOtpError('');
                              }}
                              invalid={phoneOtpError !== ''}
                              style={{ color: 'black', borderColor: phoneOtpError ? 'red' : '' }}
                            />
                            {phoneOtpError && <div style={{ color: 'red' }}>{phoneOtpError}</div>}
                          </FormGroup>

                          <Button
                            color="success"
                            className="mt-3"
                            onClick={handleVerifyPhoneOtp}
                            disabled={Phoneotp.length !== 6} // Enable only when OTP is 6 digits
                          >
                            Verify OTP
                          </Button>
                        </>
                      )}

                      {/* Get OTP Button */}
                      {!isPhoneOtpSent && (
                        <Button
                          color="primary"
                          onClick={handleGetPhoneOtp}
                          disabled={!validatePhoneNumber(landline) || isPhoneOtpVerified || phonevarifiedOnce}
                        >
                          Get OTP
                        </Button>
                      )}

                      <div className="mt-3">
                        {isPhoneOtpSent && phoneResendTimer > 0 ? (
                          <span style={{ color: 'red', fontWeight: 'bold' }}>
                            Resend OTP in {Math.floor(phoneResendTimer / 60)}:{phoneResendTimer % 60 < 10 ? '0' : ''}{phoneResendTimer % 60}
                          </span>
                        ) : isPhoneOtpSent ? (
                          <Button color="danger" onClick={handleResendPhoneOtp} className="mt-3">
                            Resend OTP
                          </Button>
                        ) : null}
                      </div>

                    </CardBody>
                  </Card>

                </FormGroup>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="udisenumber" className="font-weight-bold">
                        U-DISE Number
                        <i
                          className="fa fa-question-circle"
                          aria-hidden="true"
                          onClick={() => {
                            setSnackbarMessage("Format of U-DISE Number: [01-001-12345]");
                            setSnackbarBgColor('green');
                            setIsSuccess(true);
                            setSnackbarOpen(true);
                          }}
                          style={{ color: 'blue', cursor: 'pointer', marginLeft: '10px' }}
                        ></i>
                      </Label>
                      <div style={{ display: 'flex' }}>
                        <Input
                          type="text"
                          id="udisenumber"
                          placeholder="Enter  U-DISE Number"
                          value={udisenumber}
                          onChange={(e) => {
                            setUdiseNumber(e.target.value);
                            setUdiseNumberError("");
                          }}
                          style={{
                            color: "black",
                            borderColor: udisenumberNumberError ? "red" : "",
                            marginRight: '10px', // Adjust spacing between input field and button
                          }}
                        />


                      </div>
                      {udisenumberNumberError && (
                        <div style={{ color: "red" }}>
                          {udisenumberNumberError}
                        </div>
                      )}

                    </FormGroup>

                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label
                        for="applicationNumber"
                        className="font-weight-bold"
                      >
                        Affiliation Number <span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="applicationNumber"
                        placeholder="Enter your affiliation number"
                        value={applicationNumber}
                        onChange={(e) => {
                          setApplicationNumber(e.target.value);
                          setApplicationNumberError("");
                        }}
                        style={{
                          color: "black",
                          borderColor: applicationNumberError ? "red" : "",
                        }}
                      />
                      {applicationNumberError && (
                        <div style={{ color: "red" }}>
                          {applicationNumberError}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="principal" className="font-weight-bold">
                        Principal Phone Number <span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="principal"
                        placeholder="Enter your Principal Phone Number"
                        value={principalNumber}
                        onChange={handlePrincipalChange}
                        style={{
                          color: "black",
                          borderColor: principalNumberError ? "red" : "",
                        }}
                      />
                      {principalNumberError && (
                        <div style={{ color: "red" }}>
                          {principalNumberError}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="hrnumber" className="font-weight-bold">
                        HR Phone Number <span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="Numaric"
                        id="hrnumber"
                        placeholder="Enter your HR Phone Number"
                        value={hrNumber}
                        onChange={handleHrNumberChange}
                        style={{ color: "black", borderColor: hrNumberError ? "red" : "" }}
                      />
                      {hrNumberError && (
                        <div style={{ color: "red" }}>{hrNumberError}</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup className="mb-3">
                  <Label for="completeAddress" className="font-weight-bold">
                    Address1 <span className="required-asterisk">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="completeAddress"
                    placeholder="Enter the complete address"
                    value={completeAddress}
                    onChange={(e) => {
                      setCompleteAddress(e.target.value);
                      setCompleteAddressError("");
                    }}
                    style={{ color: "black", borderColor: completeAddressError ? "red" : "" }}
                  />
                  {completeAddressError && (
                    <div style={{ color: "red" }}>{completeAddressError}</div>
                  )}
                </FormGroup>
                <FormGroup className="mb-3">
                  <Label for="completeAddress" className="font-weight-bold">
                    Address2
                  </Label>
                  <Input
                    type="text"
                    id="completeAddress"
                    placeholder="Enter the complete address"
                    value={address2}
                    onChange={(e) => {
                      setAddress2(e.target.value);
                      setAddress2Error("");
                    }}
                    style={{ color: "black", borderColor: address2Error ? "red" : "" }}
                  />
                  {address2Error && (
                    <div style={{ color: "red" }}>{address2Error}</div>
                  )}
                </FormGroup>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="pincode" className="font-weight-bold">
                        Pin Code <span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="pincode"
                        placeholder="Enter your pin code"
                        value={pincode}
                        onChange={handlePincodeChange}
                        style={{ color: "black", borderColor: pincodeError ? "red" : "" }}
                      />
                      {pincodeError && (
                        <div style={{ color: "red" }}>{pincodeError}</div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="city" className="font-weight-bold">
                        City <span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="city"
                        value={city}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="district" className="font-weight-bold">
                        District <span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="district"
                        value={district}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="state" className="font-weight-bold">
                        State <span className="required-asterisk">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="state"
                        value={state}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="text-center">
                  <Button
                    className="my-4 font-weight-bold"
                    color="primary"
                    type="button"
                    style={{ fontSize: "1.2rem" }}
                    onClick={() => {
                      handleInstitutionProfile();
                    }}
                  >
                    Save and Continue
                  </Button>
                  <Button
                    className="my-4 ml-2 font-weight-bold"
                    color="danger"
                    type="button"
                    style={{ fontSize: "1.2rem" }}
                    onClick={handleInstitutionProfileReset}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            </CardBody>
          )}
        </Card>
        <Navbar
          color={additionalDetailsSuccess ? "success" : "primary"}
          light
          expand="md"
          style={{ marginTop: "20px", borderRadius: "10px" }}
          onClick={handleExpandAdditionalDetails}
        >
          <Container className="d-flex justify-content-between align-items-center">
            <NavbarBrand
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              <i
                className="fas fa-info-circle"
                style={{ marginRight: "1rem" }}
              ></i>
              Additional Details
            </NavbarBrand>
            <Nav navbar>
              <NavItem>
                <Button
                  color={additionalDetailsSuccess ? "success" : "primary"}
                  onClick={handleExpandAdditionalDetails}
                  style={{
                    borderRadius: "50%",
                    width: "50px",
                    padding: "0",      // Remove padding
                    margin: "0",
                    height: "50px",
                    display: "flex",            // Added flex display
                    justifyContent: "center",   // Center horizontally
                    alignItems: "center",
                  }}
                >
                  {expandedAdditionalDetails ? (
                    <FaMinus size={25} color="white" />
                  ) : (
                    additionalDetailsSuccess ? (
                      <FaCheck size={25} color="white" />
                    ) : (
                      <FaPlus size={25} color="white" />
                    )
                  )}
                </Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>

        {/* Content for "Additional Details" */}
        <Card
          className={`bg-secondary shadow border-0 ${expandedAdditionalDetails ? "expanded" : "collapsed"
            }`}
        >
          {expandedAdditionalDetails && (
            <CardBody className="px-lg-5 py-lg-5">
              {/* Additional Details Form */}
              <Form role="form">
                <Card>
                  <FormGroup className="mb-3">
                    <Table striped bordered hover className="mt-3">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Class <span className="required-asterisk">*</span></b>
                          </TableCell>
                          <TableCell>
                            <b>No of Division <span className="required-asterisk">*</span></b>
                          </TableCell>
                          <TableCell>
                            <b>Total Strength <span className="required-asterisk">*</span></b>
                          </TableCell>
                          <TableCell>
                            <b>Tuition Fees (₹) <span className="required-asterisk">*</span></b>
                          </TableCell>
                          <TableCell style={{ width: "23%" }}>
                            <b>Actions</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {classDetails.map((detail, index) => (
                          <TableRow key={index} >
                            <TableCell>
                              <Input
                                type="text"
                                placeholder="Enter class name"
                                value={detail.className}
                                onChange={(e) =>
                                  setClassDetails((prevClassDetails) =>
                                    updateClassDetail(
                                      prevClassDetails,
                                      index,
                                      "className",
                                      e.target.value
                                    )
                                  )
                                }
                                style={{ color: "black" }}
                              />
                            </TableCell>
                            <TableCell>
                              <Input
                                type="text"
                                placeholder="Enter divisions"
                                value={detail.divisions}
                                onChange={(e) =>
                                  setClassDetails((prevClassDetails) =>
                                    updateClassDetail(
                                      prevClassDetails,
                                      index,
                                      "divisions",
                                      e.target.value
                                    )
                                  )
                                }
                                style={{ color: "black" }}
                              />
                            </TableCell>
                            <TableCell>
                              <Input
                                type="text"
                                placeholder="Enter strength"
                                value={detail.strength}
                                onChange={(e) =>
                                  setClassDetails((prevClassDetails) =>
                                    updateClassDetail(
                                      prevClassDetails,
                                      index,
                                      "strength",
                                      e.target.value
                                    )
                                  )
                                }
                                style={{ color: "black" }}
                              />
                            </TableCell>
                            <TableCell>
                              <Input
                                type="text"
                                placeholder="Enter fees"
                                value={detail.fees}
                                onChange={(e) =>
                                  setClassDetails((prevClassDetails) =>
                                    updateClassDetail(
                                      prevClassDetails,
                                      index,
                                      "fees",
                                      e.target.value
                                    )
                                  )
                                }
                                style={{ color: "black" }}
                              />
                            </TableCell>
                            <TableCell>
                              {index > 0 && (
                                <Button
                                  onClick={() => handleRemoveClassRow(index)}
                                  color="danger"
                                  aria-label="remove row"
                                >
                                  Remove
                                </Button>
                              )}
                              <Button
                                onClick={() => handleAddClassRow()}
                                color="primary"
                                aria-label="add row"
                              >
                                Add
                              </Button>

                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </FormGroup>
                </Card>

                {/* Confirmation Dialog */}
                <Dialog
                  open={isConfirmationDialogOpen}
                  onClose={handleCancelRemove}
                >
                  <DialogTitle style={{ color: 'black', fontWeight: 'bold' }}>Confirm Removal</DialogTitle>

                  <DialogContent>
                    Are you sure you want to remove this row?
                  </DialogContent>
                  <DialogActions>
                    <button
                      tabIndex="0"
                      className="cancel-remove-styled-button"
                      onClick={handleCancelRemove}
                    >
                      Cancel
                    </button>

                    <button
                      tabIndex="1"
                      className="cancel-remove-styled-button"
                      onClick={handleConfirmRemove}
                    >
                      Remove
                    </button>
                  </DialogActions>
                </Dialog>

                <Card>
                  <CardBody className="px-lg-5 py-lg-5">
                    <FormGroup className="mb-3">
                      <Label for="facilities" className="font-weight-bold">
                        Facilities
                      </Label>
                      <Select
                        isMulti
                        name="facilities"
                        id="facilities"
                        value={facilitiesOptions.filter(option => selectedFacilities.includes(option.value))}
                        onChange={handleFacilitiesChange}
                        options={facilitiesOptions}
                        placeholder="Select Facilities"
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                      />
                    </FormGroup>
                  </CardBody>
                </Card>

                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    style={{ fontSize: "1.2rem" }}
                    onClick={() => {
                      handleAdditionalDetails();
                    }}
                  >
                    Save and Continue
                  </Button>
                  <Button
                    className="my-4 ml-2 font-weight-bold"
                    color="danger"
                    type="button"
                    style={{ fontSize: "1.2rem" }}
                    onClick={handleAdditionalDetailsReset}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            </CardBody>
          )}
        </Card>
        <Navbar
          color={uploadDocumentsSuccess ? "success" : "primary"}
          light
          expand="md"
          style={{ marginTop: "20px", borderRadius: "10px" }}
          onClick={handleExpandUploadDocuments}
        >
          <Container className="d-flex justify-content-between align-items-center">
            <NavbarBrand
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              <i
                className="fas fa-file-upload"
                style={{ marginRight: "1rem" }}
              ></i>
              Upload Documents
            </NavbarBrand>
            <Nav navbar>
              <NavItem>
                <Button
                  color={uploadDocumentsSuccess ? "success" : "primary"}
                  onClick={handleExpandUploadDocuments}
                  style={{
                    borderRadius: "50%",
                    width: "50px",
                    padding: "0",      // Remove padding
                    margin: "0",
                    height: "50px",
                    display: "flex",            // Added flex display
                    justifyContent: "center",   // Center horizontally
                    alignItems: "center",
                  }}
                >
                  {expandedUploadDocuments ? (
                    <FaMinus size={25} color="white" />
                  ) : (
                    uploadDocumentsSuccess ? (
                      <FaCheck size={25} color="white" />
                    ) : (
                      <FaPlus size={25} color="white" />
                    )
                  )}
                </Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>

        {/* Content for "Upload Documents" */}
        <Card
          className={`bg-secondary shadow border-0 ${expandedUploadDocuments ? "expanded" : "collapsed"
            }`}
        >
          {expandedUploadDocuments && (
            <CardBody className="px-lg-5 py-lg-5">
              {/* Upload Documents Form */}
              <Form role="form">
                <Row>
                  <Col md={6}>
                    {/* Logo Upload */}
                    <FormGroup className="mb-3 border rounded p-3 bg-light">
                      <Label for="logoUpload" className="font-weight-bold">
                        Upload Logo (PNG and JPG) <span className="required-asterisk">*</span>
                        <div className="custom-file">
                          <Input
                            type="file"
                            className="custom-file-input"
                            id="logoUpload"
                            accept=".png, .jpg, .jpeg"
                            onChange={(e) => handleLogoUpload(e.target.files)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="logoUpload"
                          >
                            <FaUpload className="mr-2" /> Choose file
                          </label>
                        </div>
                      </Label>
                    </FormGroup>
                    {logoPreview && (
                      <div className="mt-3 p-3 border rounded bg-light">
                        <p className="font-weight-bold">
                          Uploaded Logo Preview:
                        </p>
                        <div
                          style={{
                            width: "150px",
                            height: "150px",
                            overflow: "hidden",
                            border: "1px solid #ddd",
                          }}
                        >
                          <img
                            src={logoPreview}
                            alt="Logo Preview"
                            style={{ width: "100%", height: "auto" }}
                          />
                        </div>
                      </div>
                    )}

                    {/* Photo Upload */}
                    <FormGroup className="mb-3 border rounded p-3 bg-light">
                      <Label for="photoUpload" className="font-weight-bold">
                        School Building Front View <span className="required-asterisk">*</span>
                        <div className="custom-file">
                          <Input
                            type="file"
                            className="custom-file-input"
                            id="photoUpload"
                            accept=".jpg, .jpeg, .png"
                            onChange={(e) => handlePhotoUpload(e.target.files)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="photoUpload"
                          >
                            <FaUpload className="mr-2" /> Choose file
                          </label>
                        </div>
                      </Label>
                    </FormGroup>
                    {photoPreview && (
                      <div className="mt-3 p-3 border rounded bg-light">
                        <p className="font-weight-bold">
                          Uploaded Photo Preview:
                        </p>
                        <div
                          style={{
                            width: "150px",
                            height: "150px",
                            overflow: "hidden",
                            border: "1px solid #ddd",
                          }}
                        >
                          <img
                            src={photoPreview}
                            alt="Photo Preview"
                            style={{ width: "100%", height: "auto" }}
                          />
                        </div>
                      </div>
                    )}

                  </Col>

                  <Col md={6}>
                    {/* Application Copy PDF Upload */}
                    <FormGroup className="mb-3 border rounded p-3 bg-light">
                      <Label
                        for="applicationCopyPDF"
                        className="font-weight-bold"
                      >
                        Attach RR/Affiliation Copy (PDF) <span className="required-asterisk">*</span>
                      </Label>
                      <div className="custom-file">
                        <Input
                          type="file"
                          id="applicationCopyPDF"
                          accept=".pdf"
                          onChange={(e) =>
                            handleApplicationCopyPDFUpload(e.target.files)
                          }
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="applicationCopyPDF"
                        >
                          <FaUpload className="mr-2" /> Choose file
                        </label>
                      </div>
                    </FormGroup>
                    {pdfLink && (
                      <div className="mt-3 p-3 border rounded bg-light">
                        <p className="font-weight-bold">PDF Link:</p>
                        <a
                          href={pdfLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "blue", // Change to your desired link color
                            textDecoration: "underline", // Add underline to the link
                          }}
                        >
                          {pdfLink}
                        </a>
                      </div>
                    )}


                  </Col>
                </Row>

                {/* School Website */}
                <FormGroup className="mb-3">
                  <Label for="schoolWebsite" className="font-weight-bold">
                    School Website <span className="required-asterisk">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="schoolWebsite"
                    placeholder="Enter School Website"
                    value={schoolWebsite}
                    onChange={(e) => {
                      setSchoolWebsite(e.target.value);
                      setschoolWebsiteError("");
                    }}
                    style={{ color: "black", borderColor: schoolWebsiteError ? "red" : "" }}
                  />
                  {schoolWebsiteError && (
                    <div style={{ color: "red" }}>{schoolWebsiteError}</div>
                  )}
                </FormGroup>

                {/* School Website */}
                <FormGroup className="mb-3">
                  <Label for="schoolWebsite" className="font-weight-bold">
                    School Premise's Youtube Video Link <span className="required-asterisk">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="schoolWebsite"
                    placeholder="Enter School Premise's Youtube Video Link"
                    value={videoURL}
                    onChange={(e) => {
                      setVideourl(e.target.value);
                      setvideoURLError("");
                    }}
                    style={{ color: "black", borderColor: videoURLError ? "red" : "" }}
                  />

                  {videoURLError && (
                    <div style={{ color: "red" }}>{videoURLError}</div>
                  )}
                </FormGroup>

                {/* School Website */}
                <FormGroup className="mb-3">
                  <Label for="youtubeVideo" className="font-weight-bold">
                    Principal/Chairman's Message - Youtube Video Link <span className="required-asterisk">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="youtubeVideo"
                    placeholder="Enter Principal/Chairman's Message - Youtube Video Link"
                    value={ChairmanmMessage}
                    onChange={(e) => {
                      setChairmanmMessage(e.target.value);
                      setChairmanmMessageError("");
                    }}
                    style={{ color: "black", borderColor: ChairmanmMessageError ? "red" : "" }}
                  />
                  {ChairmanmMessageError && (
                    <div style={{ color: "red" }}>{ChairmanmMessageError}</div>
                  )}
                </FormGroup>


                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    onClick={handleupload}
                  >
                    <FaUpload className="mr-2" /> Upload
                  </Button>
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    onClick={() => {
                      handleNextUploadDocument();
                    }}
                  >
                    Save and Continue
                  </Button>
                  <Button
                    className="my-4 mx-2"
                    color="danger"
                    type="button"
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    onClick={handleUploadDocumentReset}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            </CardBody>
          )}
        </Card>

        {/* Navbar for "Salary Offered" */}
        <Navbar
          color={employmentStrategiesSuccess ? "success" : "primary"}
          light
          expand="md"
          style={{ marginTop: "20px", borderRadius: "10px" }}
          onClick={handleExpandSalaryOffered}
        >
          <Container className="d-flex justify-content-between align-items-center">
            <NavbarBrand
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              <i
                className="fas fa-briefcase"
                style={{ marginRight: "1rem" }}
              ></i>
              Employment Strategies
            </NavbarBrand>
            <Nav navbar>
              <NavItem>
                <Button
                  color={employmentStrategiesSuccess ? "success" : "primary"}
                  onClick={handleExpandSalaryOffered}
                  style={{
                    borderRadius: "50%",
                    width: "50px",
                    padding: "0",      // Remove padding
                    margin: "0",
                    height: "50px",
                    display: "flex",            // Added flex display
                    justifyContent: "center",   // Center horizontally
                    alignItems: "center",
                  }}
                >
                  {expandedSalaryOffered ? (
                    <FaMinus size={25} color="white" />
                  ) : (
                    employmentStrategiesSuccess ? (
                      <FaCheck size={25} color="white" />
                    ) : (
                      <FaPlus size={25} color="white" />
                    )
                  )}
                </Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>

        {/* Content for "Salary Offered" */}
        <Card
          className={`bg-secondary shadow border-0 ${expandedSalaryOffered ? "expanded" : "collapsed"
            }`}
        >
          {expandedSalaryOffered && (
            <CardBody className="px-lg-5 py-lg-5">
              {/* Salary Offered Form */}
              <Form role="form">
                {/* Add your form fields for salary offered here... */}


                <Table style={{ border: 'none' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "25%" }}>
                        <b>Qualification <span className="required-asterisk">*</span></b>
                      </TableCell>
                      <TableCell style={{ width: "25%" }}>
                        <b>Minimum Salary (Per Month) <span className="required-asterisk">*</span></b>
                      </TableCell>
                      <TableCell style={{ width: "25%" }}>
                        <b>Maximum Salary (Per Month) <span className="required-asterisk">*</span></b>
                      </TableCell>
                      <TableCell style={{ width: "25%" }}>
                        <b>Actions</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {salaryRanges.map((range, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ width: "25%", minWidth: "200px" }}>
                          <Select
                            name={`qualification_${index}`}
                            id={`qualification_${index}`}
                            value={
                              selectedQualifications[index]
                                ? selectedQualifications[index].map((qual) =>
                                  qualificationOptions.find((option) => option.value === qual)
                                )
                                : []
                            }
                            onChange={(selectedOptions) =>
                              handleQualificationChange(index, selectedOptions.map((opt) => opt.value))
                            }
                            placeholder="Select Qualification"
                            options={qualificationOptions}
                            isMulti
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                minHeight: "42px",
                                fontSize: "16px",
                              }),
                              indicatorSeparator: (provided, state) => ({
                                ...provided,
                                display: "none",
                              }),
                            }}
                          />

                        </TableCell>
                        <TableCell>
                          <Input

                            value={range.salaryFrom}
                            onChange={(e) =>
                              handleSalaryChange(
                                index,
                                "salaryFrom",
                                e.target.value
                              )
                            }
                            style={{ color: "black", }}
                            placeholder="Enter minimum salary"

                          />
                        </TableCell>
                        <TableCell>
                          <Input

                            value={range.salaryTo}
                            onChange={(e) =>
                              handleSalaryChange(
                                index,
                                "salaryTo",
                                e.target.value
                              )
                            }
                            style={{ color: "black", }}
                            placeholder="Enter maximum salary"

                          />
                        </TableCell>
                        <TableCell>
                          {index === salaryRanges.length - 1 && (
                            <FaPlus
                              size={25}
                              color="green"
                              onClick={() => handleAddRow()}
                              style={{ marginRight: '10px' }}
                            />
                          )}

                          {index !== 0 && (
                            <FaMinus
                              size={25}
                              color="red"
                              onClick={() => handleRemoveRow(index)}
                              style={{ marginLeft: '10px' }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>



                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    onClick={() => {
                      handleEmploymentStrategies();
                    }}
                  >
                    Save and Continue
                  </Button>
                  <Button
                    className="my-4 mx-2"
                    color="danger"
                    type="button"
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    onClick={handleEmploymentStrategiesReset}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            </CardBody>
          )}
        </Card>

        <Dialog
          open={isEmployeeConfirmationDialogOpen}
          onClose={handleCancelRemove}
        >
          <DialogTitle style={{ color: 'black', fontWeight: 'bold' }}>Confirm Removal</DialogTitle>
          <DialogContent>
            Are you sure you want to remove this row?
          </DialogContent>
          <DialogActions>
            <button
              tabIndex="0"
              className="cancel-remove-styled-button"
              onClick={handleCancelEmployeeRemove}
            >
              Cancel
            </button>

            <button
              tabIndex="1"
              className="cancel-remove-styled-button"
              onClick={handleConfirmEmployeeRemove}
            >
              Remove
            </button>
          </DialogActions>
        </Dialog>

        {/* Navbar for "Job Security" */}
        <Navbar
          color={jobSecuritySuccess ? "success" : "primary"}
          light
          expand="md"
          style={{ marginTop: "20px", borderRadius: "10px" }}
          onClick={handleExpandJobSecurity}
        >
          <Container className="d-flex justify-content-between align-items-center">
            <NavbarBrand
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              <i className="fas fa-lock" style={{ marginRight: "1rem" }}></i>
              Job Security
            </NavbarBrand>
            <Nav navbar>
              <NavItem>
                <Button
                  color={jobSecuritySuccess ? "success" : "primary"}
                  onClick={handleExpandJobSecurity}
                  style={{
                    borderRadius: "50%",
                    width: "50px",
                    padding: "0",      // Remove padding
                    margin: "0",
                    height: "50px",
                    display: "flex",            // Added flex display
                    justifyContent: "center",   // Center horizontally
                    alignItems: "center",
                  }}
                >
                  {expandedJobSecurity ? (
                    <FaMinus size={25} color="white" />
                  ) : (
                    jobSecuritySuccess ? (
                      <FaCheck size={25} color="white" />
                    ) : (
                      <FaPlus size={25} color="white" />
                    )
                  )}
                </Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>

        {/* Content for "Job Security" */}
        <Card
          className={`bg-secondary shadow border-0 ${expandedJobSecurity ? "expanded" : "collapsed"
            }`}
        >
          {expandedJobSecurity && (
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                {/* Row 1: Normal Textfields */}
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="employeeIdPE" className="font-weight-bold">
                        PF Number
                      </Label>
                      <Input
                        type="text"
                        id="employeeIdPE"
                        placeholder="Enter PF number"
                        value={employeeIdPE}
                        onChange={(e) => {
                          setEmployeeIdPE(e.target.value);

                        }}
                        style={{ color: "black", }}

                      />

                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="employeeIdESI" className="font-weight-bold">
                        ESI Number
                      </Label>
                      <Input
                        type="text"
                        id="employeeIdESI"
                        placeholder="Enter ESI number"
                        value={employeeIdESI}
                        onChange={(e) => {
                          setEmployeeIdESI(e.target.value);
                          setEmployeeIdESIError("");
                        }}
                        style={{ color: "black", borderColor: employeeIdESIError ? "red" : "" }}
                      />
                      {employeeIdESIError && (
                        <div style={{ color: "red" }}>{employeeIdESIError}</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                {/* Row 2: Normal Textfields */}
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="clTeacherStaff" className="font-weight-bold">
                        Casual Leaves per year
                      </Label>
                      <Input
                        type="Number"
                        min="1"
                        id="clTeacherStaff"
                        placeholder="Enter the number of CL provided to teacher staff per year"
                        value={clTeacherStaff}
                        onChange={(e) => {
                          const inputValue = parseInt(e.target.value, 10);

                          if (
                            !isNaN(inputValue) &&
                            inputValue >= 0 &&
                            inputValue <= 365
                          ) {
                            setClTeacherStaff(inputValue);
                            setClTeacherStaffError("");
                          }
                        }}
                        style={{
                          color: "black",
                          borderColor: clTeacherStaffError ? "red" : "",
                        }}
                      />

                      {clTeacherStaffError && (
                        <div style={{ color: "red" }}>
                          {clTeacherStaffError}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label
                        for="clTeacherNonStaff"
                        className="font-weight-bold"
                      >
                        Sick Leaves per year
                      </Label>
                      <Input
                        type="Number"
                        min="0"
                        id="clTeacherNonStaff"
                        placeholder="Enter the number of SL provided to teacher staff per year"
                        value={clTeacherNonStaff}
                        onChange={(e) => {
                          const inputValue = parseInt(e.target.value, 10);

                          if (
                            !isNaN(inputValue) &&
                            inputValue >= 0 &&
                            inputValue <= 365
                          ) {
                            setClTeacherNonStaff(inputValue);
                            setClTeacherNonStaffError("");
                          }
                        }}
                        style={{
                          color: "black",
                          borderColor: clTeacherNonStaffError ? "red" : "",
                        }}
                      />

                      {clTeacherNonStaffError && (
                        <div style={{ color: "red" }}>
                          {clTeacherNonStaffError}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup className="mb-3">
                  <Label for="noticePeriod" className="font-weight-bold">
                    Notice Period in Months
                  </Label>
                  <Input
                    type="text"
                    id="noticePeriod"
                    placeholder="Enter notice period"
                    value={noticePeriod}
                    onChange={(e) => {
                      handleNoticePeriodChange(e);
                    }}
                    style={{ color: "black", borderColor: noticePeriodError ? "red" : "" }}
                  />
                  {noticePeriodError && (
                    <div style={{ color: "red" }}>{noticePeriodError}</div>
                  )}
                </FormGroup>

                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="originalCertificates" className="font-weight-bold">
                        Original Certificates To be Submitted? <span className="required-asterisk">*</span>
                      </Label>
                      <Select
                        name="originalCertificates"
                        id="originalCertificates"
                        value={originalCertificates ? salaryDuringVacationOptions.find(option => option.value === originalCertificates) : null}
                        // value={salaryDuringVacationOptions.find(option => option.value === originalCertificates)}
                        onChange={handleOriginalCertificatesChange}
                        styles={{ color: "black", }}
                        placeholder="Select Option"
                        options={salaryDuringVacationOptions}
                      />
                      {originalCertificatesError && (
                        <div style={{ color: "red" }}>{originalCertificatesError}</div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-3">
                      <Label for="showSalaryDuringVacation" className="font-weight-bold">
                        Salary Paid During Vacation? <span className="required-asterisk">*</span>
                      </Label>
                      <Select
                        name="showSalaryDuringVacation"
                        id="showSalaryDuringVacation"
                        value={showSalaryDuringVacation ? salaryDuringVacationOptions.find(option => option.value === showSalaryDuringVacation) : null}
                        // value={salaryDuringVacationOptions.find(option => option.value === showSalaryDuringVacation)}
                        onChange={handleSalaryDuringVacationChange}
                        styles={{ color: "black", }}
                        placeholder="Select Option"
                        options={salaryDuringVacationOptions}
                      />
                      {showSalaryDuringVacationError && (
                        <div style={{ color: "red" }}>{showSalaryDuringVacationError}</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup className="mb-3" style={{ display: "flex" }}>
                  <div style={{ flex: "1", marginRight: "10px" }}>
                    <Label for="agreement" className="font-weight-bold">
                      Agreement of Service? <span className="required-asterisk">*</span>
                    </Label>
                    <Select
                      name="agreement"
                      id="agreement"
                      // value={salaryDuringVacationOptions.find(option => option.value === agreement)}
                      value={agreement ? salaryDuringVacationOptions.find(option => option.value === agreement) : null}
                      onChange={handleAgreementChange}
                      styles={{ color: "black", }}
                      placeholder="Select Option"
                      options={salaryDuringVacationOptions}
                    />
                    {agreementError && (
                      <div style={{ color: "red" }}>{agreementError}</div>
                    )}
                  </div>

                  {agreement === "Yes" && (
                    <div style={{ flex: "1" }}>
                      <Label
                        for="agreementDuration"
                        className="font-weight-bold"
                      >
                        Agreement Duration in Months
                      </Label>
                      <Input
                        type="text"

                        id="agreementDuration"
                        placeholder="Enter Agreement Duration"
                        value={agreementDuration}
                        onChange={(e) => {
                          handleAgreementDurationChange(e);
                        }}
                        style={{ color: "black", borderColor: agreementDurationError ? "red" : "" }}
                      />
                      {agreementDurationError && (
                        <div style={{ color: "red" }}>
                          {agreementDurationError}
                        </div>
                      )}
                    </div>
                  )}
                </FormGroup>

                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    onClick={() => {
                      handleNextJobSecurity(6);
                    }}
                  >
                    Save and Continue
                  </Button>
                  <Button
                    className="my-4 mx-2"
                    color="danger"
                    type="button"
                    style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                    onClick={handleJobSecurityReset}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            </CardBody>
          )}
        </Card>
        <div>
          <Navbar color="primary" light expand="md" style={{ marginTop: '20px', borderRadius: '10px' }}>
            <Container className="d-flex justify-content-between align-items-center">
              <NavbarBrand style={{ color: 'white', fontWeight: 'bold', fontSize: '1.5rem' }}>
                <i className="fas fa-cogs" style={{ marginRight: '1rem' }}></i>
                Subscription Plans
              </NavbarBrand>
              <Nav navbar>
                <NavItem>
                  <Button
                    color={subscriptionPlansSuccess ? "success" : "primary"}
                    onClick={handleExpandPlan}
                    style={{
                      borderRadius: "50%",
                      width: "50px",
                      padding: "0",
                      margin: "0",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {expandedPlan ? (
                      <FaMinus size={25} color="white" />
                    ) : (
                      subscriptionPlansSuccess ? (
                        <FaCheck size={25} color="white" />
                      ) : (
                        <FaPlus size={25} color="white" />
                      )
                    )}
                  </Button>
                </NavItem>
              </Nav>
            </Container>
          </Navbar>

          <Card className={`bg-secondary shadow border-0 ${expandedPlan ? 'expanded' : 'collapsed'}`}>
            {expandedPlan && (
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <div>
                    <Row>
                      {availablePlans.map((plan) => (
                        <Col key={plan.id} md="3">
                          <Card
                            style={{
                              height: '250px',
                              overflowY: 'auto',
                              backgroundColor:
                                selectedPlan && (selectedPlan.code === plan.code || selectedPlan === plan.code)
                                  ? 'green'
                                  : 'orange',
                            }}
                            className={selectedPlan && (selectedPlan.code === plan.code || selectedPlan === plan.code) ? 'selected' : ''}
                            onClick={() => {
                              // Only allow selecting plans with price of 0
                              if (plan.price === 0) {
                                handleSelectPlan(plan);
                              }
                            }}
                          >
                            <CardBody>
                              <h5 style={{ fontWeight: 'bold' }}>{plan.name}</h5>
                              <p style={{ fontWeight: 'bold' }}>{plan.details}</p>
                              <p style={{ fontWeight: 'bold' }}>Amount: ₹{plan.price}</p>
                              {plan.price > 0 && (
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    if (isPhoneOtpVerified) {
                                      handleSelectPlan(plan)
                                      handlePayment(plan.price);
                                    } else {

                                      setSnackbarMessage("Please verify your phone and email before proceeding to payment.");
                                      setSnackbarBgColor('red');
                                      setIsSuccess(false);
                                      setSnackbarOpen(true);
                                    }
                                  }}
                                  className="mt-3"
                                >
                                  Perform Payment
                                </Button>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
                      onClick={() => {
                        handlePlan();
                      }}
                      disabled={!selectedPlan}
                    >
                      Save and Continue
                    </Button>
                  </div>
                </Form>
              </CardBody>
            )}
          </Card>
          {showPaymentHandler && (
            <PaymentHandler
              amount={amount}
              //  orderId={orderId}
              currency={currency}

            />
          )}
        </div>


        <div style={{ marginTop: '20px' }}>

          <label style={{ paddingLeft: '5px' }}>
            <input
              type="checkbox"
              checked={agreedToTerms}
              onChange={handleCheckboxChange}
              style={{ transform: 'scale(1.5)' }}
            />
            <span style={{ marginLeft: '10px', color: 'white' }}>
              I agree to the <a href={`${process.env.PUBLIC_URL}/TermsCondition.html`} style={{ color: 'white', textDecoration: 'underline', cursor: 'pointer' }}>Terms and Conditions</a>


            </span>
          </label>
        </div>
        <ToastContainer />

        <div className="text-center">
          <Button
            className="my-4"
            color="primary"
            type="button"
            style={{ fontSize: "1.2rem", fontWeight: "bold" }}
            onClick={handlesubmit}
          >
            Save
          </Button>
          <Button
            className="my-4"
            color="primary"
            type="button"
            style={{ fontSize: "1.2rem", fontWeight: "bold" }}
            onClick={handleBack}
          >
            Back To Login
          </Button>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle sx={{ color: 'black', fontWeight: 'bold' }}>Confirm Exit?</DialogTitle>
          <DialogContent>
            Are you sure you want to exit the signup process?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="danger">
              No
            </Button>
            <Button onClick={handleConfirmBack} color="success">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <ToastContainer />
        <MySnackbar
          snackbarOpen={snackbarOpen}
          handleSnackbarClose={handleSnackbarClose}
          snackbarMessage={snackbarMessage}
          snackbarBgColor={snackbarBgColor}
          isSuccess={isSuccess}
        />
        {loading && (
          <div className="spinner-container">
            <ClipLoader size={50} color={"#123abc"} loading={loading} />
          </div>
        )}
      </Container>
    </>
  );
};

export default SignUp;
