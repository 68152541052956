import React, { useState } from "react";
import { Button } from "reactstrap";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import "./suggestedjobs.css";
import MySnackbar from 'views/examples/Snackbar';
import { useAuth } from "views/examples/AuthContext";
import { useApiUrl } from "views/examples/ApiUrlContext";

const ForgotPasswordModal = ({ isOpen, toggle }) => {
  const { apiUrl } = useApiUrl();
  const { userData } = useAuth();

  const [currentSlide, setCurrentSlide] = useState(1); // 1 for email input, 2 for OTP and password
  const [userId, setUserId] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarBgColor, setSnackbarBgColor] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const isEmailValid = () => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(userId);
  };

  const handleGetOtp = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/otp/generateotpi`,
        { login: userId },
        {
          headers: {
            'Content-Type': 'application/json', // Set the content type header
          },
        }
      );
      if (response.data.cd === 1) {
        // Move to OTP slide

        setSnackbarMessage(response.data.description);
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
      } else {
        setIsOtpSent(true);
        setCurrentSlide(2);
        setSnackbarMessage(response.data.description);
        setSnackbarBgColor('green');
        setIsSuccess(true);
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Error sending OTP. Please try again.');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
    }
  };

  const handleVerify = async () => {
    if (!otp) {
      setSnackbarMessage("Please Enter OTP!");
      setSnackbarBgColor("red");
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }
    if (!newPassword) {
      setSnackbarMessage("Please Enter New Password!");
      setSnackbarBgColor("red");
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setSnackbarMessage("Passwords do not match!");
      setSnackbarBgColor("red");
      setIsSuccess(false);
      setSnackbarOpen(true);
      return;
    }
    try {
      const response = await axios.post(`${apiUrl}/otp/verifyotpi`,
        {
          otp: otp,
          name: userId,
          password: newPassword,
          newPassword: newPassword,
        },
        {
          headers: {
            'Content-Type': 'application/json', // Set the content type header
          },
        });
      if (response.data.cd === 1) {
        setSnackbarMessage(response.data.description);
        setSnackbarBgColor('red');
        setIsSuccess(false);
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage(response.data.description);
        setSnackbarBgColor('green');
        setIsSuccess(true);
        setSnackbarOpen(true);
        handleToggle();
      }
    } catch (error) {
      setSnackbarMessage('Error verifying OTP. Please try again.');
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
    }
  };

  const resetForm = () => {
    setCurrentSlide(1); // Reset to first slide
    setUserId("");
    setOtp("");
    setNewPassword("");
    setConfirmNewPassword("");
    setIsOtpSent(false);
  };

  // Override the toggle function to reset the form
  const handleToggle = () => {
    resetForm();
    toggle();
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleNewConfirmPasswordVisibility = () => {
    setShowNewConfirmPassword(!showNewConfirmPassword);
  };

  return (
    <>
      {isOpen && (
        <div className="forgetpwd-modal-overlay">
          <div className="forgetpwd-modal-content">
            <div className="forgetpwd-modal-header">
              <h2 className="modal-title">Forgot Password</h2>
              <button className="cancel-button" onClick={handleToggle}>
                &#x2715;
              </button>
            </div>
            <div className="forgetpwd-modal-body">
              {currentSlide === 1 && ( // First slide for email input
                <form>
                  <div className="form-group">
                    <label htmlFor="userId" style={{ fontWeight: 'bold' }}>
                      Email <span className="required-asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="userId"
                      placeholder="Enter Email"
                      value={userId}
                      onChange={(e) => setUserId(e.target.value)}
                    />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Button
                      color="primary"
                      onClick={handleGetOtp}
                      disabled={!isEmailValid()} // Disable button if email is invalid
                    >
                      Get OTP
                    </Button>
                  </div>

                </form>
              )}
              {currentSlide === 2 && ( // Second slide for OTP and password
                <form>
                  <div className="form-group">
                    <label htmlFor="otp" style={{ fontWeight: 'bold' }}>
                      Enter OTP <span className="required-asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="otp"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                  <div className="form-group" style={{ position: "relative" }}>
                    <label htmlFor="newPassword" style={{ fontWeight: 'bold' }}>
                      New Password <span className="required-asterisk">*</span>
                    </label>
                    <input
                      type={showNewPassword ? 'text' : 'password'}
                      className="form-control"
                      id="newPassword"
                      placeholder="Enter new password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={showNewPassword ? faEyeSlash : faEye}
                      onClick={toggleNewPasswordVisibility}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: "10px",
                        top: "70%", // Adjusts to vertically center the icon
                        transform: "translateY(-50%)",
                        zIndex: "1000",
                        color: "grey",
                      }}
                    />
                  </div>
                  <div className="form-group" style={{ position: "relative" }}>
                    <label htmlFor="confirmNewPassword" style={{ fontWeight: 'bold' }}>
                      Confirm New Password{' '}
                      <span className="required-asterisk">*</span>
                    </label>
                    <input
                       type={showNewConfirmPassword ? 'text' : 'password'}
                      className="form-control"
                      id="confirmNewPassword"
                      placeholder="Confirm new password"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={showNewConfirmPassword ? faEyeSlash : faEye}
                      onClick={toggleNewConfirmPasswordVisibility}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: "10px",
                        top: "70%", // Vertically centers the icon
                        transform: "translateY(-50%)",
                        zIndex: "1000",
                        color: "grey",
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Button color="danger" onClick={handleVerify}>
                      Reset Password
                    </Button>
                  </div>

                </form>
              )}
            </div>
          </div>
          <MySnackbar
            snackbarOpen={snackbarOpen}
            handleSnackbarClose={handleSnackbarClose}
            snackbarMessage={snackbarMessage}
            snackbarBgColor={snackbarBgColor}
            isSuccess={isSuccess}
          />
        </div>
      )}
    </>
  );
};

export default ForgotPasswordModal;
