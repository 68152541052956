// AppointmentLetter.js
import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import "./suggestedjobs.css";
import { Button, Modal, Form, FormGroup, FormControl, Alert } from 'react-bootstrap';
import { useAuth } from "views/examples/AuthContext";
import { useApiUrl } from "views/examples/ApiUrlContext";
import MySnackbar from 'views/examples/Snackbar'
import useUtil from "views/examples/Util"


const AppointmentLetter = ({ employeeId, Faculties }) => {
  const { userData } = useAuth();
  const { apiUrl } = useApiUrl();
  const { userLoginCode } = useAuth();
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [basicinstitutedata, setBasicinstitutedata] = useState({});
  const [showTerminationModal, setShowTerminationModal] = useState(false);
  const [circumstances, setCircumstances] = useState('');
  const [noticePeriodFrom, setNoticePeriodFrom] = useState('');
  const [noticePeriodTo, setNoticePeriodTo] = useState('');
  const [validationError, setValidationError] = useState('');
  const [validationErrorMemo, setValidationErrorMemo] = useState('');
  const [showMemoModal, setShowMemoModal] = useState(false);
  const [memocircumstances, setmemocircumstances] = useState('');

  // State variables for Experience Letter modal
  const [showExpLetterModal, setShowExpLetterModal] = useState(false);
  const [expCircumstances, setExpCircumstances] = useState('');
  const [validationErrorExp, setValidationErrorExp] = useState('');
  const [joiningToDate, setJoiningToDate] = useState('');
  const [noticePeriodEndDate, setNoticePeriodEndDate] = useState('');
  const [resignationDate, setResignationDate] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarBgColor, setSnackbarBgColor] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        {
          const bearerToken = userData.accessToken;
          const response = await fetch(`${apiUrl}/employees/empCode/${employeeId}`, {
            headers: {
              'Authorization': `Bearer ${bearerToken}`,
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const data = await response.json();
          setEmployeeDetails(data);
          // if (response && ( response.status === 403 ||  response.status === 400)) {
          //   const headers = response.headers;
          //   if (headers && headers.get('exception') === 'SignatureException') {
          //     await useUtil.logout();
          //     // clear shared Pref & perform logout
          //   } else if (headers && headers.get('exception') === 'ExpiredJwtException') {
          //     const newBearerToken = await useUtil.refreshToken();
          //     // Use the newBearerToken as needed
          //   }
          // }
        }

      } catch (error) {


      }
    };

    fetchData(); // Invoke the fetchData function
  }, [employeeId, apiUrl, userData.accessToken]);


  useEffect(() => {
    fetchInstituteData();
  }, [apiUrl, userData]);

  const fetchInstituteData = async () => {
    try {
      const bearerToken = userData.accessToken;
      const response = await fetch(
        `${apiUrl}/iBasic/code/${userLoginCode.name}`,
        {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
        })
      const data = await response.json();

      setBasicinstitutedata(data);
      if (response && (response.status === 403 || response.status === 400)) {
        const headers = response.headers;
        if (headers && headers.Exception === 'SignatureException') {
          await useUtil.logout();
          // clear shared Pref & perform logout
        } else if (headers && headers.Exception === 'ExpiredJwtException') {
          bearerToken = await useUtil.refreshToken();
          // Use the newBearerToken as needed
        }
      }
    }
    catch (error) {

    }
  };

  const generateMemo = () => {
    if (employeeDetails) {
      if (!memocircumstances) {
        setValidationErrorMemo('Please fill field.');
        return;
      }
      const pdf = new jsPDF();

      pdf.setFont("times", "normal");
      pdf.setFontSize(12);

      pdf.text(`${basicinstitutedata.trust}`, 15, 20);
      const userInfoText = `${basicinstitutedata.name}, ${basicinstitutedata.afflTypeVal}, ${basicinstitutedata.afflTypeVal}, ${basicinstitutedata.phone}`;
      pdf.text(userInfoText, 15, 30);

      const pdfWidth = pdf.internal.pageSize.getWidth();
      pdf.text("_".repeat(pdfWidth), 0, 35);

      let today = new Date();
      let date =
        today.getDate() +
        "-" +
        parseInt(today.getMonth() + 1) +
        "-" +
        today.getFullYear();

      pdf.text(`Date: ` + date, 15, 45);
      pdf.text(`To,`, 15, 55);
      pdf.text(`${employeeDetails.name}`, 15, 65);
      pdf.text(`${employeeDetails.designation}`, 15, 75);
      pdf.text(`${employeeDetails.employeeId}`, 15, 85);
      pdf.text("Sub: Interim Memo,", 15, 95);
      pdf.text(`Dear ${employeeDetails.name}`, 15, 105);
      pdf.text(
        15,
        115,
        "On behalf of the governing body, we are extremely sorry to issue you interim memo on the basis of certain undeniable circumstances.",
        { maxWidth: pdfWidth - 15 }
      );
      pdf.text(
        15,
        135,
        `Your recent acts of indifference towards '${memocircumstances}' have been brought to our notice several weeks back. We at this moment have decided to give you another chance to prove your worth. This kind of indifference is unacceptable and it might pose difficulty in your career growth. Within 16 hours of receiving this memo, you are expected to submit a written undertaking along with reasons that such acts shall not be repeated in future.`,
        { maxWidth: pdfWidth - 15 }
      );
      pdf.text("Thanking you,", 15, 160);
      pdf.text("Chairman", 15, 170);
      pdf.text("Seal", 15, 200);
      const pdfData = pdf.output("datauristring");
      const newWindow = window.open();
      newWindow.document.write(
        `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
      );
    }

  };

  const OpenTerminationModel = () => {
    if (employeeId) {
      setShowTerminationModal(true);
    } else {
      setSnackbarMessage("Please select the employee");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const OpenMemoModel = () => {
    if (employeeId) {
      setShowMemoModal(true);
    } else {
      setSnackbarMessage("Please select the employee");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
    }
  };


  const OpenExpLetterModel = () => {
    if (employeeId) {
      setShowExpLetterModal(true);
    } else {
      setSnackbarMessage("Please select the employee");
      setSnackbarBgColor('red');
      setIsSuccess(false);
      setSnackbarOpen(true);
    }
  };


  const handleCloseTerminationModal = () => {
    setShowTerminationModal(false);
    setCircumstances('');
    setNoticePeriodFrom('');
    setNoticePeriodTo('');
    setValidationError('');
  };

  const handleCloseMemoModal = () => {
    setShowMemoModal(false);
    setmemocircumstances('');
    setValidationErrorMemo('');
  };

  const handleCloseExpLetterModal = () => {
    setShowExpLetterModal(false);
    setExpCircumstances('');
    setValidationErrorExp('');
    setJoiningToDate('');
    setResignationDate('');
    setNoticePeriodEndDate('');
  };

  const handleCircumstancesChange = (e) => {
    setCircumstances(e.target.value);
  };

  const handlememocircumstancesCircumstancesChange = (e) => {
    setmemocircumstances(e.target.value);
  };

  const handleExpCircumstancesChange = (e) => {
    setExpCircumstances(e.target.value);
  };

  const handleJoiningToDateChange = (e) => {
    setJoiningToDate(e.target.value);
  };

  const handleNoticePeriodEndDateChange = (e) => {
    setNoticePeriodEndDate(e.target.value);
  };

  const handleResignationDateChange = (e) => {
    setResignationDate(e.target.value);
  };

  const handleNoticePeriodFromChange = (e) => {
    const selectedDate = new Date(e.target.value);

    // Calculate noticePeriodTo by adding 6 months to noticePeriodFrom
    const calculatedDate = new Date(selectedDate);
    calculatedDate.setMonth(calculatedDate.getMonth() + 6);

    // Update state variables
    setNoticePeriodFrom(e.target.value);

  };

  const handleNoticePeriodToChange = (e) => {
    setNoticePeriodTo(e.target.value);
  }

  const generateTerminationLetter = () => {

    if (employeeDetails) {
      if (!circumstances || !noticePeriodFrom || !noticePeriodTo) {
        setValidationError('Please fill in all required fields.');
        return;
      }
      if (noticePeriodTo <= noticePeriodFrom) {
        setValidationError('Notice period "To" must be greater than notice period "From".');
        return;
      }

      const pdf = new jsPDF();
      const margin = 20; // Define the margin for both sides
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const contentWidth = pdfWidth - margin * 2;
      let yPosition = margin + 10; // Initial Y position with top margin

      // Draw a border around the content
      const borderPadding = 5; // Padding inside the border
      pdf.rect(margin - borderPadding, margin - borderPadding, pdfWidth - margin * 2 + borderPadding * 2, pageHeight - margin * 2 + borderPadding * 2);

      pdf.setFont("times", "normal");
      pdf.setFontSize(12);
      pdf.setLineHeightFactor(1.5);

      // Add the trust name
      pdf.text(`${basicinstitutedata.trust}`, margin, yPosition);
      yPosition += 10;

      // Add the institution details
      const userInfoText = `${basicinstitutedata.name}, ${basicinstitutedata.afflTypeVal}, ${basicinstitutedata.afflTypeVal}, ${basicinstitutedata.phone}`;
      pdf.text(userInfoText, margin, yPosition);
      yPosition += 10;

      // Draw a separator line within the margins
      const separatorLine = "_".repeat(contentWidth / 2);
      const separatorXPosition = (pdfWidth - pdf.getTextDimensions(separatorLine).w) / 2;
      pdf.text(separatorLine, separatorXPosition, yPosition);
      yPosition += 15;

      // Add the date
      let today = new Date();
      let date = `${today.getDate()}-${parseInt(today.getMonth() + 1)}-${today.getFullYear()}`;
      pdf.text(`Date: ${date}`, margin, yPosition);
      yPosition += 10;

      // Add the recipient information
      pdf.text(`To,`, margin, yPosition);
      yPosition += 10;

      pdf.text(`${employeeDetails.name}`, margin, yPosition);
      yPosition += 10;

      pdf.text(`${employeeDetails.designation}`, margin, yPosition);
      yPosition += 10;

      pdf.text(`${employeeDetails.employeeId}`, margin, yPosition);
      yPosition += 10;

      // Add the subject line
      pdf.text("Sub: Regarding the termination of service,", margin, yPosition);
      yPosition += 10;

      // Add the salutation
      pdf.text(`Dear ${employeeDetails.name},`, margin, yPosition);
      yPosition += 10;

      // Combine the first and second paragraphs into a single paragraph
      const fullParagraph = `On behalf of the governing body, we are extremely sorry to inform you the termination of your services with our institution on the basis of certain undeniable circumstances. Your recent acts of indifference towards '${circumstances}' have been brought to our notice several weeks back. We at this moment decided to give you another chance to prove your worth which you missed seemingly even after giving interim memo. Moreover, this kind of repeated indifference is unacceptable. Thus we have decided to give you the opportunity to resign yourself and maintain your dignity, or we will be forced to terminate you with effect from today and you will be given a notice period of '${noticePeriodFrom}' and you will be relieved on '${noticePeriodTo}'.\n\nThanking you,\n\n\nChairman\nSeal`;
      let fullParagraphHeight = pdf.getTextDimensions(fullParagraph).h;
      pdf.text(fullParagraph, margin, yPosition, { maxWidth: contentWidth });
      yPosition += fullParagraphHeight + 10;

      // Output the PDF
      const pdfData = pdf.output("datauristring");
      const newWindow = window.open();
      newWindow.document.write(
        `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
      );
    }
    setShowTerminationModal(false);
  };


  const generateExpLetter = () => {
    if (employeeDetails) {
      if (!expCircumstances || !noticePeriodEndDate || !resignationDate) {
        setValidationErrorExp('Please fill in all required fields.');
        return;
      }
      if (resignationDate <= noticePeriodEndDate) {
        setValidationErrorExp('Resignation date must be greater than the notice period end date.');
        return;
      }


      const pdf = new jsPDF();

      pdf.setFont("times", "normal");
      pdf.setFontSize(12);

      pdf.text(`${basicinstitutedata.trust}`, 15, 20);
      const userInfoText = `${basicinstitutedata.name}, ${basicinstitutedata.afflTypeVal}, ${basicinstitutedata.afflTypeVal}, ${basicinstitutedata.phone}`;
      pdf.text(userInfoText, 15, 30);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      pdf.text("_".repeat(pdfWidth), 0, 25);
      var pageWidth =
        pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(
        "EXPERIENCE CERTIFICATE CUM RELIEVING ORDER",
        pageWidth / 2,
        55,
        {
          align: "center",
        }
      );
      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text(`To whosoever it may concern`, pageWidth / 2, 65, {
        align: "center",
      });
      pdf.setLineHeightFactor(2);
      pdf.setFontSize(12).setFont(undefined, "normal");
      pdf.text(
        15,
        80,
        `We hereby certify that  ${employeeDetails.name} who worked as ${employeeDetails.designation} in our school from ${employeeDetails.joiningDate} to ${joiningToDate}. During his/her tenure we found him/her a ${expCircumstances} employee with a professional attitude and very good job knowledge. He/She is amiable in nature and possesses good character. He/She has proved with her efficient work himself/herself as one of the important assets of the organization. He/She owes no dues from our office. He/she is relieved from service with effect from '${noticePeriodEndDate}' against him/her resignation letter dated '${resignationDate}'. 
        \nWe wish him/her good luck and success in her life.
        \n
        \nWith Regards,
        \n
        \nChairman Seal`,
        { maxWidth: pdfWidth - 15, lineHeight: 2 }
      );

      const pdfData = pdf.output("datauristring");
      const newWindow = window.open();
      newWindow.document.write(
        `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
      );
    }
    handleCloseExpLetterModal();
  };

  const generateCodeOfConduct = () => {
    if (employeeDetails) {
      const pdf = new jsPDF();
      pdf.setFont("times", "normal");
      pdf.setFontSize(12);

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Add border around the entire PDF page
      const borderMargin = 10;
      pdf.rect(borderMargin, borderMargin, pdfWidth - 2 * borderMargin, pdfHeight - 2 * borderMargin);

      // Adjust content margins to stay within the border
      const contentLeftMargin = borderMargin + 5;
      const contentRightMargin = pdfWidth - borderMargin - 5;

      // Institute Information
      pdf.text(`${basicinstitutedata.trust}`, contentLeftMargin, 20);
      const userInfoText = `${basicinstitutedata.name || ''}, ${basicinstitutedata.afflTypeVal || ''}, ${basicinstitutedata.afflTypeVal || ''}, ${basicinstitutedata.phone || ''}`;
      pdf.text(userInfoText, contentLeftMargin, 30);

      // Underline below the institute information
      const underlineY = 35;
      const underlineLength = (contentRightMargin - contentLeftMargin) / pdf.getTextWidth("_");
      pdf.text("_".repeat(underlineLength), contentLeftMargin, underlineY);

      // Title
      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text("Code of Conduct for Employees", pdfWidth / 2, 45, { align: "center" });

      pdf.setLineHeightFactor(1.2); // Adjust line height for tighter spacing
      pdf.setFontSize(10).setFont(undefined, "normal"); // Reduce font size

      let currentY = 60; // Start position for content

      // Content
      const contentText = `1. Every employee shall be governed by the Code of Conduct. The following acts shall constitute breach of code of conduct:
        i) Habitual late coming and negligence of duty.
        ii) Use of abusive language, quarrelsome and riotous behavior.
        iii) Insubordination and defiance of lawful order.
        iv) Disrespectful behavior, rumor mongering and character assassination.
        v) Making false accusations or assault either provoked or otherwise.
        vi) Use of liquor or narcotics on the school premises.
        vii) Embezzlement of funds or misappropriation of school property or theft or fraud.
        viii) Mutilation/destruction of school records and property.
        ix) Conviction by a court of law for criminal offence.
        x) Possession in school premises of weapons, explosives, and other objectionable materials.
        xi) Indulging in or encouraging any form of malpractice connected with examination or other school activities.
        xii) Divulging confidential matters relating to school.
        xiii) Obstructing other members of the staff from lawful duties and indulging in any sort of agitation to coerce or embarrass the school authorities.
        xiv) Carrying on personal monetary transactions among themselves, with the student and/or with the parents.
        xv) Taking active part in politics.
        xvi) Propagating through teaching lessons or otherwise communal or sectarian outlook or inciting or allowing any student to indulge in communal or sectarian activity.
        xvii) Making sustained neglect in correcting class work or home work.
        xviii) Taking private tuitions without permission of school authorities.
        xix) Organizing or attending any meeting during school hours except when he is required or permitted by the Head of the school to do so.
        xx) Absenting from work even though present in the school premises or absent without leave.
        xxi) Preparing or publishing any book or books commonly known as keys or assist whether directly or indirectly in their publication or as a selling agent or canvasser for any publishing firm or trader.
        xxii) Asking for or accept, except with the previous sanction of the society, any contribution or otherwise associate himself with the raising of funds of any kind or make any collection whether in cash or in kind, in pursuance of any object whatsoever, except subscription from the members of any association of teachers.`;

      // Split the content text into lines to ensure proper wrapping
      const lines = pdf.splitTextToSize(contentText, contentRightMargin - contentLeftMargin);

      // Render the lines with appropriate indentation for subheadings
      lines.forEach((line, index) => {
        const isSubheading = line.match(/^\s*[ixv]+\)/);
        const indent = isSubheading ? 0 : 5; // No indent for subheadings, small indent for other lines
        pdf.text(line.trim(), contentLeftMargin + indent, currentY);
        currentY += isSubheading ? 8 : 5; // More space after subheadings
      });

      const pdfData = pdf.output("datauristring");
      const newWindow = window.open();
      newWindow.document.write(
        `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
      );
    }
  };

  const generateLeavePolicy = () => {
    if (employeeDetails) {
      const pdf = new jsPDF();

      pdf.setFont("times", "normal");
      pdf.setFontSize(12);

      pdf.text(`${basicinstitutedata.trust}`, 15, 20);
      const userInfoText = `${basicinstitutedata.name}, ${basicinstitutedata.afflTypeVal}, ${basicinstitutedata.afflTypeVal}, ${basicinstitutedata.phone}`;
      pdf.text(userInfoText, 15, 30);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      pdf.text("_".repeat(pdfWidth), 0, 35);
      var pageWidth =
        pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text("LEAVE POLICY FOR TEACHERS", pageWidth / 2, 45, {
        align: "center",
      });

      pdf.setLineHeightFactor(1.5);
      pdf.setFontSize(12).setFont(undefined, "normal");
      pdf.text(
        15,
        60,
        `All employees of '${basicinstitutedata.name}', shall be governed by the Code of Conduct and Service Rules prescribed by Management Committee of the School, as specified in rule .
        Leave of any kind cannot be claimed as of right. Discretion is reserved to the authority competent to grant leave to refuse, or to revoke leave at any time in the interests of the institution.
        Casual Leave(CL):- A Teacher is eligible to avail casual leave for a total of 8 days in a calendar year for special needs. This leave shall not be accumulated, nor shall it be combined with any other kind of leave. Normally, it is given just one day per month. After one year of service three days of casual leave are given at a time if the said teacher has not availed of the same in the previous months. A teacher after joining the service shall be eligible to take a CL only after completing a service period of 1 month or 25 working days.`,
        { maxWidth: pdfWidth - 15, lineHeight: 2 }
      );

      const pdfData = pdf.output("datauristring");
      const newWindow = window.open();
      newWindow.document.write(
        `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
      );
    }

  };

  const generateFacultyList = () => {
    if (employeeDetails) {
      const pdf = new jsPDF();

      pdf.setFont("times", "normal");
      pdf.setFontSize(12);
      pdf.text(`${basicinstitutedata.trust}`, 15, 20);
      const userInfoText = `${basicinstitutedata.name}, ${basicinstitutedata.afflTypeVal}, ${basicinstitutedata.afflTypeVal}, ${basicinstitutedata.phone}`;
      pdf.text(userInfoText, 15, 30);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      pdf.text("_".repeat(pdfWidth), 0, 35);
      let today = new Date();
      let date =
        today.getDate() +
        "-" +
        parseInt(today.getMonth() + 1) +
        "-" +
        today.getFullYear();

      pdf.text(`Date: ` + date, 15, 40);
      var pageWidth =
        pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
      pdf.setFontSize(16).setFont(undefined, "bold");
      pdf.text("List of Faculty", pageWidth / 2, 45, {
        align: "center",
      });
      const columns = [
        { title: 'S.No', dataKey: 'sno' },
        { title: 'SB Unique ID', dataKey: 'iCode' },
        { title: 'Employee ID', dataKey: 'employeeId' },
        { title: 'Name', dataKey: 'name' },
        { title: 'Designation', dataKey: 'designationVal' },
        { title: 'Joining Date', dataKey: 'joiningDate' },
        { title: 'Contact Number', dataKey: 'phoneNumber' },
        { title: 'Email Address', dataKey: 'email' },
      ];

      const rows = Faculties.map((faculty, index) => ({
        sno: index + 1,
        iCode: faculty.iCode,
        employeeId: faculty.employeeId,
        name: faculty.name,
        designationVal: faculty.designationVal,
        dob: faculty.dob,
        joiningDate: faculty.joiningDate,
        phoneNumber: faculty.phoneNumber,
        email: faculty.email,
      }));

      // Set the table options
      const options = {
        startY: 60, // Vertical position to start the table (in mm)
        // startX: 10, // Vertical position to start the table (in mm)
      };

      pdf.autoTable(columns, rows, options);

      const pdfData = pdf.output("datauristring");
      const newWindow = window.open();
      newWindow.document.write(
        `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
      );
    }

  };

  return (
    <div>

      <Button
        variant="success"
        onClick={OpenTerminationModel}
        className="mt-5"
        style={{ color: 'black', fontWeight: 'bold' }}
      >
        Generate Termination
      </Button>

      <Button variant="success"
       onClick={OpenMemoModel}
        className="mt-5"
        style={{ color: 'black', fontWeight: 'bold' }}
        >
        Generate Memo
      </Button>

      <Button variant="success"
       onClick={generateFacultyList}
        className="mt-5"
        style={{ color: 'black', fontWeight: 'bold' }}
        >
        Add Employee List
      </Button>
      <Button 
      variant="success"
       onClick={OpenExpLetterModel} 
       className="mt-5"
       style={{ color: 'black', fontWeight: 'bold' }}
       >
        Experience Letter
      </Button>

      <Button
        variant="success"
        onClick={generateCodeOfConduct}
        className="mt-5"
        style={{ color: 'black', fontWeight: 'bold' }}
      >
        Employee Code of Conduct
      </Button>
      <Button variant="success"
       onClick={generateLeavePolicy}
        className="mt-5"
        style={{ color: 'black', fontWeight: 'bold' }}
        >
        Leave Policy
      </Button>

      {showTerminationModal && (
        <div className="termination-modal-overlay">
          <div className="termination-modal-content">
            <div className="termination-modal-header">
              <h2 className="modal-title">Termination Letter</h2>
              <button className="cancel-button" onClick={handleCloseTerminationModal}>
                &#x2715;
              </button>
            </div>
            <div className="termination-modal-body">

              <Form>
                <FormGroup className="mb-3">
                  <Form.Label style={{ fontWeight: 'bold' }}>Circumstances</Form.Label>
                  <FormControl
                    type="text"
                    value={circumstances}
                    onChange={handleCircumstancesChange}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Form.Label style={{ fontWeight: 'bold' }}>Notice Period From</Form.Label>
                  <FormControl
                    type="date"
                    value={noticePeriodFrom}
                    onChange={handleNoticePeriodFromChange}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Form.Label style={{ fontWeight: 'bold' }}>Notice Period To</Form.Label>
                  <FormControl
                    type="date"
                    value={noticePeriodTo}
                    onChange={handleNoticePeriodToChange}

                  />
                </FormGroup>
              </Form>
              {validationError && <Alert variant="danger">{validationError}</Alert>}

            </div>
            <div className="termination-button">
              <Button
                variant="success"
                onClick={generateTerminationLetter}
              >
                Generate Termination Letter
              </Button>
              <Button
                variant="danger"
                onClick={handleCloseTerminationModal}
              >
                Close
              </Button>

            </div>
          </div>
        </div>

      )}

      {showMemoModal && (
        <div className="memo-modal-overlay">
          <div className="memo-modal-content">
            <div className="memo-modal-header">
              <h2 className="modal-title">Memo Letter</h2>
              <button className="cancel-button" onClick={handleCloseMemoModal}>
                &#x2715;
              </button>
            </div>
            <div className="memo-modal-body">
              <Form>
                <FormGroup className="mb-3">
                  <Form.Label style={{ fontWeight: 'bold' }}>Circumstances:</Form.Label>
                  <FormControl
                    type="text"
                    value={memocircumstances}
                    onChange={handlememocircumstancesCircumstancesChange}
                  />
                </FormGroup>


              </Form>
              {validationErrorMemo && <Alert variant="danger">{validationErrorMemo}</Alert>}


            </div>
            <div className="memo-button">
              <Button
                variant="success"
                onClick={generateMemo}
              >
                Generate Memo
              </Button>
              <Button
                variant="danger"
                onClick={handleCloseMemoModal}
              >
                Close
              </Button>

            </div>
          </div>
        </div>

      )}
      {showExpLetterModal && (
        <div className="experiance-modal-overlay">
          <div className="experiance-modal-content">
            <div className="experiance-modal-header">
              <h2 className="modal-title">Experience Letter</h2>
              <button className="cancel-button" onClick={handleCloseExpLetterModal}>
                &#x2715;
              </button>
            </div>
            <div className="experiance-modal-body">
              <Form>
                <FormGroup className="mb-3">
                  <Form.Label style={{ fontWeight: 'bold' }}>Circumstances</Form.Label>
                  <FormControl
                    type="text"
                    value={expCircumstances}
                    onChange={handleExpCircumstancesChange}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <Form.Label style={{ fontWeight: 'bold' }}>Joining Date</Form.Label>
                  <FormControl
                    type="date"
                    value={joiningToDate}
                    onChange={handleJoiningToDateChange}
               
                  />
                </FormGroup>

                {/* Notice Period End Date */}
                <FormGroup className="mb-3">
                  <Form.Label style={{ fontWeight: 'bold' }}>Notice Period End Date</Form.Label>
                  <FormControl
                    type="date"
                    value={noticePeriodEndDate}
                    onChange={handleNoticePeriodEndDateChange}
                  />
                </FormGroup>

                {/* Resignation Date */}
                <FormGroup className="mb-3">
                  <Form.Label style={{ fontWeight: 'bold' }}>Resignation Date</Form.Label>
                  <FormControl
                    type="date"
                    value={resignationDate}
                    onChange={handleResignationDateChange}
                  // readOnly
                  />
                </FormGroup>
              </Form>
              {validationErrorExp && <Alert variant="danger">{validationErrorExp}</Alert>}


            </div>
            <div className="experiance-button">
              <Button
                variant="success"
                onClick={generateExpLetter}
              >
                Generate Experience Letter
              </Button>
              <Button
                variant="danger"
                onClick={handleCloseExpLetterModal}
              >
                Close
              </Button>


            </div>
          </div>
        </div>

      )}

<MySnackbar
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        snackbarMessage={snackbarMessage}
        snackbarBgColor={snackbarBgColor}
        isSuccess={isSuccess}
      />
    </div>
  );
};

export default AppointmentLetter;
